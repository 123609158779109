import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { SyntheticEvent, useState } from 'react';
import FormProvision from '../FormProvision/index';
import { useTranslation } from 'react-i18next';
import GenericFormSchedule from '../GenericFormSchedule';
import { useStyles } from './styles';
import { startAutomaticProvision } from '../../services/support';
import { postSaveTimeProvision } from '../../services/data-manager';

const ManagerProvision = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [value, setValue] = useState('1');

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box className={classes.root}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={ready ? t('provision.provisions') : 'Provisiones'}
              value="1"
            />
            <Tab
              label={ready ? t('provision.schedule') : 'Programación'}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.tabPanel}>
          <FormProvision />
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <GenericFormSchedule
            startAutomaticService={startAutomaticProvision}
            saveTimeService={postSaveTimeProvision}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ManagerProvision;
