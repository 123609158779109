import { useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import theme from '../theme';
import {
  DASHBOARD,
  DATA_MAINTAINER,
  LOGIN,
  REPORT,
  SOLUTION,
  SUPPORT,
  SUPPORT_DETAIL,
  DEVICE_DETAIL,
  USER_SETTINGS,
} from '../constants/routes';
import {
  LogDetail,
  Login,
  Report,
  Solution,
  Support,
  UserSettings,
} from '../pages';
import SupportDetail from '../pages/SupportDetail';
import Dashboard from '../pages/Dashboard';
import DataMaintainer from '../pages/Mantainer';
import DeviceDetail from '../pages/DeviceDetail';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { useAppSelector } from '../redux';
import { selectToken } from '../redux/user';

import { SpinnerContainer } from './styles';
import { checkToken } from '../services/auth';

export default function AppRouter(): JSX.Element {
  const [isLogged, setIsLogged] = useState(false);
  const [cheking, setCheking] = useState(true);

  const token = useAppSelector(selectToken);

  const validateToken = async () => {
    try {
      const response = await checkToken();
      if (response.status === 200) {
        setIsLogged(true);
      } else {
        setIsLogged(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useLayoutEffect(() => {
    setCheking(true);
    validateToken();
    setCheking(false);
  }, []);

  useLayoutEffect(() => {
    setIsLogged(!!token);
  }, [token]);

  if (cheking) {
    return (
      <SpinnerContainer>
        <CircularProgress />
      </SpinnerContainer>
    );
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <PublicRoute
            exact
            path={LOGIN}
            component={Login}
            isLogged={isLogged}
          />

          <PrivateRoute isLogged={isLogged}>
            <Route exact path={DASHBOARD} component={Dashboard} />
            <Route exact path={DATA_MAINTAINER} component={DataMaintainer} />
            <Route path={`${DATA_MAINTAINER}/:logId`} component={LogDetail} />
            <Route exact path={SUPPORT} component={Support} />
            <Route
              path={`${SUPPORT_DETAIL}/:installationId`}
              component={SupportDetail}
            />
            <Route path={`${DEVICE_DETAIL}/:keyID`} component={DeviceDetail} />
            <Route exact path={SOLUTION} component={Solution} />
            <Route exact path={REPORT} component={Report} />
            <Route exact path={USER_SETTINGS} component={UserSettings} />
          </PrivateRoute>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}
