import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      padding: '0px',
      minHeight: '100vh',
    },
    mainPage: {
      flexGrow: '1',
      flexShrink: '1',
      flexBasis: '0%',
      padding: '20px',
      background: '#F2F2F2',
      '@media print': {
        background: '#FFFFFF',
      },
    },
    styledToolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      background: 'linear-gradient(270deg, #525252 0%, #04225F 100%)',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    styledList: {
      padding: '0 !important',
    },
    menuitem: {
      padding: '15px 10px !important',
      fontSize: '15px',
    },
    menuItemIcon: {
      color: '#04225F !important',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: 'auto',
        marginRight: '5px',
      },
    },
    itemList: {
      display: 'flex',
      justifyContent: 'center !important',
      color: '#56565A',
    },
    selected: {
      backgroundColor: '#04225F !important',
      opacity: '0.7',
      color: 'white !important',
      display: 'flex',
      width: '100%',
    },
    profileImgContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    profileImg: {
      cursor: 'pointer',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  };
});
