import axios from 'axios';
import axiosPrivate from '../api/axios';
import { LOGIN } from '../constants/routes';
import { PasswordRecover } from '../interfaces/user';
import { User } from '../interfaces/user';

export const login = (username: string, password: string) => {
  return axios.post<User>(`${process.env.REACT_APP_CORE_API}${LOGIN}`, {
    username,
    password,
  });
};

export const recoverPassword = (data: PasswordRecover): Promise<any> => {
  return axios.post(`${process.env.REACT_APP_CORE_API}/recoverpassword`, {
    ...data,
    length: 8,
  });
};

export const checkToken = (): Promise<any> => {
  return axiosPrivate.get(`${process.env.REACT_APP_CORE_API}/checktoken`);
};
