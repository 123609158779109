import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import { ConfigCell, Order } from './utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  config: ConfigCell[];
  center?: boolean;
  selectable: boolean;
  actions: boolean;
}

export default function EnhancedTableHead(
  props: EnhancedTableProps
): JSX.Element {
  const {
    order,
    orderBy,
    onRequestSort,
    config,
    center = false,
    selectable,
    actions,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {config.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={center ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={selectable ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && (
          <TableCell align="center">
            <b>{t('common.table.actions')}</b>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
