import { SyntheticEvent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import ManagerDataDump from '../../components/ManagerDataDump/index';
import ManagerProvision from '../../components/ManagerProvision/index';
import { TabPanel } from '../../components/molecules/TabPanel';
import { a11yProps } from '../../utils/tabpanel';
import { useTranslation } from 'react-i18next';

const DataMaintainer = (): JSX.Element => {
  const { t, ready } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label={ready ? t('maintenance.dump') : 'Volcado'}
            {...a11yProps(0)}
          />
          <Tab
            label={ready ? t('maintenance.provision') : 'Provisión'}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ManagerDataDump />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ManagerProvision />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default DataMaintainer;
