import io from 'socket.io-client';

const URL = process.env.REACT_APP_CORE_API || '';

const PATH = '/api/core';

const socket = io(URL.replace(PATH, ''), {
  reconnection: true,
  withCredentials: true,
  transports: ['polling', 'flashsocket'],
  path: !URL.includes('localhost') ? `${PATH}/socket.io` : '',
});

export default socket;
