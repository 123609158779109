import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Typography,
  Toolbar,
  CircularProgress,
  CssBaseline,
} from '@mui/material';
import { destructureObject, DestructuresObject } from '../../utils/helpers';
import Table from '../../components/Table';
import { AxiosResponse } from 'axios';
import { getDeviceStatusFamocRemote } from '../../services/famoc';
import { getInfoNsignDevice } from '../../services/nsign';

export default function DeviceDetail(): JSX.Element {
  const { keyID } = useParams<{ keyID: string }>();
  const [service, id_name] = keyID.split('==');

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [device, setDevice] = useState<any>();
  const [deviceTable, setDeviceTable] = useState<DestructuresObject | null>(
    null
  );

  const done = (data: AxiosResponse | null) => {
    if (data && Array.isArray(data?.data)) {
      data.data = data.data[0];
    }
    const table = data ? destructureObject(data.data) : null;
    setLoading(false);
    setErrorMessage('');
    setDevice(data?.data);
    setDeviceTable(table);
  };

  const error = (err: any) => {
    console.error(err);
    setLoading(false);
    setErrorMessage('Dispositivo no encontrado');
  };

  const init = async () => {
    try {
      let data;
      switch (service.toUpperCase()) {
        case 'FAMOC':
          data = await getDeviceStatusFamocRemote(id_name);
          break;
        case 'NSIGN':
          data = await getInfoNsignDevice(id_name);
          break;
        default:
          data = null;
      }

      done(data);
    } catch (err: any) {
      error(err);
    }
  };

  useEffect(() => {
    init();
  }, [id_name]);

  return (
    <div>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" component="div">
            {device?.name || device?.deviceName || ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box p={2}>
        {errorMessage && (
          <Typography variant="h6" color="inherit" component="div">
            {errorMessage}
          </Typography>
        )}
        {loading && (
          <Box width={1} textAlign="center">
            <CircularProgress />
          </Box>
        )}
        {deviceTable && (
          <Box mt={-6}>
            <Table
              noPag
              dense
              rowsPerPage={20}
              rows={deviceTable.keys.map((key: string, inx: number) => ({
                property: key,
                value: deviceTable.values[inx],
              }))}
              config={[
                { id: 'property', label: '' },
                { id: 'value', label: '' },
              ]}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}
