import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { UserState } from '../../../../interfaces/user';
import { useAppSelector } from '../../../../redux';
import { useLockerMetricsReport } from '../../../../hooks/useLockerMetricsReport';
import NivoPieChart from '../../../../components/NivoPieChart';
import { useStyles } from './styles';
import DatePickerMenu from '../../BasicReport/DatePickerMenu';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DownloadIcon from '@mui/icons-material/Download';
import { visuallyHidden } from '@mui/utils';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isRolAdmin } from '../../../../utils/adminList';

const GrayTableCell = ({ children }: { children: string }) => {
  return (
    <TableCell sx={{ backgroundColor: '#E5E5E5', minWidth: '120px' }}>
      {children}
    </TableCell>
  );
};

const CustomTableCell = ({ children }: { children: string | number }) => {
  return <TableCell align="center">{children}</TableCell>;
};

const LockerMetrics = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { user } = useAppSelector<UserState>((state) => state.user);
  const hasManyCustomers =
    user.userToCustomer?.length === 1 && !isRolAdmin(user.role) ? false : true;
  const {
    chrtLastConnection,
    chrtRecurrent,
    clear,
    createSortHandler,
    csvData,
    customerList,
    handleAutocomplete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClean,
    headCells,
    isLoading,
    metricData,
    metricsReportHeads,
    metricTitle,
    order,
    orderBy,
    page,
    rowsPerPage,
    setDateFrom,
    setDateTo,
    totalRows,
  } = useLockerMetricsReport();

  return (
    <Grid key={clear}>
      <Typography variant="h4" className={classes.title}>
        {metricTitle}
      </Typography>
      <Box className={classes.chartSection}>
        <Box className={classes.chartContainer}>
          <Typography variant="h6" className={classes.chartTitle}>
            {ready
              ? t('reports.metrics-report.returning-users')
              : 'Usuarios recurrentes'}
          </Typography>
          <NivoPieChart
            data={chrtRecurrent}
            hasLinkLabels={false}
            margin={{ top: 40, right: 10, bottom: 10, left: 10 }}
            colors={['#A3E368', '#FCF380', '#FB7474']}
            hasLegends={false}
            cornerRadius={0}
            legendDirection="row"
            legendPosition="bottom"
            labelPercentage={true}
            isInteractive={false}
            labelColor="#000000"
          />
        </Box>
        <Box className={classes.chartContainer}>
          <Typography variant="h6" className={classes.chartTitle}>
            {ready
              ? t('reports.metrics-report.expired-orders')
              : 'Pedidos expirados'}
          </Typography>
          <NivoPieChart
            data={chrtLastConnection}
            hasLinkLabels={false}
            margin={{ top: 40, right: 10, bottom: 10, left: 10 }}
            colors={['#A3E368', '#FCF380', '#FB7474']}
            hasLegends={false}
            cornerRadius={0}
            legendDirection="row"
            legendPosition="bottom"
            labelPercentage={true}
            isInteractive={false}
            labelColor="#000000"
          />
        </Box>
      </Box>
      <Box className={classes.filterContainer}>
        <DatePickerMenu
          dateFrom={(date: string) => setDateFrom(date)}
          dateTo={(date: string) => setDateTo(date)}
        />
        {hasManyCustomers && (
          <Autocomplete
            sx={{ width: 200 }}
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            options={customerList}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(_event: any, newValue: any) =>
              handleAutocomplete('customer', newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('reports.customer') : 'Cliente'}
              />
            )}
          />
        )}
        <Tooltip
          title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
        >
          <Button variant="outlined" disableElevation onClick={handleClean}>
            <FilterAltOffIcon fontSize="large" />
          </Button>
        </Tooltip>
        <CSVLink
          ref={null}
          data={csvData}
          headers={metricsReportHeads}
          filename="report.csv"
        >
          <Tooltip
            title={String(ready ? t('reports.download-csv') : 'Descargar CSV')}
          >
            <Button variant="outlined" className={classes.downloadBtn}>
              <DownloadIcon color="primary" />
            </Button>
          </Tooltip>
        </CSVLink>
      </Box>
      <Paper elevation={0} className={classes.tableContainer}>
        <TableContainer className={classes.tableScroll}>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => {
                  return (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={
                        index < 3
                          ? { backgroundColor: '#E5E5E5' }
                          : { backgroundColor: '#FFFFFF' }
                      }
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {metricData.map((metric) => (
                <TableRow key={metric.id}>
                  <GrayTableCell>{metric.customer}</GrayTableCell>
                  <GrayTableCell>{metric.locker_id}</GrayTableCell>
                  <GrayTableCell>{metric.busines_type}</GrayTableCell>
                  <CustomTableCell>{metric.timestamp}</CustomTableCell>
                  <CustomTableCell>{metric.users_new}</CustomTableCell>
                  <CustomTableCell>{metric.user_recu}</CustomTableCell>
                  <CustomTableCell>{metric.uses_total}</CustomTableCell>
                  <CustomTableCell>{metric.user_time_avg_sec}</CustomTableCell>
                  <CustomTableCell>{metric.occupation_avg_per}</CustomTableCell>
                  <CustomTableCell>{metric.slots_busy}</CustomTableCell>
                  <CustomTableCell>{metric.slots_tot}</CustomTableCell>
                  <CustomTableCell>{metric.pickups_tot}</CustomTableCell>
                  <CustomTableCell>{metric.pickups_cart}</CustomTableCell>
                  <CustomTableCell>{metric.pickups_scooter}</CustomTableCell>
                  <CustomTableCell>{metric.pickups_bike}</CustomTableCell>
                  <CustomTableCell>{metric.dropoffs_tot}</CustomTableCell>
                  <CustomTableCell>{metric.dropoffs_cart}</CustomTableCell>
                  <CustomTableCell>{metric.dropoffs_scooter}</CustomTableCell>
                  <CustomTableCell>{metric.dropoffs_bike}</CustomTableCell>
                  <CustomTableCell>{metric.expired_orders}</CustomTableCell>
                  <CustomTableCell>{metric.curr_expired}</CustomTableCell>
                  <CustomTableCell>{metric.staff_retire}</CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', right: '0px', left: '0px' }}
          />
        )}
        <TablePagination
          labelRowsPerPage={ready ? t('common.table.row') : 'Filas por columna'}
          rowsPerPageOptions={[5]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={totalRows <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </Paper>
    </Grid>
  );
};

export default LockerMetrics;
