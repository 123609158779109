/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { User, UserState, Tokens } from '../../interfaces/user';

interface ModifiedUser {
  image: string;
  email: string;
  firstname: string;
  lastname: string;
}

interface Language {
  language: string;
}

const initialState: UserState = {
  user: {
    key: '',
    username: '',
    firstname: '',
    lastname: '',
    image: '',
    email: '',
    role: '',
    hasCustomers: false,
    token: '',
    refreshToken: '',
    permissions: [],
    crmGroup: undefined,
    language: '',
    updateSeed: 0,
    hasBusinessDashboard: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    refreshToken: (state, action: PayloadAction<Tokens>) => {
      state.user.token = action.payload.token;
      state.user.refreshToken = action.payload.refreshToken;
    },
    logout: () => initialState,
    setModifiedUser: (state, action: PayloadAction<ModifiedUser>) => {
      state.user.image = action.payload.image;
      state.user.email = action.payload.email;
      state.user.firstname = action.payload.firstname;
      state.user.lastname = action.payload.lastname;
      state.user.updateSeed = state.user.updateSeed
        ? state.user.updateSeed + 1
        : 1;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.user.language = action.payload.language;
    },
  },
});

export const { login, logout, setModifiedUser, setLanguage } =
  userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const selectToken = (state: RootState) => state.user.user.token;
export const selectRole = (state: RootState) => state.user.user.role;

export default userSlice.reducer;
