import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  settingsContainer: {
    position: 'relative',
    background: 'linear-gradient(310.83deg, #FFF 10.24%, #F4F4F4 89.03%)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    marginRight: '20px !important',
    borderRadius: '10px',
    padding: '20px',
  },
  titleContainer: {
    marginBottom: '5px',
  },
  boldText: {
    fontWeight: '700 !important',
    fontSize: '18px !important',
    color: '#56565A',
  },
  imagesContainer: {
    position: 'relative',
  },
  blurImgContainer: {
    height: '140px',
    overflow: 'hidden',
  },
  blurProfileImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(4px)',
    transform: 'scale(1.03)',
  },
  profileImgContainer: {
    position: 'absolute',
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    border: '4px solid white',
    bottom: '-30px',
    left: '30px',
  },
  profileImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  editImgBtn: {
    position: 'absolute',
    bottom: '15px',
    left: '145px',
    color: 'white',
  },
  nameContainer: {
    display: 'flex',
    paddingBottom: '20px',
  },
  dataContainer: {
    padding: '40px 10px',
  },
  roleContainer: {
    paddingBottom: '20px',
  },
  roleText: {
    fontWeight: '700 !important',
    color: '#56565A',
    fontSize: '14px !important',
    opacity: '0.7',
  },
  saveDataBtn: {
    width: '100%',
    position: 'absolute',
    bottom: '10px',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
});
