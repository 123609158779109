import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginTop: '10px',
      background: 'linear-gradient(310.83deg, #FFFFFF 10.24%, #F4F4F4 89.03%)',
      boxShadow: '-4px -8px 8px #FFFFFF, 4px 8px 8px rgba(25, 118, 210, 0.19)',
      borderBottomLeftRadius: '14px',
      borderBottomRightRadius: '14px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
        background: 'none',
        boxShadow: 'none',
        borderBottomLeftRadius: 'none',
        borderBottomRightRadius: 'none',
      },
    },
    cardStyle: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '0px !important',
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: '24px !important',
      },
    },
    main: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    titleText: {
      fontSize: '20px !important',
      color: '#56565A',
      [theme.breakpoints.down('xl')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down(1290)]: {
        fontSize: '15px !important',
      },
    },
    contentContainer: {
      padding: '0px 20px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    contentText: {
      color: '#04225F',
      fontWeight: 'bold !important',
      fontSize: '30px !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '20px !important',
      },
    },
    downlaodBtnContainer: {
      position: 'absolute',
      right: '-8px',
      bottom: '-15px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    iconButton: {
      border: '1px solid #04225F !important',
      width: '30px',
      height: '30px',
    },
    icon: {
      transform: 'scale(0.8)',
    },
    progressContainer: {
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        bottom: '-2px',
        left: '0px',
      },
      [theme.breakpoints.up('sm')]: {
        top: '0px',
        left: '0px',
      },
    },
    divider: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  };
});
