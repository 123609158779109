import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
    },
    tableContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 40px)',
      },
    },
  };
});
