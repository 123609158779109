import { useTranslation } from 'react-i18next';
import { Buttons } from '../interfaces/report';

export const useDatePickerButtons = () => {
  const { t } = useTranslation();

  const buttons: Buttons[] = [
    {
      id: 1,
      title: t('reports.date-filter.today'),
    },
    {
      id: 2,
      title: t('reports.date-filter.yesterday'),
    },
    {
      id: 3,
      title: t('reports.date-filter.last-seven'),
    },
    {
      id: 4,
      title: t('reports.date-filter.last-thirty'),
    },
    {
      id: 5,
      title: t('reports.date-filter.current-month'),
    },
    {
      id: 6,
      title: t('reports.date-filter.last-month'),
    },
    {
      id: 7,
      title: t('reports.date-filter.current-year'),
    },
    {
      id: 8,
      title: t('reports.date-filter.last-year'),
    },
    {
      id: 10,
      title: t('reports.date-filter.custom-range'),
    },
  ];
  return { buttons };
};
