import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    background: 'rgba(0, 128, 185, 0.05)',
    marginBottom: '30px',
    padding: '8px',
  },
  styledAutocomplete: {
    margin: '8px',
    flexGrow: '0',
    flexShrink: '1',
    flexBasis: '31%',
  },
  styledFormControl: {
    margin: '8px !important',
    width: '100%',
  },
  selectContainer: {
    marginRight: '16px',
  },
  filterButtonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterButtonContainer: {
    margin: '8px',
  },
});
