import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const PasswordInfo = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  const recomends = [
    {
      id: 1,
      text: ready
        ? t('user-settings.length-max')
        : 'Longitud máxima 20 caracteres',
    },
    {
      id: 2,
      text: ready
        ? t('user-settings.length-min')
        : 'Longitud mínima 8 caracteres',
    },
    {
      id: 3,
      text: ready
        ? t('user-settings.capital-letter')
        : 'Al menos una letra mayúscula',
    },
    {
      id: 4,
      text: ready
        ? t('user-settings.lowercase-letter')
        : 'Al menos una letra minúscula',
    },
    {
      id: 5,
      text: ready ? t('user-settings.number') : 'Al menos un número',
    },
    {
      id: 6,
      text: ready
        ? t('user-settings.special-caracter')
        : 'Al menos un carácter especial (+._!@#$%^&*)',
    },
  ];

  return (
    <>
      <Typography className={classes.title}>
        {ready
          ? t('user-settings.password-tip')
          : 'Recuerda que tu contraseña debe contener'}
      </Typography>
      {recomends.map((item) => (
        <Box className={classes.itemElement} key={item.id}>
          <CircleIcon className={classes.itemIcon} />
          <Typography className={classes.itemText}>{item.text}</Typography>
        </Box>
      ))}
    </>
  );
};

export default PasswordInfo;
