import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useInstallationsTable } from './useInstallationsTable';
import {
  headCells,
  MakeLinks,
} from '../../../components/GenericPaginatedTable/utils';
import { InstallationData } from '../../../interfaces/installations';
import { SUPPORT_DETAIL } from '../../../constants/routes';
import StatusChip from '../../../components/atoms/StatusChip';
import { useStyles } from './styles';
import {
  TableRow,
  Box,
  FormControlLabel,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
} from '@mui/material';

interface IInstallationTable {
  data: InstallationData[];
  rowsQuantity: number;
  rowsPerPage: number;
  page: number;
  loading: boolean;
}

const InstallationsTable = (props: IInstallationTable): JSX.Element => {
  const { data, rowsQuantity, rowsPerPage, page, loading } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });

  const {
    dense,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeDense,
    installationsColumn,
  } = useInstallationsTable(data);

  return (
    <Box className={classes.root}>
      <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={isMobile ? 'small' : dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {headCells(installationsColumn).map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any, index: any) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let hasSolutions = false;
                let sols: any[] = [];
                if (
                  typeof row.solutions === 'string' &&
                  row.solutions !== 'No hay soluciones'
                ) {
                  sols = JSON.parse(row.solutions);
                  hasSolutions = true;
                }
                return (
                  <TableRow key={`${row.installation}`}>
                    <TableCell align="center">
                      {row.crmGroup || t('common.sin-datos')}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      id={labelId}
                      scope="row"
                      style={{ width: '20%' }}
                    >
                      {row.client || t('common.sin-datos')}
                    </TableCell>
                    <TableCell align="center" style={{ width: '30%' }}>
                      <Link to={`${SUPPORT_DETAIL}/${row.id}`}>
                        {row.installation || t('common.sin-datos')}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <StatusChip status={row.status.toString() || ''} />
                    </TableCell>
                    <TableCell align="center">
                      {hasSolutions ? (
                        <MakeLinks solutions={sols} />
                      ) : (
                        t('support.no-solutions')
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <LinearProgress
            sx={{ position: 'absolute', right: '0px', left: '0px' }}
          />
        )}
        <TablePagination
          labelRowsPerPage={t('common.table.row')}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rowsQuantity}
          rowsPerPage={rowsPerPage}
          page={rowsQuantity <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {isDesktop && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label={String(t('common.compact-view'))}
        />
      )}
    </Box>
  );
};

export default InstallationsTable;
