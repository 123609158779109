import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import Query from 'query-string';
import { SelectChangeEvent } from '@mui/material';
import { toString } from 'lodash';
import { validURL } from '../utils/helpers';
import { DeviceType, ITableData } from '../interfaces/core';
import { QueryKey } from '../interfaces/platform';
import { getSolutionsPaginated, updatedDeviceType } from '../services/devices';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../redux';
import { useSelector } from 'react-redux';
import { rowsPerPageValue, changeRowsPerPage } from '../redux/table';

const useSolutionsColumn = () => {
  const { t, ready } = useTranslation();
  return [
    {
      id: 'name',
      label: ready ? t('table.solutions.name') : 'Soporte',
    },
    {
      id: 'link',
      label: ready ? t('table.solutions.link') : 'Procedimiento de soporte',
    },
    {
      id: 'created',
      label: ready ? t('table.solutions.created') : 'Fecha creacion',
    },
    {
      id: 'updated',
      label: ready ? t('table.solutions.updated') : 'Ultima modificacion',
    },
    {
      id: 'action',
      label: ready ? t('table.solutions.action') : 'Acciones',
    },
  ];
};

interface Props {
  location: string;
}

export const useSolutionTable = ({ location }: Props): any => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [allSolutions, setAllSolutions] = useState<any[]>([]);
  const [solutions, setSolutions] = useState<DeviceType[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [solutionSelected, setSolutionSelected] = useState<string>('0');
  const [dense, setDense] = useState(false);
  const [open, setOpenModal] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [editingSolution, setEditingSolution] = useState<Partial<DeviceType>>(
    {}
  );
  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const rowsPerPage = useSelector(rowsPerPageValue);

  const init = async () => {
    const types: AxiosResponse<ITableData> = await getSolutionsPag();
    setTotalRows(types.data.total);
    setAllSolutions(types.data.rows);
    setSolutions(types.data.rows);
  };

  const initLocationStates = () => {
    const query = Query.parse(location);
    setSolutionSelected(toString(query.s || 0));
  };

  const filterBySolutionName = useCallback(
    (allSols: DeviceType[]) => {
      if (!solutionSelected || solutionSelected == '0') {
        return allSols;
      }
      return allSols.filter((s) => String(s.id) === solutionSelected);
    },
    [solutionSelected]
  );

  const handleClean = () => {
    setSolutionSelected('0');
    history.replace('/solutions');
  };

  useEffect(() => {
    init();
    initLocationStates();
  }, []);

  useEffect(() => {
    let updated = [...allSolutions];
    updated = filterBySolutionName(updated);

    setSolutions(updated);
  }, [location, allSolutions]);

  const handleChangeSolution = (e: SelectChangeEvent) => {
    const value = e.target.value || '0';
    setSearchHistory('s', value);
    setSolutionSelected(String(value));
  };

  const setSearchHistory = useCallback(
    (key: QueryKey, value: any) => {
      const obj = Query.parse(location);
      obj[key] = value;
      const q = Query.stringify(obj);
      history.push(`?${q}`);
    },
    [location]
  );

  const buildData = useCallback(() => {
    return solutions.map((s) => ({
      id: s.id,
      name: s.name,
      link: s.link,
      invalidLink: s.invalidLink,
      created: DateTime.fromJSDate(new Date(s.created.toString())).toFormat(
        'dd/MM/y HH:mm'
      ),
      updated: s.updated
        ? DateTime.fromJSDate(new Date(s.updated.toString())).toFormat(
            'dd/MM/y HH:mm'
          )
        : null,
    }));
  }, [solutions]);

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const handlerEditBtn = (row: DeviceType) => () => {
    openModal();
    setEditingSolution(row);
  };

  const onChangeModal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const invalidLink = !!value && !validURL(value);
    setEditingSolution((prev) => ({ ...prev, link: value, invalidLink }));
  };

  const handleSaveLink = async () => {
    try {
      setLoadingBtn(true);
      await updatedDeviceType(editingSolution);
      await init();
      enqueueSnackbar('OK', { variant: 'success' });
    } catch (error: any) {
      const mge = error.response?.data || 'Falló al guardar';
      enqueueSnackbar(mge, { variant: 'error' });
    } finally {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setLoadingBtn(false);
    setEditingSolution({});
    closeModal();
  };

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(changeRowsPerPage(newRowsPerPage));
    setPage(0);
  };

  const getSolutionsPag = async (page = 0, rowsPerPage = 10) =>
    getSolutionsPaginated(page, rowsPerPage);

  return {
    allSolutions,
    dense,
    editingSolution,
    getSolutionsPag,
    handleChangeDense,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSolution,
    handleClean,
    handleCloseModal,
    handlerEditBtn,
    handleSaveLink,
    loadingBtn,
    onChangeModal,
    openModal: open,
    page,
    rows: buildData(),
    rowsPerPage,
    setSolutions,
    solutions,
    solutionsColumn: useSolutionsColumn(),
    solutionSelected,
    totalRows,
  };
};
