import { useStyles } from './styles';
import { Grid } from '@mui/material';
import NsignMetrics from './NsignMetrics';
import LockerMetrics from './LockerMetrics';
import { useLockerMetricsReport } from '../../../hooks/useLockerMetricsReport';
import { useNsignMetricsReport } from '../../../hooks/useNsignMetricsReport';

const MetricsReport = () => {
  const classes = useStyles();

  const { hasCustomers: hasCustomersLocker } = useLockerMetricsReport();
  const { hasCustomers: hasCustomersNsign } = useNsignMetricsReport();

  return (
    <Grid className={classes.tableContainer}>
      {hasCustomersNsign && <NsignMetrics />}
      {hasCustomersLocker && <LockerMetrics />}
    </Grid>
  );
};

export default MetricsReport;
