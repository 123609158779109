import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '70%',
      overflow: 'hidden',
      maxHeight: '90%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '95%',
      },
    },
  };
});
