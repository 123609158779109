import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface PathState {
  supportPath: string;
}

const initialState: PathState = {
  supportPath: '',
};

export const pathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {
    setSupportPath: (state, action: PayloadAction<string>) => {
      state.supportPath = action.payload;
    },
  },
});

export const { setSupportPath } = pathSlice.actions;
export const selectPath = (state: RootState) => state.path;
export default pathSlice.reducer;
