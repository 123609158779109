import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import { NSIGN_ACTIONS } from '../constants/actions';

axiosRetry(axiosPrivate, { retries: 3 });

//nsign - separar del core-api

export const rebootNsignDevice = (deviceId: string | number): Promise<any> => {
  return axiosPrivate.get('/nsign/actions', {
    params: { action: NSIGN_ACTIONS.REBOOT, id: deviceId },
  });
};

export const getInfoNsignDevice = (deviceId: number | string): Promise<any> => {
  return axiosPrivate.get('/nsign/actions', {
    params: { action: NSIGN_ACTIONS.INFO, id: deviceId },
  });
};

export const fabricRestoreNsignDevice = (
  deviceId: number | string
): Promise<any> => {
  return axiosPrivate.get('/nsign/actions', {
    params: { action: NSIGN_ACTIONS.RESET_FACTORY, id: deviceId },
  });
};
