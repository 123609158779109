import { LoggedUser } from '../interfaces/platform';
import { UserState } from '../interfaces/user';
import { useAppSelector } from '../redux';

export const getUserInfo = (): LoggedUser => {
  const { user } = useAppSelector<UserState>((state) => state.user);
  return {
    ...user,
    fullname: `${user?.username} ${user?.lastname}`,
  };
};
