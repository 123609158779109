import { toString } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import openWindow from 'window-open';
import Query from 'query-string';

import { DEVICE_DETAIL } from '../../constants/routes';
import { getInstallation } from '../../services/installation';
import { getTeamviewerAgent } from '../../services/data-manager';
import {
  InstallationDevice,
  InstallationGral,
  DevicesTypes,
} from '../../interfaces/core';
import { QueryKey } from '../../interfaces/platform';
import {
  changeChildrenCurrentPage,
  currentPageSupportDetail,
  rowsPerPageSupportDetail,
} from '../../redux/table';
import {
  fabricRestoreNsignDevice,
  rebootNsignDevice,
} from '../../services/nsign';
import { useAppDispatch } from '../../redux';
import CustomSnakbar from '../../components/molecules/CustomSnakbar';
import Detail from './Detail';
import SimpleModal, { SimpleModalProps } from '../../components/SimpleModal';
import SuportDetailTable from './SupportDetailTable';

import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import RotateLeft from '@mui/icons-material/RotateLeft';
import Tv from '@mui/icons-material/Tv';
import { Box, Snackbar, AlertColor } from '@mui/material';

interface SnackProps {
  color?: AlertColor;
  mge: string;
  open: boolean;
}

const SupportDetail = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation().search;
  const { installationId } = useParams<{ installationId: string }>();
  const rowsPerPage = useSelector(rowsPerPageSupportDetail);
  const currentPage = useSelector(currentPageSupportDetail);

  const [installation, setInstallation] = useState<
    InstallationGral | undefined
  >();
  const [devices, setDevices] = useState<InstallationDevice[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DevicesTypes[]>([]);
  const [typeSelected, setTypeSelected] = useState<string>('0');
  const [statusDevicesSelected, setStatusDeviceSelected] = useState<string[]>([
    'all',
  ]);
  const [snack, setSnack] = useState<SnackProps>({ open: false, mge: '' });
  const [sModal, setSimpleModal] = useState<SimpleModalProps>({ open: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
  }, [location, rowsPerPage, currentPage]);

  const init = async () => {
    const query = Query.parse(location);
    const statusList = toString(query.ds || 'all').split(',');
    const devicetypeId = toString(query.s);
    try {
      setLoading(true);
      const response = await getInstallation(
        Number(installationId),
        currentPage,
        rowsPerPage,
        statusList,
        Number(devicetypeId)
      );
      const { data }: { data: InstallationGral } = response.data;
      setInstallation(data);
      setDeviceTypes(data.devicesTypes);
      setDevices(data.devices);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const initLocationStates = () => {
    const query = Query.parse(location);
    setTypeSelected(toString(query.s || 0));
    setStatusDeviceSelected(toString(query.ds || 'all').split(','));
  };

  useEffect(() => {
    initLocationStates();
    dispatch(changeChildrenCurrentPage(0));
  }, [location]);

  const makeHistoryHandler = (
    key: QueryKey,
    setter: React.Dispatch<React.SetStateAction<any>>
  ) => {
    return function (a?: any) {
      setSearchHistory(key, a);
      setter(a);
    };
  };

  const setSearchHistory = useCallback(
    (key: QueryKey, value: any) => {
      const obj = Query.parse(location);
      obj[key] = value;
      const q = Query.stringify(obj);
      history.push(`?${q}`);
    },
    [location]
  );

  const rebootDevice = (row: any) => {
    setSnack({ open: true, mge: 'Procesando...', color: 'info' });
    const snk: SnackProps = { open: true, mge: 'OK' };
    rebootNsignDevice(row.nsignId)
      .then(() => {
        snk.color = 'success';
      })
      .catch((err) => {
        snk.mge = err.response?.data?.title
          ? `${err.response?.data?.title}: ${err.response?.data?.detail}`
          : 'No autorizado';
        snk.color = 'warning';
      })
      .finally(() => setSnack(snk));
  };

  const resetNsignDevice = (row: any) => {
    setSnack({ open: true, mge: 'Procesando...', color: 'info' });
    const snk: SnackProps = { open: true, mge: 'OK' };
    fabricRestoreNsignDevice(row.nsignId)
      .then(() => {
        snk.color = 'success';
      })
      .catch((err) => {
        snk.mge = err.response?.data?.title
          ? `${err.response?.data?.title}: ${err.response?.data?.detail}`
          : 'No autorizado';
        snk.color = 'warning';
      })
      .finally(() => setSnack(snk));
  };

  const detailNsignDevice = (row: any) => {
    openWindow(`${DEVICE_DETAIL}/nsign==${row.nsignId}`, {
      height: 480,
      name: row.name,
      width: 630,
    });
  };

  // const rebootDeviceFamoc = async (row: any) => {
  //   setSnack({ open: true, mge: 'Procesando...', color: 'info' });
  //   const snk: SnackProps = { open: true, mge: 'OK' };
  //   rebootDeviceFamocRemote(row.name, row.organizationId)
  //     .then((res) => {
  //       snk.color = 'success';
  //       snk.mge = res.data;
  //     })
  //     .catch((err) => {
  //       snk.mge = err.message || 'Error no identificado';
  //       snk.color = 'warning';
  //     })
  //     .finally(() => setSnack(snk));
  // };

  // const getDetailFamoc = (row: any) => {
  //   openWindow(`${DEVICE_DETAIL}/famoc==${row.name}`, {
  //     height: 480,
  //     name: row.name,
  //     width: 630,
  //   });
  // };

  const startRemoteConnection = async (row: any) => {
    const response = await getTeamviewerAgent(row.teamviewerAgent);
    const { agent }: any = response.data;
    window.open(`https://start.teamviewer.com/${agent.teamviewerId}`);
  };

  const tableActions = [
    // Nsign Actions
    {
      label: t('support-detail.restart-nsign-device'),
      icon: <RotateLeft color="primary" />,
      tooltip: t('support-detail.restart-nsign-device'),
      action: rebootDevice,
      isList: true,
      nsignId: true,
    },
    {
      label: t('support-detail.nsign-detail'),
      icon: <InsertDriveFile color="primary" />,
      tooltip: t('support-detail.nsign-detail'),
      action: detailNsignDevice,
      isList: true,
      nsignId: true,
    },
    {
      label: t('support-detail.factory-reset'),
      icon: <RotateLeft color="primary" />,
      tooltip: t('support-detail.factory-reset'),
      action: resetNsignDevice,
      isList: true,
      nsignId: true,
    },
    //FAMOC actions
    // {
    //   label: t('support-detail.restart-famoc-device'),
    //   icon: <RotateLeft color="primary" />,
    //   tooltip: t('support-detail.restart-famoc-device'),
    //   action: rebootDeviceFamoc,
    //   isList: true,
    //   famoc: true,
    // },
    // {
    //   label: t('support-detail.famoc-detail'),
    //   icon: <InsertDriveFile color="primary" />,
    //   tooltip: t('support-detail.famoc-detail'),
    //   action: getDetailFamoc,
    //   isList: true,
    //   famoc: true,
    // },

    // Team Viewer actions
    {
      label: t('support-detail.connect-to-teamviewer-devices'),
      icon: <Tv color="primary" />,
      tooltip: t('support-detail.connect-to-teamviewer-devices'),
      action: startRemoteConnection,
      isList: true,
      teamviewer: true,
    },
  ];

  return (
    <div>
      <SimpleModal
        {...sModal}
        onClose={() => setSimpleModal((m) => ({ ...m, open: false }))}
      />
      <Detail
        data={installation}
        deviceTypes={deviceTypes}
        handleSolutionChange={makeHistoryHandler('s', setTypeSelected)}
        handleStatusDeviceChange={makeHistoryHandler(
          'ds',
          setStatusDeviceSelected
        )}
        statusDevicesSelected={statusDevicesSelected}
        typeSelected={typeSelected}
      />
      <Box className={classes.tableContainer}>
        <SuportDetailTable
          data={devices}
          rowsQuantity={installation?.totalRows || '0'}
          actions={tableActions}
          loading={loading}
        />
      </Box>
      <Snackbar
        open={snack.open}
        autoHideDuration={4000}
        onClose={() => setSnack((prev) => ({ ...prev, open: false }))}
      >
        <CustomSnakbar
          severity={snack.color}
          sx={{ width: '100%' }}
          onClose={() => setSnack((prev) => ({ ...prev, open: false }))}
        >
          {snack.mge}
        </CustomSnakbar>
      </Snackbar>
    </div>
  );
};

export default SupportDetail;
