import { useState } from 'react';
import CustomMarker from './CustomMarker';
import { useStyles } from './styles';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Grid, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux';
import { setMapZoom } from '../../redux/map';
import { InstallationCoordinates } from '../../interfaces/installations';

interface GeoMapProps {
  dynamicCenter: CenterMap;
  zoom: number;
}

interface CenterMap {
  lat: number;
  lng: number;
}

const GeolocationMap = (props: GeoMapProps): JSX.Element => {
  const { dynamicCenter, zoom } = props;
  const { installationsCoordinates } = useAppSelector(
    (state) => state.installations
  );
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_NEXT_PUBLIC_MAPS_KEY || '',
  });
  const [map, setMap] = useState<any>({});

  const handleZoomChanged = () => {
    dispatch(setMapZoom(map.zoom ?? 6));
  };

  if (!isLoaded)
    return (
      <Grid className={classes.loadingContainer}>
        <CircularProgress />
      </Grid>
    );
  return (
    <GoogleMap
      zoom={zoom}
      center={dynamicCenter}
      mapContainerClassName={classes.root}
      onLoad={(map: any) => {
        setMap(map);
      }}
      onZoomChanged={handleZoomChanged}
    >
      {installationsCoordinates.map((marker: InstallationCoordinates) => (
        <CustomMarker locationInfo={marker} key={marker.installationId} />
      ))}
    </GoogleMap>
  );
};

export default GeolocationMap;
