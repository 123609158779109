import { Redirect, Route } from 'react-router';
import { PublicRouteProps } from '../interfaces/core';

export const PublicRoute = ({
  isLogged,
  component: Component,
  ...rest
}: PublicRouteProps): JSX.Element => {
  return (
    <Route
      {...rest}
      component={(props: any) =>
        !isLogged ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
