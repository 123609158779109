import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => {
  return {
    bodyModal: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
    },
    textModalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    locationIcon: {
      transform: 'scale(1.3)',
      margin: '0px 10px',
    },
    modalTittle: {
      fontSize: '1.5rem !important',
      padding: '20px 0px',
    },
    modalSubTitleBox: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '.5rem',
    },
    modalSubTitle: {
      fontSize: '1.3rem !important',
      fontWeight: '500',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    modalSubTitleBold: {
      fontSize: '1.3rem !important',
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    modalText: {
      fontSize: '1.1rem !important',
      fontWeight: '300',
      padding: '0px 20px 20px 20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: '2rem',
      maxWidth: '85%',
      gap: '.5rem',
    },
    itemsModalContainerDump: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '40%',
      borderRadius: '.3rem',
      border: 'solid 1px black',
    },
    modalBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    itemDump: {
      margin: '.5rem',
      width: '95%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
    },
    itemTittleDump: {
      backgroundColor: '#C6C6C6',
      gridArea: '1 / 1 / 2 / 3',
    },
    itemInsertKey: {
      gridArea: '2 / 1 / 4 / 3',
    },
    itemInsertValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridArea: '2 / 2 / 3 / 3',
    },
    itemUpdateKey: {
      gridArea: '3 / 1 / 4 / 2',
    },
    itemUpdateValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridArea: '3 / 2 / 4 / 3',
    },
    itemDeleteKey: {
      gridArea: '4 / 1 / 5 / 2',
    },
    itemDeleteValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridArea: '4 / 2 / 5 / 3',
    },
    itemTotalKey: {
      gridArea: '5 / 1 / 6 / 2',
    },
    itemTotalValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridArea: '5 / 2 / 6 / 3',
    },
    infoBox: {
      border: '1px red',
      marginBottom: '1.5rem',
      borderRadius: '.3rem',
    },
  };
});
