import axios from 'axios';
import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import {
  NsignMetricsResponse,
  LockerMetricsResponse,
} from '../interfaces/report';
import {
  MetricsBody,
  NsignMetricParams,
  LockerMetricParams,
  DownloadKpiBody,
  SendErrorBody,
  ClientInterface,
} from '../interfaces/core';

axiosRetry(axiosPrivate, { retries: 3 });

export const getClientMetrics = (body: MetricsBody): Promise<any> => {
  return axiosPrivate.post('/client-metrics', body);
};

export const getKpiReport = (body: DownloadKpiBody) => {
  return axiosPrivate.post('/front-kpi', body);
};

export const sendErrorLog = (body: SendErrorBody) => {
  return axios.post(
    `${process.env.REACT_APP_CORE_API}/front-error-detail`,
    body
  );
};

export const getBusinessDashboardUrl = (user: string) => {
  return axiosPrivate.get<ClientInterface[]>(
    `${process.env.REACT_APP_CORE_API}/businessDashboardByUser?username=${user}`
  );
};

export const getNsignMetrics = (params: NsignMetricParams) => {
  const { userName, ...body } = params;
  return axiosPrivate.post<NsignMetricsResponse>(
    `/getPlayersPaginated?username=${userName}`,
    body
  );
};

export const getLockerMetrics = (params: LockerMetricParams) => {
  const { userName, ...body } = params;
  return axiosPrivate.post<LockerMetricsResponse>(
    `/getLockerPaginated?username=${userName}`,
    body
  );
};
