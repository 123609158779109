import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Query from 'query-string';
import { SelectChangeEvent } from '@mui/material';
import { toString } from 'lodash';
import { ClientInterface } from '../interfaces/core';
import { QueryKey } from '../interfaces/platform';
import { useTranslation } from 'react-i18next';
import { getBusinessDashboardUrl } from '../services/report';
import { useAppSelector } from '../redux';
import { selectUser } from '../redux/user';

interface Props {
  location: string;
}

export const useReportsList = ({ location }: Props): any => {
  const history = useHistory();
  const { t, ready } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const [allClients, setAllClients] = useState<ClientInterface[]>([]);
  const [clientSelected, setClientSelected] = useState<number>(0);
  const [idClientSelected, setIdClientSelected] = useState(0);

  const init = async () => {
    try {
      const { data } = await getBusinessDashboardUrl(user.username);
      const allCustomerOpt: ClientInterface[] = [
        {
          id: 0,
          name: ready ? t('reports.all-dashboard') : 'Todos los dashboard',
        },
      ];
      setAllClients(allCustomerOpt.concat(data));
    } catch (e) {
      console.error(e);
    }
  };

  const initLocationStates = () => {
    const query = Query.parse(location);
    setClientSelected(parseInt(toString(query.s || 0)));
  };

  const handleClean = () => {
    setClientSelected(0);
    setIdClientSelected(0);
    history.replace('/reports');
  };

  const handleChangeClient = (e: SelectChangeEvent) => {
    const value = parseInt(e.target.value) || 0;
    setSearchHistory('s', value);
    const customerIdx = (element: ClientInterface) => element.id === value;
    setClientSelected(allClients.findIndex(customerIdx));
    setIdClientSelected(value);
  };

  const setSearchHistory = useCallback(
    (key: QueryKey, value: any) => {
      const obj = Query.parse(location);
      obj[key] = value;
      const q = Query.stringify(obj);
      history.push(`?${q}`);
    },
    [location]
  );

  useEffect(() => {
    init();
    initLocationStates();
  }, []);

  return {
    allClients,
    handleChangeClient,
    setClientSelected,
    clientSelected,
    handleClean,
    idClientSelected,
  };
};
