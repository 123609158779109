import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { changePassword } from '../../../services/user-settings';
import { UserState } from '../../../interfaces/user';
import PasswordInfo from './PasswordInfo';
import { useAppSelector } from '../../../redux';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';

interface State {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showRepeatPassword: boolean;
}

const PasswordForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { user: userRedux } = useAppSelector<UserState>((state) => state.user);
  const regex = /^(?=.*\d)(?=.*[+._!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const regexNoWhiteSpace = /^\S*$/;
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showRepeatPassword: false,
  };
  const [values, setValues] = useState<State>(initialState);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (item: string, value: boolean) => {
    setValues({
      ...values,
      [item]: !value,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const checkPasswordEquality = () => {
    if (
      regex.test(values.newPassword) &&
      regex.test(values.repeatNewPassword) &&
      values.newPassword !== values.repeatNewPassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkSavePassword = () => {
    if (
      values.currentPassword.length > 0 &&
      regex.test(values.newPassword) &&
      regex.test(values.repeatNewPassword) &&
      !checkPasswordEquality()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const submitPassword = async () => {
    try {
      setIsLoading(true);
      const res = await changePassword({
        username: userRedux?.username,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      setIsLoading(false);
      if (res.status === 200) {
        enqueueSnackbar(t('user-settings.psw-success'), {
          variant: 'success',
        });
        setValues(initialState);
      }
      if (res.status === 203) {
        enqueueSnackbar(t('user-settings.wrong-curr-psw'), {
          variant: 'error',
        });
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(t('user-settings.save-psw-err'), {
        variant: 'error',
      });
      console.error(err);
    }
  };

  return (
    <Grid item xs={8} xl={9} className={classes.passwordContainer}>
      <Box className={classes.passwordTitleContainer}>
        <Typography className={classes.boldText}>
          {ready ? t('user-settings.change-password') : 'Cambiar Contraseña'}
        </Typography>
      </Box>
      <Grid className={classes.passwordMain}>
        <Grid item xs={6}>
          <Grid item xs={12} md={11} xl={8}>
            <Typography className={classes.stepsText}>
              {ready
                ? t('user-settings.step-1')
                : '1 - Introduce la contraseña actual.'}
            </Typography>
            <FormControl sx={{ m: 1, mb: 2.5 }} variant="standard" fullWidth>
              <InputLabel htmlFor="standard-adornment-currentPassword">
                {ready ? t('user-settings.password') : 'Contraseña'}
              </InputLabel>
              <Input
                autoComplete="new-password"
                data-testid="curr-psw-input"
                id="standard-adornment-currentPassword"
                type={values.showCurrentPassword ? 'text' : 'password'}
                value={values.currentPassword}
                onChange={handleChange('currentPassword')}
                inputProps={{
                  maxLength: 20,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(
                          'showCurrentPassword',
                          values.showCurrentPassword
                        )
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showCurrentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={11} xl={8}>
            <Typography className={classes.stepsText}>
              {ready
                ? t('user-settings.step-2')
                : '2 - Introduce la nueva contraseña.'}
            </Typography>
            <FormControl sx={{ m: 1, mb: 2.5 }} variant="standard" fullWidth>
              <InputLabel htmlFor="standard-adornment-password">
                {ready ? t('user-settings.password') : 'Contraseña'}
              </InputLabel>
              <Input
                autoComplete="new-password"
                data-testid="new-psw-input"
                error={
                  (values.newPassword !== '' &&
                    !regex.test(values.newPassword)) ||
                  !regexNoWhiteSpace.test(values.newPassword)
                }
                id="standard-adornment-password"
                type={values.showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                inputProps={{
                  maxLength: 20,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(
                          'showNewPassword',
                          values.showNewPassword
                        )
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={11} xl={8}>
            <Typography className={classes.stepsText}>
              {ready
                ? t('user-settings.step-3')
                : '3 - Repite la nueva contraseña.'}
            </Typography>
            <FormControl sx={{ m: 1, mb: 2.5 }} variant="standard" fullWidth>
              <InputLabel htmlFor="standard-adornment-password">
                {ready ? t('user-settings.password') : 'Contraseña'}
              </InputLabel>
              <Input
                autoComplete="new-password"
                data-testid="repeat-psw-input"
                error={
                  values.repeatNewPassword !== '' &&
                  !regex.test(values.repeatNewPassword)
                }
                id="standard-adornment-repeatNewPassword"
                type={values.showRepeatPassword ? 'text' : 'password'}
                value={values.repeatNewPassword}
                onChange={handleChange('repeatNewPassword')}
                inputProps={{
                  maxLength: 20,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(
                          'showRepeatPassword',
                          values.showRepeatPassword
                        )
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showRepeatPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {checkPasswordEquality() ? (
            <Grid
              item
              xs={12}
              md={11}
              xl={8}
              className={classes.errorContainer}
            >
              <Typography className={classes.comparePassword}>
                {t('user-settings.password-error')}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={6} className={classes.infoContainer}>
          <PasswordInfo />
        </Grid>
      </Grid>
      <Grid className={classes.savePswContainer}>
        <Button
          data-testid="save-btn"
          startIcon={
            isLoading ? <CircularProgress size={20} /> : <SaveRoundedIcon />
          }
          disableElevation
          disabled={checkSavePassword()}
          onClick={submitPassword}
        >
          {ready ? t('user-settings.save-password') : 'Guardar Contraseña'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PasswordForm;
