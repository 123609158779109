import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { manualProvision, getServiceLogs } from '../../services/support';
import StatusChip from '../../components/atoms/StatusChip';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { LoadingButton } from '@mui/lab';
import { useStyles } from './styles';
import { DateTime } from 'luxon';
import {
  Grid,
  Box,
  Alert,
  AlertColor,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

interface AlertModal {
  open: boolean;
  message?: string;
  color: AlertColor;
}

interface DataLog {
  name: string;
  data: any[];
}

const FormProvision = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [historyLogs, setHistoryLogs] = useState<DataLog[]>([]);
  const [alert, setAlert] = useState<AlertModal>({
    open: false,
    color: 'info',
  });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const handleProvisionStart = async () => {
    setLoading(true);
    const alrt: AlertModal = { open: true, color: 'info' };
    try {
      await manualProvision({ services: ['nsign', 'famoc', 'teamViewer'] });
      alrt.message = ready
        ? t('provision.starting-provision')
        : 'Iniciando Provisión.';
    } catch (err: any) {
      if (!err?.response || err?.response?.status >= 500) {
        alrt.message = ready
          ? t('provision.starting-provision-error')
          : 'Hubo un error al iniciar la provisión';
        alrt.color = 'error';
        return;
      }
      alrt.message = err.response.data?.message;
      alrt.color = 'warning';
    } finally {
      setAlert(alrt);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const res: AxiosResponse = await getServiceLogs();
        const dataLogs: DataLog[] = res.data;
        setHistoryLogs(dataLogs);
      } catch (error) {
        setAlert({
          open: true,
          color: 'error',
          message: 'Hubo un error al recuperar los datos',
        });
      }
    };

    fetchLogs();
  }, []);

  return (
    <Grid>
      <Collapse in={alert.open}>
        <Alert
          className={classes.alert}
          severity={alert.color}
          onClose={() => setAlert({ open: false, color: 'info' })}
        >
          {alert.message}
        </Alert>
      </Collapse>

      <Box className={classes.root}>
        <Grid className={classes.main}>
          <LoadingButton
            loading={loading}
            variant="contained"
            disableElevation
            className={classes.loadingButton}
            onClick={handleProvisionStart}
          >
            {ready ? t('provision.start') : 'Iniciar Provision'}
          </LoadingButton>
        </Grid>
        <Typography className={classes.provisionResultText}>
          {ready
            ? t('provision.last-privision-registered')
            : 'Resultados de la última provisión'}
        </Typography>

        <Box className={classes.tableContainer}>
          <TableContainer component={Paper}>
            <Table size={isMobile ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('table.formProvision.service')}</TableCell>
                  <TableCell align="center">
                    {t('table.formProvision.status')}
                  </TableCell>
                  <TableCell align="left">
                    {t('table.formProvision.result')}
                  </TableCell>
                  <TableCell align="right">
                    {t('table.formProvision.date')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyLogs.map((service: DataLog, inx: number) => (
                  <TableRow
                    key={inx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {String(service.name).toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                      <StatusChip status={service.data?.[0]?.status ?? ''} />
                    </TableCell>
                    <TableCell align="left">
                      {service.data?.[0]?.message}
                    </TableCell>
                    <TableCell align="right">
                      {service.data?.[0]?.created_at
                        ? DateTime.fromJSDate(
                            new Date(service.data?.[0]?.created_at?.toString())
                          ).toFormat('dd/MM/y HH:mm')
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Grid>
  );
};

export default FormProvision;
