import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: 'rgba(0, 128, 185, 0.05)',
      borderRadius: '8px',
      padding: '24px',
      marginTop: '16px',
      marginBottom: '40px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    customerDataContainer: {
      display: 'flex',
    },
    selectContainer: {
      display: 'flex',
      [theme.breakpoints.down('xl')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '8px',
      },
    },
    styledFormControl: {
      margin: '8px',
      [theme.breakpoints.down('xl')]: {
        margin: '8px 0px',
      },
    },
    infoIcon: {
      marginRight: '24px',
      height: '100%',
      color: '#919191',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 5px',
      },
    },
    installationDetailContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: 'rgba(0, 128, 185, 0.05)',
      borderRadius: '8px',
      marginBottom: '40px',
      padding: '24px',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0px',
    },
    dataText: {
      fontSize: '1.2rem !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1rem !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem !important',
      },
    },
    itemIcon: {
      transform: 'scale(0.5)',
    },
  };
});
