import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  menuContainer: {
    display: 'flex',
  },
  buttonsContainer: {
    display: 'grid',
  },
  styledBtn: {
    margin: '2px 5px !important',
  },
  datePickersContainer: {
    display: 'grid',
    alignContent: 'space-between',
  },
  pickerContainer: {
    margin: '10px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2px 14px',
  },
});
