const enum RoleEnum {
  admin = 'Admin',
  l1 = 'L1',
  l2 = 'L2', // as same as Admin permissions
  clientLevelOne = 'Cliente Primer Nivel', // as same as L1
  clientFranchise = 'Cliente Franquicia', // as same as L1
  superAdmin = 'Superadmin', // as same as Admin permissions
  supportAdmin = 'Admin Soporte', // as same as Admin permissions
  agentSupport = 'Agente Soporte',
  excecutive = 'Directivo', // as same as Admin permissions
  commercialManager = 'Manager Comercial', // as same as L1
  commercialAgent = 'Agente Comercial', // as same as L1
}

const ROLES_ADMIN = [
  RoleEnum.admin,
  RoleEnum.l2,
  RoleEnum.superAdmin,
  RoleEnum.supportAdmin,
  RoleEnum.excecutive,
];

export const isRolAdmin = (rolValidate: string) =>
  ROLES_ADMIN.some((rol: RoleEnum) => rol === rolValidate);
