const palette = {
  primary: {
    main: '#04225F',
  },
  secondary: {
    main: '#4EC3E0',
  },
  lightgray: {
    main: '#B1B3B3',
  },
  darkgray: {
    main: '#53565A',
  },
  accent: {
    main: '#FFA300',
  },
};

export default palette;
