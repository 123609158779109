import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import installationsReducer from './installations';
import userReducer from './user';
import pathReducer from './path';
import mapReducer from './map';
import tableReducer from './table';

const rootReducer = combineReducers({
  user: userReducer,
  installations: installationsReducer,
  path: pathReducer,
  map: mapReducer,
  table: tableReducer,
});

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_STORAGE_KEY || '',
  onError: function (error) {
    console.error('modified localstorage data ', error);
  },
});

const persistConfig = {
  key: `${process.env.REACT_APP_STORAGE_NAME}-store`,
  version: 5,
  storage,
  blacklist: ['path', 'installations'],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        warnAfter: 128,
      },
      immutableCheck: { warnAfter: 128 },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
