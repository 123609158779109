import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux';
import {
  changeCurrentPage,
  changeOrder,
  changeOrderBy,
  changeRowsPerPage,
  currentPageValue,
  orderByDashboardTable,
  orderDashboardTable,
  rowsPerPageValue,
} from '../../redux/table';
import { TableHeadType, Row, RowDevices } from '../../interfaces/dashboard';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  LinearProgress,
} from '@mui/material';
import { useStyles } from './styles';
import { changeOrderDirection, OrderTypes } from '../../utils/helpers';

interface RowProps {
  row: Row;
  collapsibleTableHead: TableHeadType[];
  collapsibleRowTitle: string;
}

const CollapsibleRow = (props: RowProps) => {
  const { t, ready } = useTranslation();
  const { row, collapsibleTableHead, collapsibleRowTitle } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {Object.values(row)
          .slice(1, -1)
          .map((cell, index: number) => (
            <TableCell key={index}>
              {cell.length
                ? cell
                : ready
                ? t('collapsibleTable.noData')
                : 'Sin datos'}
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {row.devices.length ? (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {collapsibleRowTitle}
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {collapsibleTableHead.map(
                          (cell: TableHeadType, index: number) => (
                            <TableCell key={index}>{cell.label}</TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.devices.map(
                        (deviceList: RowDevices, index: number) => (
                          <TableRow key={index}>
                            {Object.values(deviceList).map(
                              (device: string, idx) => (
                                <TableCell key={idx}>{device}</TableCell>
                              )
                            )}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <Typography variant="h6" gutterBottom component="div">
                  {ready ? t('collapsibleTable.noData') : 'Sin datos'}
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

interface CollapsibleTableProps {
  tableHead: TableHeadType[];
  rows: Row[];
  totalRows: number;
  loading: boolean;
  collapsibleTableHead: TableHeadType[];
  collapsibleRowTitle: string;
}

const CollapsibleTable = (props: CollapsibleTableProps) => {
  const {
    tableHead,
    rows,
    totalRows,
    loading,
    collapsibleTableHead,
    collapsibleRowTitle,
  } = props;
  const { t, ready } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const rowsPerPage = useSelector(rowsPerPageValue);
  const page = useSelector(currentPageValue);
  const order = useSelector(orderDashboardTable);
  const orderBy = useSelector(orderByDashboardTable);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(changeCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(changeCurrentPage(0));
  };

  useEffect(() => {
    dispatch(changeCurrentPage(0));
  }, []);

  const handleOrder = (newName: string): OrderTypes => {
    return newName === orderBy ? changeOrderDirection(order) : 'ASC';
  };

  const handleTittle = (name: string) => {
    dispatch(changeOrderBy(name));
    dispatch(changeOrder(handleOrder(name) as string));
  };

  interface IArrowDirection {
    columnName: string;
    orderBy: string;
    order: string;
  }
  function ArrowDirection({
    columnName,
    orderBy,
    order,
  }: IArrowDirection): JSX.Element {
    const isOrderBy = columnName === orderBy;

    return isOrderBy ? (
      order === 'ASC' ? (
        <ArrowUpwardIcon fontSize="small" />
      ) : (
        <ArrowDownwardIcon fontSize="small" />
      )
    ) : (
      <></>
    );
  }

  return (
    <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
      <TableContainer>
        <Table
          size={isMobile ? 'small' : 'medium'}
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {tableHead?.map((cell: TableHeadType) => (
                <TableCell key={cell.id} onClick={() => handleTittle(cell.key)}>
                  <Box className={classes.tableCell}>
                    <Typography className={classes.cellTittle}>
                      {cell.label}
                    </Typography>
                    <ArrowDirection
                      columnName={cell.key}
                      orderBy={orderBy as string}
                      order={order as string}
                    />
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: Row) => (
              <CollapsibleRow
                key={row.id}
                row={row}
                collapsibleTableHead={collapsibleTableHead}
                collapsibleRowTitle={collapsibleRowTitle}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <LinearProgress
          sx={{ position: 'absolute', right: '0px', left: '0px' }}
        />
      )}
      <TablePagination
        labelRowsPerPage={ready ? t('common.table.row') : 'Filas por columna'}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={totalRows <= 0 ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CollapsibleTable;
