import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { getFirstDayOfLastYearGlobal } from '../utils/helpers';
import { getNsignMetrics } from '../services/report';
import { NsignMetric, ListElement, ChartData } from '../interfaces/report';
import { metricNames } from '../utils/helpers';
import { useAppSelector } from '../redux';
import { selectUser } from '../redux/user';

interface DataFilter {
  customer: string;
  deviceName: string;
}

type Order = 'asc' | 'desc';

export const useNsignMetricsReport = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const dateNow = DateTime.now().toJSDate();
  const initialDate = getFirstDayOfLastYearGlobal().toISOString().split('T')[0];
  const untilDate = dateNow.toISOString().split('T')[0];
  const [isLoading, setIsLoading] = useState(false);
  const [clear, setClear] = useState(1);
  const [dateFrom, setDateFrom] = useState(initialDate);
  const [dateTo, setDateTo] = useState(untilDate);
  const [metricData, setMetricData] = useState<NsignMetric[]>([]);
  const [customerList, setCustomerList] = useState<ListElement[]>([]);
  const [deviceList, setDeviceList] = useState<ListElement[]>([]);
  const [hasCustomers, setHasCustomers] = useState<boolean>(false);
  const [chrtLastConnection, setChrtLastConnection] = useState<ChartData[]>([]);
  const [chrtSpace, setChrtSpace] = useState<ChartData[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [metricTitle, setMetricTitle] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('customer');
  const [dataFilter, setDataFilter] = useState<DataFilter>({
    customer: '',
    deviceName: '',
  });

  const headCells = [
    {
      id: 'customer',
      label: t('reports.metrics-report.customer'),
    },
    {
      id: 'deviceName',
      label: t('reports.metrics-report.device-name'),
    },
    {
      id: 'deviceStatus',
      label: t('reports.metrics-report.device-status'),
    },
    {
      id: 'lastConnection',
      label: t('reports.metrics-report.last-connection'),
    },
    {
      id: 'lat',
      label: t('reports.metrics-report.latitude'),
    },
    {
      id: 'long',
      label: t('reports.metrics-report.longitude'),
    },
    {
      id: 'spaceAvailable',
      label: t('reports.metrics-report.free-space'),
    },
    {
      id: 'resolution',
      label: t('reports.metrics-report.resolution'),
    },
  ];

  const metricsReportHeads = [
    {
      label: t('reports.metrics-report.customer'),
      key: 'customer',
    },
    {
      label: t('reports.metrics-report.device-name'),
      key: 'deviceName',
    },
    {
      label: t('reports.metrics-report.device-status'),
      key: 'deviceStatus',
    },
    {
      label: t('reports.metrics-report.last-connection'),
      key: 'lastConnection',
    },
    {
      label: t('reports.metrics-report.latitude'),
      key: 'lat',
    },
    {
      label: t('reports.metrics-report.longitude'),
      key: 'long',
    },
    {
      label: t('reports.metrics-report.free-space'),
      key: 'spaceAvailable',
    },
    {
      label: t('reports.metrics-report.resolution'),
      key: 'resolution',
    },
  ];

  const init = async () => {
    try {
      setIsLoading(true);
      const { data } = await getNsignMetrics({
        rowsPerPage: rowsPerPage,
        pageNumber: page,
        dateFrom: dateFrom,
        dateTo: dateTo,
        customer: dataFilter.customer || null,
        device: dataFilter.deviceName || null,
        userName: user.username,
        order,
        orderBy,
      }).finally(() => setIsLoading(false));
      const {
        name,
        total,
        metrics,
        customers,
        hasCustomers,
        devices,
        chartLastConnection,
        chartSpace,
      } = data;
      setMetricData(
        metrics.map((item) => ({
          ...item,
          lastConnection: DateTime.fromISO(
            item.lastConnection || dateNow.toDateString()
          ).toFormat('dd-MM-yyyy HH:mm'),
        }))
      );
      setCustomerList(customers);
      setDeviceList(devices);
      setTotalRows(parseInt(total.totalRows));
      setHasCustomers(hasCustomers);
      setMetricTitle(metricNames[name] || name);
      setChrtLastConnection(chartLastConnection);
      setChrtSpace(chartSpace);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    init();
  }, [dataFilter, dateFrom, dateTo, page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    setPage(0);
  }, [dataFilter, dateFrom, dateTo, rowsPerPage, order, orderBy]);

  const handleAutocomplete = (key: string, newValue: any) => {
    if (newValue) {
      setDataFilter({ ...dataFilter, [key]: newValue.label });
    } else {
      setDataFilter({ ...dataFilter, [key]: '' });
    }
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCsvData = () => {
    return metricData.map((metric) => ({
      customer: metric.customer.name,
      deviceName: metric.name,
      deviceStatus: metric.customer.status,
      lastConnection: metric.lastConnection,
      lat: metric.latitude,
      long: metric.longitude,
      spaceAvailable: metric.space,
      resolution: metric.resolution,
    }));
  };

  const handleClean = () => {
    setDataFilter({ customer: '', deviceName: '' });
    setClear(Math.random());
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return {
    chrtLastConnection,
    chrtSpace,
    clear,
    createSortHandler,
    csvData: getCsvData(),
    customerList,
    deviceList,
    hasCustomers,
    handleAutocomplete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClean,
    headCells,
    isLoading,
    metricData,
    metricsReportHeads,
    metricTitle,
    order,
    orderBy,
    page,
    rowsPerPage,
    setDateFrom,
    setDateTo,
    totalRows,
  };
};
