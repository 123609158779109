import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import { DeviceGraph } from '../interfaces/device';
import { DeviceType, DashFilter } from '../interfaces/core';

axiosRetry(axiosPrivate, { retries: 3 });

//core-api devices conexion directa a la db

export const getDeviceTypes = (): Promise<any> => {
  return axiosPrivate.get('/devicetypes');
};

export const updatedDeviceType = (body: Partial<DeviceType>): Promise<any> => {
  return axiosPrivate.post(`/devicetypes/${body.id}`, body);
};

export const getSolutionsPaginated = (currentPage = 0, rowsPerPage = 10) => {
  return axiosPrivate.get(
    `/devicetypes/paginated?page=${currentPage}&rows=${rowsPerPage}`
  );
};

export const getGroupedDevices = (body: DashFilter) => {
  return axiosPrivate.post<DeviceGraph>('/devices-graph', body);
};
