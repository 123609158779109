import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import { ChangePassBody } from '../interfaces/core';

axiosRetry(axiosPrivate, { retries: 3 });

export const updateUser = (body: FormData): Promise<any> => {
  return axiosPrivate.post('/editUser', body);
};

export const changePassword = (body: ChangePassBody): Promise<any> => {
  return axiosPrivate.put('/changePassword', body);
};
