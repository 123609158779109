import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import { DumpPart } from '../interfaces/dump';

axiosRetry(axiosPrivate, { retries: 3 });

export const startDump = (dumpPart: DumpPart): Promise<any> => {
  return axiosPrivate.post('/jira-data', dumpPart);
};

export const getTeamviewerAgent = (key: string): Promise<any> => {
  return axiosPrivate.get('/teamviewer-agent-key', {
    params: { key },
  });
};

// eslint-disable-next-line
export const postSaveTimeProcess = (data: any): Promise<any> => {
  return axiosPrivate.post('/save-time-process', data);
};

// eslint-disable-next-line
export const postSaveTimeProvision = (data: any): Promise<any> => {
  return axiosPrivate.post('/save-time-provision', data);
};
