import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: '-0.8px 0.8px 0 0',
      borderEndEndRadius: '10px',
      borderEndStartRadius: '10px',
    },
    main: {
      background: 'rgba(0, 128, 185, 0.05)',
      padding: '16px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    alert: {
      margin: '8px',
      borderRadius: '8px',
    },
    provisionResultText: {
      color: '#56565A',
      fontSize: '25px !important',
      padding: '20px 10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important',
      },
    },
    loadingButton: {
      height: '56px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tableContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 41px)',
      },
    },
  };
});
