import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
  },
  errorContainer: {
    background: 'rgb(4, 34, 95, 0.8)',
    display: 'grid',
    gap: '1rem',
    maxWidth: '600px',
    padding: '20px',
    margin: '5px',
  },
  topContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  hmyImage: {
    width: '150px',
  },
  icon: {
    color: 'white',
    transform: 'scale(2.5)',
    transformOrigin: 'right',
  },
  button: {
    backgroundColor: '#04225F !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#506EAD',
    },
  },
  sendReportText: {
    display: 'flex',
  },
  hereText: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  textBoxContainer: {
    border: '2px solid white',
    borderRadius: '5px',
    padding: '0.5rem',
  },
});
