import { useMediaQuery } from 'react-responsive';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';

const LayoutSwitcher = ({ children }: any) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });
  return (
    <>
      {isMobile && <MobileLayout>{children}</MobileLayout>}
      {isDesktop && <DesktopLayout>{children}</DesktopLayout>}
    </>
  );
};

export default LayoutSwitcher;
