import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  status: string;
  style?: object;
}

const StatusChip = ({ status, style = {} }: Props): JSX.Element => {
  const { t, ready } = useTranslation();
  const [color, setColor] = useState<any>('warning');
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (ready) {
      switch (status.toLocaleLowerCase()) {
        case 'active':
          setColor('success');
          setLabel(t('common.active'));
          break;
        case 'inactive':
          setColor('error');
          setLabel(t('common.inactive'));
          break;
        case 'success':
        case 'completado':
          setColor('success');
          setLabel(t('common.success'));
          break;
        case 'ok':
          setColor('success');
          setLabel(t('common.success'));
          break;
        case 'error':
          setColor('error');
          setLabel(t('common.error'));
          break;
        case 'fallo':
          setColor('error');
          setLabel(t('common.error'));
          break;
        case 'fail':
          setColor('error');
          setLabel(t('common.error'));
          break;
        case 'completado con errores':
          setColor('warning');
          setLabel(t('common.success-with-errors'));
          break;
        case 'pending':
        case 'en progreso':
          setColor('info');
          setLabel(t('common.pending'));
          break;
        case 'action needed':
          setColor('warning');
          setLabel(t('common.action-needed'));
          break;
        default:
          setColor('warning');
          setLabel(t('common.stateless'));
          break;
      }
    }
  }, [ready, status, t]);

  return <Chip label={label} color={color} style={style} />;
};

export default StatusChip;
