import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    fontWeight: '700 !important',
    fontSize: '14px !important',
    color: '#56565A',
    marginBottom: '10px !important',
  },
  itemElement: {
    display: 'flex',
  },
  itemIcon: {
    color: '#56565A',
    transform: 'scale(0.3)',
  },
  itemText: {
    color: '#56565A',
    fontSize: '14px !important',
  },
});
