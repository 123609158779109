import { useMediaQuery } from 'react-responsive';
import SimpleModal from '../../components/SimpleModal';
import { useSolutionTable } from '../../hooks/useSolutionTable';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { sortBy } from 'lodash';
import { useStyles } from './styles';
import { DateTime } from 'luxon';
import EditIcon from '@mui/icons-material/EditRounded';
import { headCells } from '../../components/GenericPaginatedTable/utils';
import { RiFilterOffFill } from 'react-icons/ri';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  FormControlLabel,
  Switch,
  TableHead,
} from '@mui/material';

const Solution = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const location = useLocation().search;
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });

  const {
    dense,
    rowsPerPage,
    page,
    handleChangeSolution,
    solutionSelected,
    handleClean,
    loadingBtn,
    openModal,
    editingSolution,
    onChangeModal,
    handleSaveLink,
    handleCloseModal,
    solutions: data,
    handlerEditBtn,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeDense,
    solutionsColumn,
  } = useSolutionTable({ location });

  return (
    <div>
      <SimpleModal
        open={openModal}
        title={
          ready
            ? t('solutions.titleModal', { name: editingSolution.name })
            : 'Editar procedimiento para: ' + editingSolution.name
        }
        onClose={handleCloseModal}
        sx={{ width: 0.5 }}
      >
        <TextField
          fullWidth
          sx={{ my: 1.2 }}
          label={t('common.link')}
          value={editingSolution.link}
          onChange={onChangeModal}
          error={editingSolution.invalidLink}
          helperText={
            editingSolution.invalidLink
              ? ready
                ? t('solutions.helperTextLinkError')
                : 'URL no válida.'
              : ready
              ? t('solutions.helperTextLink')
              : 'Use url validas y seguras (https)'
          }
        />
        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            loading={loadingBtn}
            variant="contained"
            sx={{ ml: 1 }}
            disabled={!!editingSolution.link && editingSolution.invalidLink}
            onClick={handleSaveLink}
          >
            {ready ? t('solutions.save') : 'Guardar'}
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            sx={{ ml: 1 }}
            onClick={handleCloseModal}
          >
            {ready ? t('solutions.cancel') : 'Cancelar'}
          </Button>
        </Box>
      </SimpleModal>

      <Grid className={classes.filterContainer}>
        <Grid item xs={12} md={8} lg={6} className={classes.selectContainer}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="solution-select-label">
              {ready ? t('solutions.solution') : 'Solución'}
            </InputLabel>
            <Select
              labelId="solution-select-label"
              id="solution-select"
              data-testid="solution-select"
              value={solutionSelected}
              label={ready ? t('solutions.solution') : 'Solución'}
              onChange={handleChangeSolution}
            >
              {[
                {
                  id: 0,
                  name: ready
                    ? t('solutions.all-solutions')
                    : 'Todas las Soluciones',
                },
              ]
                .concat(sortBy(data, 'name'))
                .map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Tooltip
            title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
          >
            <Button
              variant="contained"
              sx={{ height: 56, m: 1 }}
              onClick={handleClean}
              data-testid="clear-btn"
            >
              <RiFilterOffFill size={26} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Box className={classes.tableContainer}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={isMobile ? 'small' : dense ? 'small' : 'medium'}
            >
              <TableHead>
                <TableRow>
                  {headCells(solutionsColumn).map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="center"
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="table-body">
                {data.map((row: any) => {
                  return (
                    <TableRow key={`${row.id}`}>
                      <TableCell align="center">
                        {row.name || 'Sin datos'}
                      </TableCell>
                      <TableCell align="center">
                        {row.link ? (
                          <a
                            href={String(row.link)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {String(row.link).length > 45
                              ? String(row.link).slice(0, 45) + '...'
                              : row.link}
                          </a>
                        ) : (
                          <span>
                            {ready ? t('solutions.noLink') : 'No disponible'}
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {DateTime.fromJSDate(
                          new Date(row.created as string)
                        ).toFormat('dd/MM/y HH:mm')}
                      </TableCell>
                      <TableCell align="center">
                        {row.updated
                          ? DateTime.fromJSDate(
                              new Date(row.updated as string)
                            ).toFormat('dd/MM/y HH:mm')
                          : 'Sin datos'}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={String(
                            ready
                              ? t('common.change-procedure')
                              : 'Cambia el procedimiento de soporte'
                          )}
                        >
                          <IconButton
                            onClick={handlerEditBtn(row)}
                            role="button"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={t('common.table.row')}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {isDesktop && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label={String(t('common.compact-view'))}
          />
        )}
      </Box>
    </div>
  );
};

export default Solution;
