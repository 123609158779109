import { LoadingButton } from '@mui/lab';
import { AlertColor, Box, Button, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomSnakbar from '../../../components/molecules/CustomSnakbar';
import SimpleModal from '../../../components/SimpleModal';
import { PasswordRecover } from '../../../interfaces/user';
import { recoverPassword } from '../../../services/auth';

const PaswordRecovery = (): JSX.Element => {
  const { t, ready } = useTranslation();
  const [recover, setRecover] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('error');
  const [msgSnack, setMsgSnack] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const handleCloseSnack = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const onSubmit = async (values: PasswordRecover) => {
    await recoverPassword(values)
      .then((res) => {
        const snackSeverity =
          res.status === 200 || res.status === 203 ? 'success' : 'error';
        const msgCode = res.data.msgCode;
        setSeverity(snackSeverity);
        setMsgSnack(msgCode);
      })
      .catch((e) => {
        console.error(e);
      });
    reset({ username: '', email: '' });
    setRecover(false);
    setOpenSnack(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          fullWidth
          onClick={() => setRecover(true)}
          disableRipple
          sx={{ marginBottom: '16px' }}
        >
          {ready ? t('login.password-recovery') : 'Recuperar contraseña'}
        </Button>
      </Box>
      {recover && (
        <SimpleModal
          open={recover}
          title={ready ? t('login.password-recovery') : 'Recuperar contraseña'}
          onClose={() => setRecover(false)}
          sx={{
            width: '40vw',
            px: '4rem',
            py: '3rem',
            ['@media (max-width:600px)']: {
              px: '1rem',
              py: '1rem',
            },
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              data-testid="data-username"
              label={ready ? t('login.username') : 'Usuario'}
              autoComplete="username"
              autoFocus
              {...register('username', { required: 'Ingrese su usuario' })}
              error={errors.username ? true : false}
              name="username"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              data-testid="data-email"
              label={ready ? t('support.email') : 'Email'}
              autoComplete="email"
              {...register('email', { required: 'Ingrese su email' })}
              error={errors.email ? true : false}
              name="email"
            />
            <LoadingButton
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3, mb: 2 }}
            >
              {ready
                ? t('login.temporal-password')
                : 'Obtener contraseña temporal'}
            </LoadingButton>
          </Box>
        </SimpleModal>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={8000}
        onClose={handleCloseSnack}
      >
        <CustomSnakbar
          onClose={handleCloseSnack}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {ready ? t(`login.${msgSnack}`) : 'Generic message'}
        </CustomSnakbar>
      </Snackbar>
    </>
  );
};

export default PaswordRecovery;
