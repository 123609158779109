export interface DumpPart {
  agent: boolean;
  contactPerson: boolean;
  crmGroup: boolean;
  customer: boolean;
  devices: boolean;
  installations: boolean;
  platformAccount: boolean;
  teamviewerAgent: boolean;
}

export const DUMP_ALL: DumpPart = {
  agent: true,
  contactPerson: true,
  crmGroup: true,
  customer: true,
  devices: true,
  installations: true,
  platformAccount: true,
  teamviewerAgent: true,
};
export interface Status {
  insert: number;
  update: number;
  delete: number;
  total: number;
  error?: string;
}
export interface DumpModifications {
  platformAccount: Status;
  businessDashboardPlatform: Status;
  customer: Status;
  agent: Status;
  teamViewerAgent: Status;
  contactPerson: Status;
  installation: Status;
  devices: Status;
  crmGroup: Status;
}

export interface DumpResult {
  id: number;
  finished: string;
  modifications: DumpModifications;
  status: string;
  info: string;
}
