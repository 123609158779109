import { ButtonTypeMap } from '@mui/material';
import { CrmGroup, Customer } from './core';

export type BtnColor = ButtonTypeMap['props']['color'];

enum StatusValue {
  'Action Needed',
  'Active',
  'Inactive',
}

export type StatusLabel = string;
export const statusLabels = Object.keys(StatusValue).filter((k) =>
  isNaN(Number(k))
);

enum SearchKeys {
  /** 1 */
  s = 'solution',
  i = 'installation name',
  a = 'address name',
  ds = 'device status',
  c = 'customer name',
  g = 'crm group',
}

export type QueryKey = keyof typeof SearchKeys;

/**
 * DOCS: Added new filters to installations.
 * Steps new filters:
 *
 * 1.- Agregar nueva key para el filter en el enum superior, será la key en la url, y servirá tambien
 *   para history. El string como value es meramente informativo.
 *
 * 2.- Crear el fltro correspondiente a la funcionalidad. Usar useCallback que escuche su state
 *   para mejorar rendimiento. La funcion debe tener los siguientes requisitos:
 *   - recibe como parametro un array de installaciones (podran ser todas o las ya filtradas).
 *   - debe retornar un array de installaciones según criterio de filtro del state que esta escuchando.
 *   - debe tener una 'salida rápida'. si el criterio es 'todos', 'cero' o similar debe retornar el
 *   mismo array entrante.
 *
 * 3.- Agregar dicho filtro a la función 'runFilters', de manera que se irá sobreescribiedo el array.
 *
 * 4.- Agregar el setState necesario en el objeto de retorno al final del archivo, para usarlas en
 *   el componente de UI. Usar 'makeHandler'. Esta funcion se encarga del history ademas del state,
 *   recibe como paremtro la key identificatoria (la creada en el punto 1) y la funcion setter del state.
 *
 * 5.- Agregar el comportamiento inicial del state. Esto es cuando NO existe la key en la query,
 *   es decir, el valor es especificamente 'undefined' (y el undefined rompe toodo :v).
 *   *yapa: El metodo 'toString' de lodash convierte undefined a cadena vacia, y los array como join()
 *
 * 6.- Agregar valor default en el 'handleClean'. Este será el valor seteado al usar el boton 'Limpiar filtros'.
 *
 * extras:
 * A.- En caso de necesitar valores y parametros extras de otros endpoints, filtros extras que afecten
 *   al TOTAL de installaciones, agregarlo en el init, creando una funcion con async await, (tal como se
 *   cargan los deviceStatus). Asi ya el allInstallation tiene lo requerido para arrancar.
 *
 * B.- En caso de usar listas customizadas según se van filtrando las cosas, agragar dichos setters en
 *   el useEffect de customsLists.
 *
 */

export interface LoggedUser {
  crmGroup?: CrmGroup;
  firstname: string;
  fullname: string;
  hasCustomers: boolean;
  key: string;
  language?: string;
  lastname: string;
  refreshToken: string;
  role: string;
  token: string;
  username: string;
  userToCustomer?: UserToCustomer[];
  hasBusinessDashboards?: boolean;
}

export interface UserToCustomer {
  customer: Customer;
  customerId: number;
  userId: number;
  userToCustomerId: number;
}

export interface StatusBtnChip {
  color: BtnColor;
  icon: JSX.Element;
  status: string;
}
