import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SimpleModal from '../SimpleModal';
import { useStyles } from './styles';
import AddchartIcon from '@mui/icons-material/Addchart';
import { DumpResult } from '../../interfaces/dump';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import AdjustIcon from '@mui/icons-material/Adjust';
interface Props {
  dumpOpen: boolean;
  setDumpOpen: (value: boolean) => void;
  dumpData?: DumpResult;
}

const DumpDetail = ({
  dumpOpen,
  setDumpOpen,
  dumpData,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [colorStatus, setColorStatus] = useState<string>('');
  const [labelStatus, setLabelStatus] = useState<string>('');
  const [messageStatus, setMessageStatus] = useState<string>('');

  useEffect(() => {
    if (dumpData) {
      const { status } = dumpData;
      switch (status.toLocaleLowerCase()) {
        case 'completado':
          setColorStatus('#E3FCEF');
          setLabelStatus(t('common.success'));
          setMessageStatus(t('common.success-message'));
          break;
        case 'completado con errores':
          setColorStatus('#fcf1e3');
          setLabelStatus(t('common.success-with-errors'));
          setMessageStatus(t('common.success-with-errors-message'));
          break;
        case 'en progreso':
          setColorStatus('#e3e4fc');
          setLabelStatus(t('common.pending'));
          setMessageStatus(t('common.pending-message'));
          break;
        default:
          setColorStatus('#fce3e3');
          setLabelStatus(t('common.error'));
          setMessageStatus(t('common.error-message'));
          break;
      }
    }
  }, [dumpData]);

  const IconStatus = (status?: string) => {
    if (!status) return <></>;
    switch (status.toLocaleLowerCase()) {
      case 'completado':
        return <CheckCircleIcon style={{ color: 'green' }} />;
      case 'completado con errores':
        return <ReportIcon style={{ color: 'orange' }} />;
      case 'en progreso':
        return <HourglassBottomIcon style={{ color: 'purple' }} />;
      default:
        return <DoDisturbOnIcon style={{ color: 'red' }} />;
    }
  };

  return (
    <SimpleModal
      open={dumpOpen}
      onClose={() => setDumpOpen(false)}
      sx={{ width: 0.4 }}
    >
      <Box className={classes.bodyModal}>
        <Box className={classes.textModalContainer}>
          <AddchartIcon className={classes.locationIcon} />
          <Typography
            variant="h6"
            color="initial"
            className={classes.modalTittle}
          >
            {ready
              ? t('maintenance.dump-finiched')
              : 'Finalizó proceso de volcado de datos'}
          </Typography>
        </Box>
        <Box className={classes.infoBox} sx={{ backgroundColor: colorStatus }}>
          <Box className={classes.modalSubTitleBox}>
            {IconStatus(dumpData?.status)}
            <Typography
              color="initial"
              className={classes.modalSubTitleBold}
              sx={{ fontWeight: 'bold' }}
            >
              {ready ? t('common.status') : ''}
            </Typography>
            <Typography color="initial" className={classes.modalSubTitle}>
              {ready ? t(labelStatus) : ''}
            </Typography>
          </Box>
          <Typography color="initial" className={classes.modalText}>
            {ready ? `${t(messageStatus)}` : ''}
          </Typography>
          {dumpData?.info && (
            <Typography
              color="initial"
              className={classes.modalText}
              sx={{ marginLeft: '1rem' }}
            >
              <AdjustIcon fontSize="small" />
              {dumpData?.info}
            </Typography>
          )}
        </Box>
        {dumpData && (
          <Box className={classes.itemsModalContainerDump}>
            {Object.entries(dumpData.modifications).map(
              ([key, value]) =>
                value?.total >= 0 && (
                  <Box className={classes.itemDump} key={key}>
                    <Box className={classes.itemTittleDump}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {t(`dataDump.checkDump.${key}`)}
                      </Typography>
                    </Box>
                    <Box className={classes.itemInsertKey}>
                      <Typography>{t('dataDump.status.inserted')}</Typography>
                    </Box>
                    <Box className={classes.itemInsertValue}>
                      <Typography>{value?.insert}</Typography>
                    </Box>
                    <Box className={classes.itemUpdateKey}>
                      <Typography>{t('dataDump.status.updated')}</Typography>
                    </Box>
                    <Box className={classes.itemUpdateValue}>
                      <Typography>{value?.update}</Typography>
                    </Box>
                    <Box className={classes.itemDeleteKey}>
                      <Typography>{t('dataDump.status.deleted')}</Typography>
                    </Box>
                    <Box className={classes.itemDeleteValue}>
                      <Typography>{value?.delete}</Typography>
                    </Box>
                    <Box className={classes.itemTotalKey}>
                      <Typography>{t('dataDump.status.total')}</Typography>
                    </Box>
                    <Box className={classes.itemTotalValue}>
                      <Typography>{value?.total}</Typography>
                    </Box>
                  </Box>
                )
            )}
          </Box>
        )}
        <Box className={classes.modalBtnContainer}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => setDumpOpen(false)}
          >
            {t('common.close')}
          </Button>
        </Box>
      </Box>
    </SimpleModal>
  );
};

export default DumpDetail;
