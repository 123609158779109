import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      background: 'rgba(0, 128, 185, 0.05)',
      padding: '8px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
    autoCompleteGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'left',
    },
    autoCompleteContainer: {
      width: '100%',
      padding: '8px',
      '@media print': {
        width: '50%',
        [theme.breakpoints.up('xs')]: {
          width: '20% !important',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '20%',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: '100%',
      '@media print': {
        display: 'none !important',
      },
    },
    clearButton: {
      height: '56px',
      marginRight: '10px !important',
    },
    downloadButton: {
      height: '56px',
      marginRight: '10px !important',
    },
  };
});
