/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMediaQuery } from 'react-responsive';
import {
  DASHBOARD,
  DATA_MAINTAINER,
  REPORT,
  SOLUTION,
  SUPPORT,
} from '../constants/routes';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HandymanIcon from '@mui/icons-material/Handyman';
import HomeIcon from '@mui/icons-material/Home';
import { useAuth } from '../auth/useAuth';
import { LinkItemProps } from '../interfaces/core';
import { useTranslation } from 'react-i18next';

export const useMenuOptions = () => {
  const { t, ready } = useTranslation();
  const { getAccessByPath } = useAuth();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const MenuItems: Array<LinkItemProps> = [
    {
      name: ready ? t('navbar.home') : 'Inicio',
      icon: <HomeIcon />,
      selectedIcon: <HomeIcon style={{ color: 'white' }} />,
      uri: DASHBOARD,
      role: getAccessByPath(DASHBOARD)!,
    },
    {
      name: ready ? t('navbar.support') : 'Soporte',
      icon: <SupportAgentIcon />,
      selectedIcon: <SupportAgentIcon style={{ color: 'white' }} />,
      uri: SUPPORT,
      role: getAccessByPath(SUPPORT)!,
    },
    {
      name: ready ? t('navbar.maintenance') : 'Mantenimiento',
      icon: <HandymanIcon />,
      selectedIcon: <HandymanIcon style={{ color: 'white' }} />,
      uri: DATA_MAINTAINER,
      role: getAccessByPath(DATA_MAINTAINER)!,
    },
    {
      name: ready ? t('navbar.solutions') : 'Soluciones',
      icon: <ImportantDevicesIcon />,
      selectedIcon: <ImportantDevicesIcon style={{ color: 'white' }} />,
      uri: SOLUTION,
      role: getAccessByPath(SOLUTION)!,
    },
    {
      name: ready ? t('navbar.reports') : 'Reportes',
      icon: <AssessmentIcon />,
      selectedIcon: <AssessmentIcon style={{ color: 'white' }} />,
      uri: REPORT,
      role: getAccessByPath(REPORT)!,
    },
  ];

  //report menu button removed in mobile layout
  const LinkItems = isMobile
    ? MenuItems.filter((item) => item.uri !== REPORT)
    : MenuItems;

  return { LinkItems };
};
