/* eslint-disable */
import { Modal, Typography, Paper, Box } from '@mui/material';
import { useStyles } from './styles';
export interface SimpleModalProps {
  title?: string;
  content?: string;
  children?: any;
  onClose?: Function;
  open: boolean;
  sx?: any;
}

export default function SimpleModal(props: SimpleModalProps) {
  const { open, title, content, children, onClose, sx = {} } = props;
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          if (onClose) onClose();
        }}
      >
        <Paper
          className={classes.root}
          sx={{
            ...sx,
          }}
        >
          <Box
            sx={{
              p: 3,
              maxHeight: '90vh',
              overflow: 'auto',
            }}
          >
            {!!title && (
              <Typography
                variant="h6"
                color="initial"
                sx={{ fontSize: '1.5rem' }}
              >
                {title}
              </Typography>
            )}
            <Box>{content ? <pre>{content}</pre> : <div>{children}</div>}</Box>
          </Box>
        </Paper>
      </Modal>
    </div>
  );
}
