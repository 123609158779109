import { useTranslation } from 'react-i18next';
import { remove, last } from 'lodash';
import { RiFilterOffFill } from 'react-icons/ri';
import { statusLabels } from '../../../../interfaces/platform';
import { useStyles } from './styles';
import { DeviceType } from '../../../../interfaces/core';
import {
  Grid,
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';

interface InstFilterProps {
  searchCustomer: string;
  searchInstall: string;
  setSearchCustomer: (a?: any) => void;
  setSearchInstall: (a?: any) => void;
  typeSelected: string;
  setTypeSelected: (a?: any) => void;
  types: DeviceType[];
  handleClean: () => void;
  optionsCustomer: string[];
  optionsInstall: string[];
  setDeviceStatusSelected: (a?: any) => void;
  deviceStatusSelected: string[];
  optionsCrmGroup: string[];
  searchCrmGroup: string;
  setSearchCrmGroup: (a?: any) => void;
}

const DesktopFilter = (props: InstFilterProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const {
    searchCustomer,
    searchInstall,
    setSearchCustomer,
    setSearchInstall,
    typeSelected,
    setTypeSelected,
    types,
    handleClean,
    optionsCustomer,
    optionsInstall,
    setDeviceStatusSelected,
    deviceStatusSelected,
    optionsCrmGroup,
    searchCrmGroup,
    setSearchCrmGroup,
  } = props;

  const deviceStatusList = ['all'].concat(statusLabels).map((status) => ({
    id: status,
    name: t(`support.statusChip.${status}`),
  }));

  return (
    <Grid className={classes.main}>
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          clearText={t('common.clear')}
          openText={t('common.open')}
          closeText={t('common.close')}
          disablePortal
          id="combo-box-crm-group"
          options={optionsCrmGroup}
          className={classes.styledAutocomplete}
          value={searchCrmGroup}
          data-testid="crm-groups"
          isOptionEqualToValue={(option: any, value: any) =>
            option.value === value.value
          }
          onChange={(_event: any, newValue: string | null) => {
            setSearchCrmGroup(newValue ? `${newValue}` : '');
          }}
          getOptionLabel={(option) => option || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={ready ? t('dashboard.crmGroups') : 'Grupos'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          clearText={t('common.clear')}
          openText={t('common.open')}
          closeText={t('common.close')}
          disablePortal
          id="combo-box-demo"
          options={optionsCustomer}
          className={classes.styledAutocomplete}
          value={searchCustomer}
          data-testid="customer"
          isOptionEqualToValue={(option: any, value: any) =>
            option.value === value.value
          }
          onChange={(_event, newValue: string | null) => {
            setSearchCustomer(newValue ? `${newValue}` : '');
          }}
          getOptionLabel={(option) => option || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={ready ? t('dashboard.customer') : 'Cliente'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          clearText={t('common.clear')}
          openText={t('common.open')}
          closeText={t('common.close')}
          disablePortal
          id="combo-box-demo-1"
          options={optionsInstall}
          className={classes.styledAutocomplete}
          value={searchInstall}
          data-testid="installation"
          isOptionEqualToValue={(option: any, value: any) =>
            option.value === value.value
          }
          onChange={(_event, newValue: string | null) => {
            setSearchInstall(newValue ? `${newValue}` : '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={ready ? t('dashboard.installation') : 'Instalación'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl className={classes.styledFormControl}>
          <Grid className={classes.selectContainer}>
            <InputLabel id="demo-simple-select-label">
              {ready ? t('dashboard.solution') : 'Solución'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="Alcampo Kiosk"
              value={typeSelected}
              data-testid="solution"
              label={ready ? t('dashboard.solution') : 'Solución'}
              fullWidth
              onChange={(event: SelectChangeEvent) => {
                setTypeSelected(event.target.value);
              }}
            >
              {[
                {
                  id: 0,
                  name: ready
                    ? t('support.all-solutions')
                    : 'Todas las Soluciones',
                },
              ]
                .concat(types)
                .map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FormControl className={classes.styledFormControl}>
          <Grid className={classes.selectContainer}>
            <InputLabel id="multiple-staus-device-select">
              {ready
                ? t('support.device-availability')
                : 'Disponibilidad de dispositivo'}
            </InputLabel>
            <Select
              labelId="multiple-staus-device-select"
              id="multiple-staus-device-select"
              multiple
              value={deviceStatusSelected}
              data-testid="availability"
              label={
                ready
                  ? t('support.device-availability')
                  : 'Disponibilidad de dispositivo'
              }
              fullWidth
              onChange={(
                event: SelectChangeEvent<typeof deviceStatusSelected>
              ) => {
                const value = event.target.value;
                let toSet =
                  typeof value === 'string' ? value.split(',') : value;
                if (last(toSet) == 'all') {
                  toSet = ['all'];
                } else if (toSet.includes('all')) {
                  remove(toSet, (s: string) => s == 'all');
                }
                setDeviceStatusSelected(toSet);
              }}
              onClose={() => {
                if (!deviceStatusSelected.length) {
                  setDeviceStatusSelected(['all']);
                }
              }}
              renderValue={(selected) =>
                selected
                  .map((s: any) =>
                    ready ? t(`support.statusChip.${s}`) : 'Todos los estados'
                  )
                  .join(', ')
              }
            >
              {deviceStatusList.map((dSt) => (
                <MenuItem key={dSt.id} value={dSt.id}>
                  <Checkbox
                    checked={
                      !!deviceStatusSelected.find((s: any) => s === dSt.id)
                    }
                  />
                  <ListItemText primary={dSt.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={4} className={classes.filterButtonSection}>
        <Tooltip
          title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
        >
          <Grid className={classes.filterButtonContainer}>
            <Button
              variant="contained"
              disableElevation
              data-testid="clearFilterButton"
              sx={{ height: 56 }}
              onClick={handleClean}
            >
              <RiFilterOffFill size={26} />
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default DesktopFilter;
