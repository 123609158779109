import { useTranslation } from 'react-i18next';
import { useFormat } from './useFormat';
import { TableHeadType, Row } from '../interfaces/dashboard';
import { InstallationData, DeviceData } from '../interfaces/installations';

export const useDashboardTable = ({
  paginatedInstallations,
}: {
  paginatedInstallations: InstallationData[];
}) => {
  const { textFormat } = useFormat();
  const { t, ready } = useTranslation();

  //Encabezados principales de la tabla
  const tableHead: TableHeadType[] = [
    {
      id: 1,
      label: ready ? t('table.dashboard.crmGroup') : 'Grupo CRM',
      key: 'crmGroup',
    },
    {
      id: 2,
      label: ready ? t('table.dashboard.customer') : 'Cliente',
      key: 'customer',
    },
    {
      id: 3,
      label: ready ? t('table.dashboard.status') : 'Estado',
      key: 'status',
    },
    {
      id: 4,
      label: ready ? t('table.dashboard.country') : 'Pais',
      key: 'country',
    },
    { id: 5, label: ready ? t('table.dashboard.town') : 'Ciudad', key: 'town' },
    {
      id: 6,
      label: ready ? t('table.dashboard.address') : 'Dirección',
      key: 'address',
    },
  ];

  //Encabezados de la fila desplegable
  const collapsibleTableHead: TableHeadType[] = [
    {
      id: 1,
      label: ready ? t('collapsibleTable.dashboard.device') : 'Dispositivo',
      key: 'device',
    },
    {
      id: 2,
      label: ready ? t('collapsibleTable.dashboard.status') : 'Estado',
      key: 'status',
    },
    {
      id: 3,
      label: ready ? t('collapsibleTable.dashboard.name') : 'Nombre',
      key: 'name',
    },
    {
      id: 4,
      label: ready ? t('collapsibleTable.dashboard.model') : 'Modelo',
      key: 'model',
    },
    {
      id: 5,
      label: ready
        ? t('collapsibleTable.dashboard.operative-system')
        : 'Sistema Operativo',
      key: 'operative-system',
    },
  ];

  //Titulo de la fila desplegable
  const collapsibleRowTitle: string = ready
    ? t('collapsibleTable.dashboard.collapsible-row-title')
    : 'Dispositivos';

  //Funcion que prepara el array de datos tal y como se mostrará
  const buildData = (): Row[] => {
    return paginatedInstallations?.map((item: InstallationData) => ({
      id: item.id, //el id debe ir obligatoriamente al comienzo
      //---el orden de los datos siguientes indicarán el orden que se vera en la tabla
      crmGroup: item.customer?.crmGroup.name,
      customer: item.customer?.name,
      status: textFormat[item.status] || item.status,
      country: textFormat[item.country] || item.country,
      town: item.town,
      address: item.address,
      //---
      //el dato que contiene el array que representara la fila desplegable debe ir obligatoriamente al final
      devices: item.devices.map((device: DeviceData) => ({
        //---el orden de los datos siguientes indicarán el orden que se vera en la tabla
        device: device.deviceType.name,
        status:
          textFormat[device.deviceType.status] || device.deviceType.status,
        name: device.name,
        model: device.model,
        operativeSystem: device.operativeSystem,
        //---
      })),
    }));
  };

  return {
    collapsibleRowTitle,
    collapsibleTableHead,
    rows: buildData(),
    tableHead,
  };
};
