import { Grid } from '@mui/material';
import { useStyles } from './styles';

const CustomDivider = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid className={classes.main} />
    </Grid>
  );
};

export default CustomDivider;
