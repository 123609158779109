import { useState, useEffect } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Tooltip,
} from '@mui/material';
import * as React from 'react';

interface PropsInterface {
  actionsProp: any;
  data: any;
  position: number;
  indexTable: number[];
  remoteOperations: boolean;
}

const Actions = (props: PropsInterface): JSX.Element => {
  const { actionsProp, data, position, indexTable, remoteOperations } = props;
  const [ids, setIds] = useState([]);
  const [actions, setActions] = useState(actionsProp);
  const [openOptions, setOpenOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement[] | null[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (remoteOperations) {
      selectActions();
    }
  }, [setActions]);

  const populateActions = () => {
    const showActions = actions.filter((action: any) => !action.isList);
    if (showActions.length > 0) {
      return showActions.map((action: any) => {
        return (
          <Tooltip title={action.tooltip} key={action.tooltip}>
            <IconButton onClick={() => action.action(data)}>
              {action.icon}
            </IconButton>
          </Tooltip>
        );
      });
    } else {
      return (
        <Tooltip title={String(t('support-detail.no-agent-asociated'))}>
          <p style={{ cursor: 'pointer' }}>{t('support-detail.no-actions')}</p>
        </Tooltip>
      );
    }
  };

  const selectActions = () => {
    // Geting Nsight actions
    const actionsNsight = actions.filter(
      (action: any) => action.nsignId && data.nsignId
    );
    // Geting FAMOC actions
    const actionsFamoc = actions.filter(
      (action: any) => action.famoc && data.famocAgent
    );
    // Geting Teamviewer actions
    const actionsTeamviewer = actions.filter(
      (action: any) => action.teamviewer && data.teamviewerAgent
    );
    const newActions = [
      ...actionsNsight,
      ...actionsFamoc,
      ...actionsTeamviewer,
    ];
    if (newActions.length === 1) {
      newActions[0].isList = false;
    }
    if (newActions.length >= 2) {
      newActions[0].isList = false;
      newActions[1].isList = false;
    }
    setActions(newActions);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    updateAcctions(event, index);
  };

  const handleClose = (index: number) => {
    updateAcctions(null, index);
  };

  const populateActionList = () => {
    const listActions = actions.filter((action: any) => action.isList);
    return listActions.length > 0 ? (
      <>
        <Tooltip title={String(t('support-detail.more-options'))}>
          <IconButton
            aria-describedby={ids[position]}
            onClick={(e) => handleClick(e, position)}
          >
            <MoreVert color="primary" />
          </IconButton>
        </Tooltip>
        <Popover
          id={ids[position]}
          open={openOptions[position] ?? false}
          anchorEl={anchorEl[position]}
          onClose={() => handleClose(position)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List dense={true} disablePadding={true}>
            {populateItems(listActions, data, position)}
          </List>
        </Popover>
      </>
    ) : (
      <></>
    );
  };

  const populateItems = (listActions: any[], row: any, index: any) => {
    return listActions.map((action: any) => {
      return (
        <ListItem disablePadding={true} key={action.label + index}>
          <ListItemButton
            dense
            onClick={() => {
              action.action(row);
              handleClose(index);
            }}
          >
            {action.label}
          </ListItemButton>
        </ListItem>
      );
    });
  };

  const getAcctions = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    index: number
  ) => {
    const newAnchor: any = [];
    const newIds: any = [];
    const newOpenOptions: any = [];
    const items = indexTable;
    items.forEach((itemIndex: any) => {
      newAnchor.push(itemIndex === index ? event?.currentTarget : null);
      newOpenOptions.push(Boolean(newAnchor[itemIndex]));
      newIds.push(openOptions[itemIndex] ? 'simple-popover' : undefined);
    });
    setAnchorEl(newAnchor);
    setIds(newIds);
    setOpenOptions(newOpenOptions);
  };
  const updateAcctions = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    index: number
  ) => {
    if (!anchorEl.length) {
      getAcctions(event, index);
    } else {
      const newAnchor: any = anchorEl;
      const newIds: any = ids;
      const newOpenOptions: any = openOptions;
      newAnchor[index] = event ? event.currentTarget : null;
      newOpenOptions[index] = Boolean(newAnchor[index]);
      newIds[index] = openOptions[index] ? 'simple-popover' : undefined;
      setAnchorEl([]);
      setIds([]);
      setOpenOptions([]);
    }
  };

  return (
    <div>
      {populateActions()}
      {populateActionList()}
    </div>
  );
};

export default Actions;
