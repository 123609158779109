import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    filterContainer: {
      display: 'flex',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      padding: '8px',
      marginBottom: '20px',
      background: 'rgba(0, 128, 185, 0.05)',
    },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tableContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 40px)',
      },
    },
  };
});
