import IframeResizer from 'iframe-resizer-react';
import { RiFilterOffFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useReportsList } from '../../../hooks/useReportsList';
import { ClientInterface } from '../../../interfaces/core';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import {
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';

const BusinessDashboard = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const location = useLocation().search;
  const {
    allClients,
    handleChangeClient,
    clientSelected,
    handleClean,
    idClientSelected,
  } = useReportsList({ location });

  const renderIframe = (client: ClientInterface) => {
    if (client?.link) {
      return (
        <IframeResizer
          log
          src={client.link}
          className={classes.iFrameResizer}
        />
      );
    } else {
      return (
        <Grid className={classes.nonData}>
          <SsidChartIcon className={classes.nonDataIcon} />
        </Grid>
      );
    }
  };

  return (
    <Grid>
      <Grid className={classes.filterContainer}>
        <Grid item xs={12} md={8} lg={6} className={classes.selectContainer}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="client-select-label">
              {ready ? t('reports.dashboard') : 'Dashboard'}
            </InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={idClientSelected}
              label={ready ? t('reports.dashboard') : 'Dashboard'}
              onChange={handleChangeClient}
            >
              {allClients.map((type: any) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip
            title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
          >
            <Button
              variant="contained"
              sx={{ height: 56, m: 1 }}
              onClick={handleClean}
            >
              <RiFilterOffFill size={26} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <Box>{renderIframe(allClients[clientSelected])}</Box>
    </Grid>
  );
};

export default BusinessDashboard;
