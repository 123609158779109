import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { useDashboard } from '../../../../hooks/useDashboard';
import { FilterProps } from '../../../../interfaces/filter';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import PrintIcon from '@mui/icons-material/Print';
import { changeCurrentPage } from '../../../../redux/table';
import { setMapZoom } from '../../../../redux/map';
import { useAppDispatch } from '../../../../redux';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';

const DesktopFilter = ({
  crmGroups,
  customers,
  installations,
  addresses,
  location,
  solutions,
}: FilterProps): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleClean,
    searchCrmGroup,
    searchCustomer,
    searchInstall,
    setSearchCrmGroup,
    setSearchCustomer,
    setSearchInstall,
    searchAddress,
    setSearchAddress,
    setTypeSelected,
    typeSelected,
  } = useDashboard({ location });

  const clearFilters = () => {
    handleClean();
    dispatch(setMapZoom(6));
    dispatch(changeCurrentPage(0));
  };

  return (
    <Grid className={classes.root}>
      <Grid item xs={12} md={10}>
        <Grid className={classes.autoCompleteGroup}>
          <Grid className={classes.autoCompleteContainer}>
            <Autocomplete
              fullWidth
              clearText={t('common.clear')}
              openText={t('common.open')}
              closeText={t('common.close')}
              disablePortal
              id="combo-box-crm-group"
              data-testid="crm-group-autocomplete"
              options={crmGroups}
              value={searchCrmGroup}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
              onChange={(_event: any, newValue: string | null) =>
                setSearchCrmGroup(newValue ? `${newValue}` : '')
              }
              getOptionLabel={(option) => option || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ready ? t('dashboard.crmGroups') : 'Grupos'}
                />
              )}
            />
          </Grid>
          <Grid className={classes.autoCompleteContainer}>
            <Autocomplete
              fullWidth
              clearText={t('common.clear')}
              openText={t('common.open')}
              closeText={t('common.close')}
              disablePortal
              id="combo-box-demo"
              data-testid="customer-autocomplete"
              options={customers}
              value={searchCustomer}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
              onChange={(_event, newValue: string | null) => {
                setSearchCustomer(newValue ? `${newValue}` : '');
              }}
              getOptionLabel={(option) => option || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ready ? t('dashboard.customer') : 'Cliente'}
                />
              )}
            />
          </Grid>
          <Grid className={classes.autoCompleteContainer}>
            <Autocomplete
              fullWidth
              clearText={t('common.clear')}
              openText={t('common.open')}
              closeText={t('common.close')}
              disablePortal
              id="combo-box-demo-1"
              data-testid="installation-autocomplete"
              options={installations}
              value={searchInstall}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
              onChange={(_event, newValue: string | null) => {
                setSearchInstall(newValue ? `${newValue}` : '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ready ? t('dashboard.installation') : 'Instalación'}
                />
              )}
            />
          </Grid>
          <Grid className={classes.autoCompleteContainer}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {ready ? t('dashboard.solution') : 'Solución'}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                data-testid="solution-select"
                placeholder="Alcampo Kiosk"
                value={typeSelected}
                label={ready ? t('dashboard.solution') : 'Solución'}
                onChange={(event: SelectChangeEvent) => {
                  setTypeSelected(event.target.value);
                }}
              >
                {[
                  {
                    id: 0,
                    name: ready
                      ? t('support.all-solutions')
                      : 'Todas las Soluciones',
                  },
                ]
                  .concat(solutions)
                  .map((type: any) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid className={classes.autoCompleteContainer}>
            <Autocomplete
              fullWidth
              clearText={t('common.clear')}
              openText={t('common.open')}
              closeText={t('common.close')}
              disablePortal
              id="combo-box-demo-1"
              data-testid="address-autocomplete"
              options={addresses}
              value={searchAddress}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
              onChange={(_event, newValue: string | null) => {
                setSearchAddress(newValue ? `${newValue}` : '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={ready ? t('dashboard.town') : 'Ciudad'}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2} className={classes.buttonsContainer}>
        <Tooltip
          title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
        >
          <Button
            variant="contained"
            disableElevation
            className={classes.clearButton}
            onClick={clearFilters}
          >
            <FilterAltOffIcon fontSize="large" />
          </Button>
        </Tooltip>
        <Tooltip title={String(ready ? t('common.print') : 'Imprimir')}>
          <Button
            variant="outlined"
            className={classes.downloadButton}
            onClick={() => window.print()}
          >
            <PrintIcon fontSize="large" />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default DesktopFilter;
