import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => {
  return {
    cellTittle: {
      minWidth: '80%',
      fontWeight: '500',
    },
    tableCell: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
  };
});
