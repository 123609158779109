import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import useFooterData from './useFooterData';
import CustomDivider from './CustomDivider';
import HmyLogoWhite from '../../assets/png/HMY-logo-white.png';

import { Grid, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer = (): JSX.Element => {
  const classes = useStyles();
  const { SocialMedia, PhoneNumber } = useFooterData();
  const { t, ready } = useTranslation();
  return (
    <Grid className={classes.root}>
      <Grid container item xs={12} sm={4}>
        <Grid className={classes.imgContainer}>
          <img src={HmyLogoWhite} alt="hmy logo" className={classes.hmyLogo} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={8} className={classes.rightSide}>
        <Grid className={classes.contactContainer}>
          <Grid>
            <Typography
              className={classes.footerText}
              data-testid="social-media"
            >
              {ready ? t('home.ourSocialMedia') : 'Nuestras Redes'}
            </Typography>
            <Grid className={classes.contactLogosContainer}>
              {SocialMedia.map((item) => {
                return (
                  <Grid
                    key={item.name}
                    className={classes.logoContainer}
                    data-testid="sm-logo-container"
                  >
                    <a href={item.url} rel="noreferrer" target="_blank">
                      <img
                        src={item.logo}
                        alt={item.name}
                        className={classes.socialMediaLogo}
                      />
                    </a>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <CustomDivider />
        </Grid>
        <Grid className={classes.phoneContactContainer}>
          <Grid>
            <Typography className={classes.telephonefooterText}>
              <PhoneIcon className={classes.phoneIcon} />
              {ready ? t('home.telephoneSupport') : 'Soporte Telefónico'}
            </Typography>
            <Grid>
              {PhoneNumber.map((item) => {
                return (
                  <Grid
                    key={item.phone}
                    className={classes.phoneContainer}
                    data-testid="phone-number-container"
                  >
                    <Typography className={classes.phoneText}>
                      {item.location + ' ' + item.phone}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <CustomDivider />
        </Grid>
        <Grid className={classes.versionContainer}>
          <Typography
            className={classes.versionText}
          >{`ver ${process.env.REACT_APP_VERSION}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
