import { ChangeEvent } from 'react';
import { InstallationDevice } from '../../../interfaces/core';
import { SupportDetailTableRows } from '../../../interfaces/support';
import { useAppDispatch } from '../../../redux';
import {
  changeChildrenCurrentPage,
  changeChildrenRowsPerPage,
} from '../../../redux/table';

export const useSupportDetailTable = (data: InstallationDevice[]) => {
  const dispatch = useAppDispatch();

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(changeChildrenCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(changeChildrenRowsPerPage(newRowsPerPage));
    dispatch(changeChildrenCurrentPage(0));
  };

  const devicesMap = () => {
    return data.map((device: InstallationDevice) => {
      return {
        device_key: device.key,
        device: `${device?.name} (${device?.key})`,
        deviceType: device?.deviceType?.name ? device.deviceType.name : '',
        famocAgent: device?.famocAgent,
        id: device?.id,
        link: device?.deviceType?.link,
        name: device?.name,
        nsignId: device?.nsignId,
        status: device?.status,
        teamviewerAgent: device?.teamviewerAgent,
      } as SupportDetailTableRows;
    });
  };

  return {
    rows: devicesMap(),
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
