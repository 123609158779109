import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startDump } from '../../services/data-manager';
import { TableDumpHistory } from '../TableDumpHistory';
import { FilteredLogs } from '../../interfaces/core';
import { getAllLatLng } from '../../services/google';
import SimpleModal from '../SimpleModal';
import socket from '../../utils/sockets';
import { useStyles } from './styles';

import { DatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import {
  Box,
  Grid,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grow,
} from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Typography from '@mui/material/Typography';
import SyncIcon from '@mui/icons-material/Sync';
import { compareDates } from '../../utils/helpers';
import { useSnackbar } from 'notistack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DumpPart, DumpResult, DUMP_ALL } from '../../interfaces/dump';
import DumpDetail from '../DumpDetail';

const FormDataDump = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dateNow = new Date();
  const dateFrom = dateNow.setMonth(dateNow.getMonth() - 3);
  const [desde, setDesde] = useState<Date>(new Date(dateFrom));
  const [hasta, setHasta] = useState<Date>(new Date());
  const [logs, setLogs] = useState<any[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<FilteredLogs[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [sModal, setSimpleModal] = useState<boolean>(false);
  const [dumpFinish, setDumpFinish] = useState<boolean>(false);
  const [dumpData, setDumpData] = useState<DumpResult>();
  const [openDump, setOpenDump] = useState<boolean>(false);
  const [affectedRows, setAffectedRows] = useState<number>(0);
  const [dumpPart, setDumpPart] = useState<DumpPart>(DUMP_ALL);
  const [addressesInserted, setAddressesInserted] = useState<string[]>([]);

  const handleDumpStart = async () => {
    setLoading(true);
    startDump(dumpPart);
  };

  const handleGoogleDump = async () => {
    setLoadingGoogle(true);
    const dataGeoPos = (await getAllLatLng()).data;
    if (dataGeoPos) {
      setLoadingGoogle(false);
      setSimpleModal(true);
      setAffectedRows(dataGeoPos.affectedRows);
      setAddressesInserted(dataGeoPos.addressesInserted);
    }
  };

  useEffect(() => {
    socket.emit('client:get-logs');
    socket.on('server:get-logs', (data: any) => {
      setLogs(data);
      setFilteredLogs(data);
    });
    socket.on('server:finish-dump', (data: DumpResult) => {
      setDumpFinish(true);
      setDumpData(data);
      setLoading(false);
    });
  }, []);

  const handleFilter = () => {
    const desdeT = desde;
    const hastaT = hasta;
    if (!compareDates(desdeT, hastaT)) {
      enqueueSnackbar(
        t('reports.invalid-range') || 'Rango de fechas no válido',
        { variant: 'error' }
      );
    }
    desdeT.setHours(0, 0, 0, 0);
    hastaT.setHours(23, 59, 59, 999);
    const filteredLog = logs.filter((l) => {
      const lStarted = new Date(l.started);
      lStarted.setHours(0, 0, 0, 0);
      if (!desde || !hasta) {
        return false;
      }
      return lStarted >= desdeT && lStarted <= hastaT;
    });
    setFilteredLogs(filteredLog);
  };

  const handleToggle = () => {
    setOpenDump(!openDump);
  };

  const handleChange = (item: string) => {
    setDumpPart({ ...dumpPart, [item]: !dumpPart[item as keyof DumpPart] });
  };

  return (
    <>
      {sModal && (
        <SimpleModal
          open={sModal}
          onClose={() => setSimpleModal(false)}
          sx={{ width: 0.4 }}
        >
          <Box className={classes.textModalContainer}>
            <PlaceOutlinedIcon className={classes.locationIcon} />
            <Typography
              variant="h6"
              color="initial"
              className={classes.modalText}
            >
              {ready
                ? t('maintenance.lat-lng-got')
                : 'Geoposiciones obtenidas/modificadas:'}{' '}
              {affectedRows}
            </Typography>
          </Box>
          {addressesInserted && (
            <Box className={classes.itemsModalContainer}>
              {addressesInserted.map((address: string, index: number) => {
                return (
                  <Typography
                    key={index}
                    variant="h6"
                    color="initial"
                    className={classes.modalItemText}
                  >
                    • {address}
                  </Typography>
                );
              })}
            </Box>
          )}
          <Box className={classes.modalBtnContainer}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setSimpleModal(false)}
            >
              {t('common.close')}
            </Button>
          </Box>
        </SimpleModal>
      )}
      {dumpFinish && (
        <DumpDetail
          dumpOpen={dumpFinish}
          setDumpOpen={setDumpFinish}
          dumpData={dumpData}
        />
      )}
      <Box className={classes.root}>
        <Grid className={classes.main}>
          <Grid item xs={12} lg={4} className={classes.barButton}>
            <Button
              size="small"
              aria-label="select merge strategy"
              aria-haspopup="menu"
              disableElevation
              onClick={handleToggle}
              variant="contained"
              startIcon={<BrowserUpdatedIcon />}
            >
              {ready ? t('maintenance.manual-dump') : 'Volcado manual'}
              {!openDump ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </Button>
            <LoadingButton
              loading={loadingGoogle}
              variant="contained"
              startIcon={<ShareLocationIcon />}
              disableElevation
              className={classes.googleDump}
              onClick={handleGoogleDump}
            >
              {ready ? t('maintenance.get-lat-lng') : 'Obtener geoposiciones'}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} lg={8} className={classes.datePickersContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box className={classes.pickerContainer}>
                <DatePicker
                  label={ready ? t('maintenance.date-from') : 'Fecha desde'}
                  value={desde}
                  onChange={(newValue) => {
                    setDesde(new Date(String(newValue)));
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  inputFormat="dd/MM/yyyy"
                />
              </Box>
              <Box className={classes.pickerContainer}>
                <DatePicker
                  label={ready ? t('maintenance.date-to') : 'Fecha hasta'}
                  value={hasta}
                  onChange={(newValue) => {
                    setHasta(new Date(String(newValue)));
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  inputFormat="dd/MM/yyyy"
                />
              </Box>
            </LocalizationProvider>
            <Button
              variant="outlined"
              className={classes.syncButton}
              onClick={handleFilter}
            >
              <SyncIcon />
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: openDump ? 'flex' : 'none',
            }}
            mt="1rem"
            xs={4}
            lg={12}
          >
            <Grow
              in={openDump}
              style={{ transformOrigin: '0 0 0' }}
              {...(openDump ? { timeout: 800 } : {})}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'row', flexFlow: 'auto' }}
              >
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  {Object.keys(dumpPart).map((part: string, index: number) => (
                    <FormControlLabel
                      key={index}
                      sx={{ minWidth: '13rem', marginRight: 0 }}
                      control={
                        <Checkbox
                          checked={dumpPart[part as keyof DumpPart]}
                          onChange={() => handleChange(part)}
                        />
                      }
                      label={
                        <Typography>
                          {t(`dataDump.checkDump.${part}`)}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  disableElevation
                  className={classes.loadingButton}
                  onClick={handleDumpStart}
                >
                  {ready ? t('maintenance.start-dump') : 'Iniciar volcado'}
                </LoadingButton>
              </Box>
            </Grow>
          </Grid>
        </Grid>
        <TableDumpHistory data={filteredLogs} />
      </Box>
    </>
  );
};

export default FormDataDump;
