import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Grid, Divider, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import {
  InstallationCoordinates,
  InstallationMarkerData,
} from '../../../interfaces/installations';
import { getInstallationMarker } from '../../../services/installation';

const CustomMarker = ({
  locationInfo,
}: {
  locationInfo: InstallationCoordinates;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
  const [markerData, setMarkerData] = useState<InstallationMarkerData>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleMouseOver = async () => {
    setShowInfoWindow(true);
    try {
      setLoading(true);
      const { data } = await getInstallationMarker(locationInfo.installationId);
      setMarkerData(data.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseExit = () => {
    setShowInfoWindow(false);
  };

  return (
    <Marker
      position={{
        lat: parseFloat(locationInfo.latitude),
        lng: parseFloat(locationInfo.longitude),
      }}
      onMouseUp={handleMouseOver}
      onMouseDown={handleMouseExit}
    >
      {!loading && showInfoWindow && (
        <InfoWindow>
          <Grid>
            <Typography variant="subtitle2">
              {t('maps.crmGroups')}: {markerData?.crmGroupName}
            </Typography>
            <Divider />
            <Typography variant="subtitle2">
              {t('maps.client')}: {markerData?.customerName}
            </Typography>
            <Divider />
            <Typography variant="subtitle2">
              {t('maps.city')}:{' '}
              {markerData?.town ? markerData?.town : t('maps.no-data')}
            </Typography>
            <Divider />
            <Typography variant="subtitle2">
              {t('maps.cp')}:{' '}
              {markerData?.postalCode
                ? markerData?.postalCode
                : t('maps.no-data')}
            </Typography>
            <Divider />
            <Typography variant="subtitle2">
              {t('maps.address')}:{' '}
              {markerData?.address ? markerData?.address : t('maps.no-data')}
            </Typography>
            <Divider />
            <Typography variant="subtitle2">
              {t('maps.deviceQuantity')}:
              {!markerData?.devices.length ? ' ' + 0 : null}
            </Typography>
            {markerData?.devices.map((solution, index) => {
              return (
                <Grid key={index} className={classes.devicecontainer}>
                  <CircleIcon className={classes.icon} />
                  <Typography variant="subtitle2">
                    {solution.id}: {solution.value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default CustomMarker;
