import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '2rem',
  },
});
