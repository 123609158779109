import { useTranslation } from 'react-i18next';

export const useFormat = () => {
  const { t } = useTranslation();
  const textFormat: any = {
    ['Active']: t('common.active'),
    ['SPAIN']: t('countries.spain'),
    ['Andorra']: t('countries.andorra'),
    ['France']: t('countries.france'),
    ['GERMANY']: t('countries.germany'),
    ['España']: t('countries.spain'),
    ['Italy']: t('countries.italy'),
    ['Netherlands']: t('countries.netherlands'),
    ['Portugal']: t('countries.portugal'),
    ['United Kingdom']: t('countries.united-kingdom'),
  };
  return { textFormat };
};
