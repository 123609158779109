import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { metricNames, getFirstDayOfLastYearGlobal } from '../utils/helpers';
import { getLockerMetrics } from '../services/report';
import { LockerMetric, ListElement, ChartData } from '../interfaces/report';
import { useAppSelector } from '../redux';
import { selectUser } from '../redux/user';

interface DataFilter {
  customer: string;
  deviceName: string;
}

type Order = 'asc' | 'desc';

export const useLockerMetricsReport = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const dateNow = DateTime.now().toJSDate();
  const initialDate = getFirstDayOfLastYearGlobal().toISOString().split('T')[0];
  const untilDate = dateNow.toISOString().split('T')[0];
  const [isLoading, setIsLoading] = useState(false);
  const [clear, setClear] = useState(1);
  const [dateFrom, setDateFrom] = useState(initialDate);
  const [dateTo, setDateTo] = useState(untilDate);
  const [metricData, setMetricData] = useState<LockerMetric[]>([]);
  const [customerList, setCustomerList] = useState<ListElement[]>([]);
  const [chrtRecurrent, setChrtRecurrent] = useState<ChartData[]>([]);
  const [hasCustomers, setHasCustomers] = useState<boolean>(false);
  const [chrtLastConnection, setChrtLastConnection] = useState<ChartData[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [metricTitle, setMetricTitle] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('customer');
  const [dataFilter, setDataFilter] = useState<DataFilter>({
    customer: '',
    deviceName: '',
  });

  const headCells = [
    {
      id: 'customer',
      label: t('reports.metrics-report.customer'),
    },
    {
      id: 'locker_id',
      label: 'Locker ID',
    },
    {
      id: 'busines_type',
      label: 'Busines Type',
    },
    {
      id: 'timestamp',
      label: 'Timestamp',
    },
    {
      id: 'users_new',
      label: 'Users_new',
    },
    {
      id: 'user_recu',
      label: 'User_recu',
    },
    {
      id: 'uses_total',
      label: 'Uses_total',
    },
    {
      id: 'user_time_avg_sec',
      label: 'User_time_avg_sec',
    },
    {
      id: 'occupation_avg_per',
      label: 'Occupation_avg_per',
    },
    {
      id: 'slots_busy',
      label: 'Slots_busy',
    },
    {
      id: 'slots_tot',
      label: 'Slots_tot',
    },
    {
      id: 'pickups_tot',
      label: 'Pickups_tot',
    },
    {
      id: 'pickups_cart',
      label: 'Pickups_cart',
    },
    {
      id: 'pickups_scooter',
      label: 'Pickups_scooter',
    },
    {
      id: 'pickups_bike',
      label: 'Pickups_bike',
    },
    {
      id: 'dropoffs_tot',
      label: 'Dropoffs_tot',
    },
    {
      id: 'dropoffs_cart',
      label: 'Dropoffs_cart',
    },
    {
      id: 'dropoffs_scooter',
      label: 'Dropoffs_scooter',
    },
    {
      id: 'dropoffs_bike',
      label: 'Dropoffs_bike',
    },
    {
      id: 'expired_orders',
      label: 'Expired_orders',
    },
    {
      id: 'curr_expired',
      label: 'Curr_expired',
    },
    {
      id: 'staff_retire',
      label: 'Staff_retire',
    },
  ];

  const metricsReportHeads = [
    {
      label: t('reports.metrics-report.customer'),
      key: 'customer',
    },
    {
      label: 'Locker ID',
      key: 'locker_id',
    },
    {
      label: 'Busines Type',
      key: 'busines_type',
    },
    {
      label: 'Timestamp',
      key: 'timestamp',
    },
    {
      label: 'Users_new',
      key: 'users_new',
    },
    {
      label: 'User_recu',
      key: 'user_recu',
    },
    {
      label: 'Uses_total',
      key: 'uses_total',
    },
    {
      label: 'User_time_avg_sec',
      key: 'user_time_avg_sec',
    },
    {
      label: 'Occupation_avg_per',
      key: 'occupation_avg_per',
    },
    {
      label: 'Slots_busy',
      key: 'slots_busy',
    },
    {
      label: 'Slots_tot',
      key: 'slots_tot',
    },
    {
      label: 'Pickups_tot',
      key: 'pickups_tot',
    },
    {
      label: 'Pickups_cart',
      key: 'pickups_cart',
    },
    {
      label: 'Pickups_scooter',
      key: 'pickups_scooter',
    },
    {
      label: 'Pickups_bike',
      key: 'pickups_bike',
    },
    {
      label: 'Dropoffs_tot',
      key: 'dropoffs_tot',
    },
    {
      label: 'Dropoffs_cart',
      key: 'dropoffs_cart',
    },
    {
      label: 'Dropoffs_scooter',
      key: 'dropoffs_scooter',
    },
    {
      label: 'Dropoffs_bike',
      key: 'dropoffs_bike',
    },
    {
      label: 'Expired_orders',
      key: 'expired_orders',
    },
    {
      label: 'Curr_expired',
      key: 'curr_expired',
    },
    {
      label: 'Staff_retire',
      key: 'staff_retire',
    },
  ];

  const init = async () => {
    try {
      setIsLoading(true);
      const { data } = await getLockerMetrics({
        rowsPerPage: rowsPerPage,
        pageNumber: page,
        dateFrom: dateFrom,
        dateTo: dateTo,
        customer: dataFilter.customer || null,
        userName: user.username,
        order,
        orderBy,
      }).finally(() => setIsLoading(false));
      //TODO: cambiar chartLastConection por chartRetire en el proximo deploy del coreapi
      const {
        name,
        total,
        metrics,
        customer,
        chartLastConnection,
        chartRecurrent,
        hasCustomers,
      } = data;
      setMetricData(
        metrics.map((item) => ({
          ...item,
          timestamp: DateTime.fromISO(
            item.timestamp || dateNow.toDateString()
          ).toFormat('dd-MM-yyyy HH:mm'),
        }))
      );
      setCustomerList(customer);
      setTotalRows(parseInt(total.totalRows));
      setMetricTitle(metricNames[name] || name);
      setChrtRecurrent(chartRecurrent);
      setHasCustomers(hasCustomers);
      setChrtLastConnection(chartLastConnection);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    init();
  }, [dataFilter, dateFrom, dateTo, page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    setPage(0);
  }, [dataFilter, dateFrom, dateTo, rowsPerPage, order, orderBy]);

  const handleAutocomplete = (key: string, newValue: any) => {
    if (newValue) {
      setDataFilter({ ...dataFilter, [key]: newValue.label });
    } else {
      setDataFilter({ ...dataFilter, [key]: '' });
    }
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCsvData = (metricParam?: LockerMetric[]) => {
    const data = metricParam ? metricParam : metricData;
    return data.map((metric) => ({
      customer: metric.customer,
      locker_id: metric.locker_id,
      busines_type: metric.busines_type,
      timestamp: DateTime.fromISO(
        metric.timestamp || dateNow.toDateString()
      ).toFormat('dd-MM-yyyy'),
      users_new: metric.users_new,
      user_recu: metric.user_recu,
      uses_total: metric.uses_total,
      user_time_avg_sec: metric.user_time_avg_sec,
      occupation_avg_per: metric.occupation_avg_per,
      slots_busy: metric.slots_busy,
      slots_tot: metric.slots_tot,
      pickups_tot: metric.pickups_tot,
      pickups_cart: metric.pickups_cart,
      pickups_scooter: metric.pickups_scooter,
      pickups_bike: metric.pickups_bike,
      dropoffs_tot: metric.dropoffs_tot,
      dropoffs_cart: metric.dropoffs_cart,
      dropoffs_scooter: metric.dropoffs_scooter,
      dropoffs_bike: metric.dropoffs_bike,
      expired_orders: metric.expired_orders,
      curr_expired: metric.curr_expired,
      staff_retire: metric.staff_retire,
    }));
  };

  const handleClean = () => {
    setDataFilter({ customer: '', deviceName: '' });
    setClear(Math.random());
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return {
    chrtLastConnection,
    chrtRecurrent,
    clear,
    createSortHandler,
    csvData: getCsvData(),
    customerList,
    hasCustomers,
    handleAutocomplete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClean,
    headCells,
    isLoading,
    metricData,
    metricsReportHeads,
    metricTitle,
    order,
    orderBy,
    page,
    rowsPerPage,
    setDateFrom,
    setDateTo,
    totalRows,
  };
};
