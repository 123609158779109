import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  generateInform,
  downloadTxtFile,
  statusFormat,
} from '../../utils/logs';
import { Box, Typography, Button, Grid, Divider } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { getLogById } from '../../services/support';
import { LogInterface } from '../../interfaces/core';
import { AxiosResponse } from 'axios';
import { useStyles } from './styles';
import { DateTime } from 'luxon';

const LogDetail = (): JSX.Element => {
  const { logId } = useParams<{ logId: string }>();
  const [log, setLog] = useState<LogInterface>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const getLog = useCallback(async () => {
    setLoading(true);
    const resp: AxiosResponse = await getLogById(logId);
    const data = await resp.data;
    setLog(data);
    setLoading(false);
  }, [logId]);

  useEffect(() => {
    getLog();
  }, [getLog]);

  const downloadLog = () => {
    const txt = generateInform(log, t);
    const filename = `Log_${log?.id}_${Date.now()}`;
    downloadTxtFile(txt, filename);
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.rowContainer}>
        <Button
          variant="outlined"
          disableElevation
          className={classes.styledButton}
          onClick={() =>
            history.push('/maintainer', { from: history.location.pathname })
          }
          startIcon={<ArrowBackIcon />}
        >
          {t('common.go-back')}
        </Button>
        <Button
          variant="contained"
          disableElevation
          className={classes.styledButton}
          onClick={() => downloadLog()}
          endIcon={<DownloadIcon />}
        >
          {t('log.download')}
        </Button>
      </Grid>
      <Grid className={classes.rowContainer}>
        <Typography className={classes.idText}>
          <SummarizeIcon sx={{ mt: 0.4 }} />
          {`${t('log.id')}: `}
          <span
            style={{ fontWeight: 'bold', marginLeft: 5 }}
          >{`${log?.id}`}</span>
        </Typography>

        <Typography className={classes.standardText}>
          {`${t('log.type')}: `}
          <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{`${
            log?.isAutomatic ? t('log.automatic') : t('log.manual')
          }`}</span>
        </Typography>
        {!log?.isAutomatic && (
          <Typography className={classes.standardText}>
            {`${t('log.user')}: `}
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{`${
              log?.user?.username || ''
            } ${log?.user?.lastname || ''}`}</span>
          </Typography>
        )}
      </Grid>
      <Grid className={classes.rowContainer}>
        <Typography className={classes.standardText}>
          {`${t('log.datetime')}: `}
          <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
            {(log?.started &&
              DateTime.fromJSDate(new Date(log?.started)).toFormat(
                'dd/MM/y HH:mm:ss'
              )) ||
              ''}
          </span>
        </Typography>
        <Typography className={classes.standardText}>
          {`${t('log.finished')}: `}
          <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
            {(log?.finished &&
              DateTime.fromJSDate(new Date(log?.finished)).toFormat(
                'dd/MM/y HH:mm:ss'
              )) ||
              ''}
          </span>
        </Typography>

        <Typography className={classes.standardText}>
          {`${t('log.status')}: `}
          <span className={classes.statusText}>{`${
            statusFormat(t)[log?.status ?? ''] || log?.status
          }`}</span>
        </Typography>
      </Grid>
      <Divider />
      <Typography variant="h5" className={classes.logDetail}>
        {t('log.detail')}
      </Typography>
      <Grid className={classes.test}>
        <Grid className={classes.detailContainer}>
          <pre style={{ fontFamily: 'RoobertRegular' }}>
            {JSON.stringify(log?.modifications, null, 2)}
          </pre>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogDetail;
