import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InstallationData } from '../../../interfaces/installations';
import { useAppDispatch } from '../../../redux';
import {
  changeRowsPerPage,
  changeCurrentPage,
  rowsPerPageValue,
  currentPageValue,
} from '../../../redux/table';

const useInstallationsColumn = () => {
  const { t, ready } = useTranslation();
  return [
    {
      id: 'crmGroup',
      label: ready ? t('table.installation.crmGroup') : 'Grupo CRM',
    },
    {
      id: 'client',
      label: ready ? t('table.installation.client') : 'Cliente',
    },
    {
      id: 'installation',
      label: ready ? t('table.installation.installation') : 'Instalación',
    },
    {
      id: 'status',
      label: ready ? t('table.installation.status') : 'Estado',
    },
    {
      id: 'installation_key',
      label: ready
        ? t('table.installation.installation_key')
        : 'Estado de dispositivos',
    },
  ];
};

export const useInstallationsTable = (data: InstallationData[]): any => {
  const [dense, setDense] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const rowsPerPage = useSelector(rowsPerPageValue);
  const page = useSelector(currentPageValue);

  useEffect(() => {
    const installations = data.map((r: InstallationData) => {
      let solutions: any = 'No hay soluciones';
      if (r?.devices?.length > 0) {
        const deviceType = r?.devices?.map((device: any) => ({
          id: r?.id,
          typeId: device.deviceType.id,
          name: device.deviceType.name,
        }));

        const deviceFinal = deviceType.reduce((prev: any, curr: any) => {
          prev[curr.typeId] = prev[curr.typeId]
            ? { ...curr, count: prev[curr.typeId].count + 1 }
            : { ...curr, count: 1 };

          return prev;
        }, {});

        solutions = JSON.stringify(Object.values(deviceFinal));
      }

      const retorn: any = {
        crmGroup: r?.customer?.crmGroup
          ? `${r.customer.crmGroup.name} (${r.customer.crmGroup.key})`
          : '',
        client: `${r?.customer?.name} (${r?.customer?.key})`,
        installation: `${r?.name} (${r?.key})`,
        status: `${r?.status}`,
        dateCreated: `${moment(r?.created).format('DD/MM/yyyy k:mm')}`,
        dateUpdated: `${moment(r?.updated).format('DD/MM/yyyy k:mm')}`,
        solutions: `${solutions}`,
        id: `${r?.id}`,
        installation_key: String(r?.key),
      };

      return retorn;
    });

    setRows(installations);
  }, [data]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(changeCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(changeRowsPerPage(newRowsPerPage));
    dispatch(changeCurrentPage(0));
  };

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  return {
    dense,
    rows,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeDense,
    installationsColumn: useInstallationsColumn(),
  };
};
