import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sortBy, toString } from 'lodash';
import Query from 'query-string';
import {
  installationsCoordinates,
  paginatedDashboardData,
} from '../services/installation';
import { getGroupedDevices } from '../services/devices';
import { QueryKey } from '../interfaces/platform';
import { useAppDispatch } from '../redux';
import {
  setAddreses,
  setCoordinatesLoading,
  setCrmGroups,
  setCustomers,
  setDevicesCountLoading,
  setGroupedSolutions,
  setInstallations,
  setInstallationsCoordinates,
  setInstallationsLoading,
  setKpis,
  setPaginatedInstallations,
  setSolutions,
  setTotalRows,
} from '../redux/installations';
import { useSelector } from 'react-redux';
import {
  changeCurrentPage,
  currentPageValue,
  orderByDashboardTable,
  orderDashboardTable,
  rowsPerPageValue,
} from '../redux/table';

export const useDashboard = ({ location }: { location: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [searchInstall, setSearchInstall] = useState<string>('');
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [searchCrmGroup, setSearchCrmGroup] = useState<string>('');
  const [typeSelected, setTypeSelected] = useState<string>('');
  const rowsPerPage = useSelector(rowsPerPageValue);
  const page = useSelector(currentPageValue);
  const order = useSelector(orderDashboardTable);
  const orderBy = useSelector(orderByDashboardTable);

  //Obtiene datos de filtros, kpis e instalaciones paginadas
  const getDashboardData = async () => {
    try {
      dispatch(setInstallationsLoading(true));
      const query = Query.parse(location);
      const { data } = await paginatedDashboardData({
        group: toString(query.g),
        customer: toString(query.c),
        installation: toString(query.i),
        solution: parseInt(toString(query.s)),
        town: toString(query.a),
        rowsPerPage: rowsPerPage,
        pageNumber: page,
        order,
        orderBy,
      });

      const {
        crmGroups,
        customers,
        data: paginatedData,
        installations,
        addresses,
        kpis,
        solutions,
        totalRows,
      } = data;
      dispatch(setCrmGroups(sortBy(crmGroups)));
      dispatch(setCustomers(sortBy(customers)));
      dispatch(setPaginatedInstallations(paginatedData));
      dispatch(setInstallations(sortBy(installations)));
      dispatch(setAddreses(sortBy(addresses)));
      dispatch(setKpis(kpis));
      dispatch(setSolutions(sortBy(solutions, 'name')));
      dispatch(setTotalRows(totalRows));
      dispatch(setInstallationsLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

  //Obtiene los datos del grafico
  const getDevicesCount = async () => {
    try {
      dispatch(setDevicesCountLoading(true));
      const query = Query.parse(location);
      const { data } = await getGroupedDevices({
        crmGroup: toString(query.g),
        searchCustomer: toString(query.c),
        searchInstall: toString(query.i),
        searchTown: toString(query.a),
        typeSelected: parseInt(toString(query.s)),
      });
      dispatch(setGroupedSolutions(data));
      dispatch(setDevicesCountLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

  //Obtiene las coordenadas de las instalaciones para el mapa
  const getInstallationsCoordinates = async () => {
    try {
      dispatch(setCoordinatesLoading(true));
      const query = Query.parse(location);
      const { data } = await installationsCoordinates({
        crmGroup: toString(query.g),
        customer: toString(query.c),
        installation: toString(query.i),
        town: toString(query.a),
        solutionType: parseInt(toString(query.s) || '0'),
      });
      dispatch(setInstallationsCoordinates(data.data));
      dispatch(setCoordinatesLoading(false));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDashboardData();
    getDevicesCount();
    getInstallationsCoordinates();
    dispatch(changeCurrentPage(0));
  }, [location]);

  useEffect(() => {
    getDashboardData();
  }, [rowsPerPage, page, order, orderBy]);

  useEffect(() => {
    initLocationStates();
  }, []);

  const initLocationStates = () => {
    const query = Query.parse(location);
    setSearchCrmGroup(toString(query.g));
    setSearchCustomer(toString(query.c));
    setSearchInstall(toString(query.i));
    setSearchAddress(toString(query.a));
    setTypeSelected(toString(query.s || '0'));
  };

  const handleClean = () => {
    setSearchCrmGroup('');
    setSearchCustomer('');
    setSearchInstall('');
    setSearchAddress('');
    setTypeSelected('');
    history.push('/');
  };

  const makeHandler = (
    key: QueryKey,
    setter: React.Dispatch<React.SetStateAction<any>>
  ) => {
    return function (a?: any) {
      setSearchHistory(key, a);
      setter(a);
    };
  };

  const setSearchHistory = useCallback(
    (key: QueryKey, value: any) => {
      const obj = Query.parse(location);
      obj[key] = value;
      const q = Query.stringify(obj);
      history.push(`?${q}`);
    },
    [location]
  );

  return {
    handleClean,
    searchCrmGroup,
    searchCustomer,
    searchInstall,
    searchAddress,
    setSearchCrmGroup: makeHandler('g', setSearchCrmGroup),
    setSearchCustomer: makeHandler('c', setSearchCustomer),
    setSearchInstall: makeHandler('i', setSearchInstall),
    setSearchAddress: makeHandler('a', setSearchAddress),
    setTypeSelected: makeHandler('s', setTypeSelected),
    typeSelected,
  };
};
