import { forEach, startCase, kebabCase } from 'lodash';
import { DateTime } from 'luxon';
import validUrl from 'valid-url';

export interface DestructuresObject {
  object: { [key: string]: string | number };
  keys: string[];
  values: (string | number)[];
}

interface DesructureObjectProps {
  obj: object;
  opts?: any;
}

export const destructureObject = (
  props: DesructureObjectProps
): DestructuresObject => {
  const { obj, opts } = props;
  const R: DestructuresObject = {
    object: {},
    keys: [],
    values: [],
  };

  forEach(obj, (value, key) => {
    const k: string = opts?.t ? opts.t(key) : startCase(kebabCase(key));
    R.keys.push(k);
    R.object[k] = value;
    R.values.push(value);
  });

  return R;
};

export const validURL = (str: string): boolean => {
  return !!validUrl.isWebUri(String(validUrl.isHttpsUri(str)));
};

export const compareDatesString = (dateFrom: string, dateTo: string) => {
  if (!dateFrom || !dateTo) throw new Error('Dates are required');
  return (
    new Date(dateFrom).setHours(0, 0, 0, 0) <=
    new Date(dateTo).setHours(0, 0, 0, 0)
  );
};

export const compareDates = (dateFrom: Date, dateTo: Date) => {
  if (!dateFrom || !dateTo) throw new Error('Dates are required');
  return dateFrom.setHours(0, 0, 0, 0) <= dateTo.setHours(0, 0, 0, 0);
};

export const getFirstDayOfCurrentYearGlobal = () => {
  const currentDayNumber = new Date().getDate();
  const currentMonthNumber = new Date().getMonth() + 1;
  const firstDayOfMonth = DateTime.now().plus({
    days: -currentDayNumber + 1,
  });
  return firstDayOfMonth.plus({ months: -currentMonthNumber + 1 }).toJSDate();
};

const currentDayNumber = new Date().getDate();

const currentMonthNumber = new Date().getMonth() + 1;

const firstDayOfMonth = DateTime.now().plus({
  days: -currentDayNumber + 1,
});

export const getFirstDayOfLastYearGlobal = () => {
  return firstDayOfMonth
    .plus({ months: -currentMonthNumber + 1 })
    .plus({ years: -1 })
    .toJSDate();
};

export const metricNames: any = {
  ['nsign_player']: 'Nsign Player',
  ['locker']: 'Locker',
};

export type OrderTypes = 'ASC' | 'DESC' | undefined;

export const changeOrderDirection = (order: string | undefined): OrderTypes =>
  order === 'ASC' ? 'DESC' : 'ASC';
