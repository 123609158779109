import axios from 'axios';
import { REFRESH } from '../constants/routes';
import store from '../redux';
import { getUserInfo } from '../services/user';
import { logout, selectToken } from '../redux/user';

// Use this for private endpoints
const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_CORE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosPrivate.interceptors.request.use((config: any) => {
  const state = store.getState();
  const token = selectToken(state);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    store.dispatch(logout());
  }
  return config;
});

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error({ error });
    const prevRequest = error?.config;
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      try {
        prevRequest.sent = true;
        return await refreshTokenHandle(prevRequest);
      } catch (err: any) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

/* eslint-disable */
export const refreshTokenHandle = async (prevRequest: any): Promise<any> => {
  const { refreshToken, username } = getUserInfo();
  const refreshResponse = await axios.post(
    REFRESH,
    { username },
    { headers: { refresh: refreshToken } }
  );
  const newAuthData = refreshResponse.data;
  prevRequest.headers['Authorization'] = `Bearer ${newAuthData.token}`;
  return axiosPrivate(prevRequest);
};

export default axiosPrivate;
