import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from '../../pages/Error';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: { name: '', message: '' },
    errorInfo: { componentStack: '' },
  };

  public static getDerivedStateFromError(): Partial<State> {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ error: error, errorInfo: errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorPage error={this.state.error} errorInfo={this.state.errorInfo} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
