export const DASHBOARD = '/';
export const LOGIN = '/login';
export const REFRESH = '/refresh';
export const SOLUTION = '/solutions';
export const SOLUTION_DETAIL = '/solution-detail';
export const SUPPORT = '/support';
export const SUPPORT_DETAIL = '/support-detail';
export const DATA_MAINTAINER = '/maintainer';
export const REPORT = '/reports';
export const LOGOUT = '/logout';
export const DEVICE_DETAIL = '/device-detail';
export const USER_SETTINGS = '/user-settings';
export const NOT_FOUND = '*';
