import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    passwordContainer: {
      position: 'relative',
      background: 'linear-gradient(310.83deg, #FFFFFF 10.24%, #F4F4F4 89.03%)',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px',
      padding: '20px',
    },
    titleContainer: {
      marginBottom: '5px',
    },
    boldText: {
      fontWeight: '700 !important',
      fontSize: '18px !important',
      color: '#56565A',
    },
    passwordTitleContainer: {
      marginBottom: '20px',
    },
    stepsText: {
      color: '#56565A',
      fontSize: '13px !important',
    },
    passwordMain: {
      display: 'flex',
    },
    infoContainer: {
      [theme.breakpoints.down('xl')]: {
        paddingLeft: '10px',
      },
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    comparePassword: {
      color: '#ff3333',
      fontSize: '15px !important',
    },
    savePswContainer: {
      position: 'absolute',
      bottom: '10px',
      right: '50px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});
