import axiosPrivate from '../api/axios';
import axiosRetry from 'axios-retry';
import { Filter } from '../interfaces/filter';

axiosRetry(axiosPrivate, { retries: 3 });

//core-api conexion directa a la db
export const startAutomaticProcess = (): Promise<any> => {
  return axiosPrivate.get('/automatic-process');
};
//core-api conexion directa a la db
export const startAutomaticProvision = (): Promise<any> => {
  return axiosPrivate.get('/automatic-provision');
};

//core-api conexion directa a la db
export const getLogs = (): Promise<any> => {
  return axiosPrivate.get('/logs');
};
//core-api conexion directa a la db
export const getLogById = (id: string): Promise<any> => {
  return axiosPrivate.get(`/logs/${id}`);
};

//core-api customers conexion directa a la db
export const getActiveClients = (filter: Filter): Promise<any> => {
  const query = filter.searchCustomer ? `?name=${filter.searchCustomer}` : '';
  return axiosPrivate.get('/customers/active' + query);
};

//core-api conexion directa a la db
export const getCrmGroupDashboard = (): Promise<any> => {
  return axiosPrivate.get('/crmgroup/active');
};
//core-api inicializacion correcta implementacion
export const manualProvision = ({
  services,
}: {
  [key: string]: string[];
}): Promise<any> => {
  return axiosPrivate.get('/manual-provision', {
    params: { services: services.join() },
  });
};
//core-api inicializacion logs correcta implementacion
export const getServiceLogs = (): Promise<any> => {
  return axiosPrivate.get('/service-logs');
};
