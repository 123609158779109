import SaveIcon from '@mui/icons-material/Save';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomSnakbar from '../molecules/CustomSnakbar';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useStyles } from './styles';

const GenericFormSchedule = (props: any): JSX.Element => {
  const { startAutomaticService, saveTimeService } = props;
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [time, setTime] = useState<Date | null>(null);
  const [config, setConfig] = useState<any>({});
  const [prevDays, setPrevDays] = useState<any>([]);
  const form = useRef<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const { handleSubmit, register } = useForm<any>();

  const fetchConfig = useCallback(async () => {
    const response: AxiosResponse = await startAutomaticService();
    const data = await response.data;
    setConfig(data);
  }, []);

  useEffect(() => {
    setPrevDays(config?.days?.split(',').map((n: string) => Number(n)) || []);
    setTime(new Date(`01/12/2021 ${config?.time}`) || null);
  }, [config]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const handleCancel = () => {
    fetchConfig();
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSave = async (values: any) => {
    const arr = [];
    for (const k in values.days) {
      if (values.days[k]) {
        arr.push(Number(k));
      }
    }
    const daysSelected = arr.join(',');
    const timeSelected =
      time &&
      DateTime.fromJSDate(new Date(time.toString())).toFormat('HH:mm') !==
        t('provision.invalid-date')
        ? DateTime.fromJSDate(new Date(time.toString())).toFormat('HH:mm')
        : values.time;
    const response: any = await saveTimeService({
      days: daysSelected,
      time: timeSelected,
    });
    if (response?.data) {
      setOpen(true);
      fetchConfig();
    }
  };

  const Days = () => {
    const days = [
      { id: 1, name: ready ? t('provision.monday') : 'Lunes' },
      { id: 2, name: ready ? t('provision.tuesday') : 'Martes' },
      { id: 3, name: ready ? t('provision.wednesday') : 'Miércoles' },
      { id: 4, name: ready ? t('provision.thursday') : 'Jueves' },
      { id: 5, name: ready ? t('provision.friday') : 'Viernes' },
      { id: 6, name: ready ? t('provision.saturday') : 'Sábado' },
      { id: 7, name: ready ? t('provision.sunday') : 'Domingo' },
    ];
    return (
      <>
        {days.map(({ id, name }) => (
          <Grid key={id}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <RadioButtonUncheckedIcon
                      fontSize="small"
                      className={classes.checkIcon}
                    />
                  }
                  checkedIcon={
                    <CheckCircleIcon
                      fontSize="small"
                      className={classes.checkIcon}
                    />
                  }
                  defaultChecked={prevDays.includes(id)}
                />
              }
              label={
                <Typography className={classes.dayText}>{name}</Typography>
              }
              {...register(`days[${id}]`)}
            />
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Box
      className={classes.root}
      ref={form}
      component="form"
      onSubmit={handleSubmit(handleSave)}
    >
      <Grid item xs={12} className={classes.main}>
        <Grid item xs={12} lg={5} xl={6}>
          <Box className={classes.selectDayTitleContainer}>
            <Typography variant="h5" className={classes.text}>
              {ready ? t('provision.select-days') : 'Días de ejecución'}
            </Typography>
          </Box>
          <FormGroup className={classes.daysContainer}>
            <Days />
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={7} xl={6} className={classes.boxSection}>
          <Box className={classes.startTimePicker}>
            <Typography variant="h5" className={classes.text}>
              {ready
                ? t('provision.select-time')
                : 'Hora de inicio del proceso'}
            </Typography>
            <Box className={classes.styledClock}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label=""
                  value={time}
                  inputFormat="HH:mm"
                  mask="__:__"
                  ampm={true}
                  {...register('time')}
                  onChange={(newValue) => {
                    setTime(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Grid className={classes.infoTextContainer}>
            <Typography variant="h6" className={classes.text}>
              {ready ? t('provision.alert') : 'En caso de error informar a:'}
            </Typography>
            <Box>
              <Typography variant="h6" className={classes.mailText}>
                guillermo.alvarez@hmy-group.com
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.optionButtonsContainer}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              size="large"
              className={classes.button}
            >
              {ready ? t('provision.cancel') : 'Cancelar'}
            </Button>
            <Button
              variant="contained"
              disableElevation
              endIcon={<SaveIcon />}
              size="large"
              type="submit"
              className={classes.button}
            >
              {ready ? t('provision.save') : 'Guardar'}
            </Button>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <CustomSnakbar
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {ready
                ? t('provision.config-saved')
                : 'La configuración fue guardada!'}
            </CustomSnakbar>
          </Snackbar>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenericFormSchedule;
