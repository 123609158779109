import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstallationCoordinates } from '../../interfaces/installations';
import { DeviceGraph } from '../../interfaces/device';
import {
  SolutionData,
  KpisData,
  InstallationData,
} from '../../interfaces/installations';
import { RootState } from '../store';

export interface InstallationsData {
  crmGroups: string[];
  customers: string[];
  installations: string[];
  addresses: string[];
  solutions: SolutionData[];
  kpis: KpisData;
  groupedSolutions: DeviceGraph;
  installationsCoordinates: InstallationCoordinates[];
  paginatedInstallations: InstallationData[];
  totalRows: number;
  installationsLoading: boolean;
  devicesCountLoading: boolean;
  coordinatesLoading: boolean;
}

const initialState: InstallationsData = {
  crmGroups: [],
  customers: [],
  installations: [],
  addresses: [],
  solutions: [],
  kpis: {
    crmGroupsQuantity: 0,
    customerQuantity: 0,
    devicesQuantity: 0,
    installationsQuantity: 0,
    solutionTypeQuantity: 0,
    devicesConnected: 0,
  },
  groupedSolutions: { totTypes: 0, total: [] },
  installationsCoordinates: [],
  paginatedInstallations: [],
  totalRows: 0,
  installationsLoading: false,
  devicesCountLoading: false,
  coordinatesLoading: false,
};

export const installationsSlice = createSlice({
  name: 'installations',
  initialState,
  reducers: {
    setCrmGroups: (state, action: PayloadAction<string[]>) => {
      state.crmGroups = action.payload;
    },
    setCustomers: (state, action: PayloadAction<string[]>) => {
      state.customers = action.payload;
    },
    setInstallations: (state, action: PayloadAction<string[]>) => {
      state.installations = action.payload;
    },
    setAddreses: (state, action: PayloadAction<string[]>) => {
      state.addresses = action.payload;
    },
    setSolutions: (state, action: PayloadAction<SolutionData[]>) => {
      state.solutions = action.payload;
    },
    setKpis: (state, action: PayloadAction<KpisData>) => {
      state.kpis = action.payload;
    },
    setGroupedSolutions: (state, action: PayloadAction<DeviceGraph>) => {
      state.groupedSolutions = action.payload;
    },
    setInstallationsCoordinates: (
      state,
      action: PayloadAction<InstallationCoordinates[]>
    ) => {
      state.installationsCoordinates = action.payload;
    },
    setPaginatedInstallations: (
      state,
      action: PayloadAction<InstallationData[]>
    ) => {
      state.paginatedInstallations = action.payload;
    },
    setTotalRows: (state, action: PayloadAction<number>) => {
      state.totalRows = action.payload;
    },
    setInstallationsLoading: (state, action: PayloadAction<boolean>) => {
      state.installationsLoading = action.payload;
    },
    setDevicesCountLoading: (state, action: PayloadAction<boolean>) => {
      state.devicesCountLoading = action.payload;
    },
    setCoordinatesLoading: (state, action: PayloadAction<boolean>) => {
      state.coordinatesLoading = action.payload;
    },
    setClearInstallations: () => initialState,
  },
});

export const {
  setCrmGroups,
  setCustomers,
  setInstallations,
  setAddreses,
  setSolutions,
  setKpis,
  setGroupedSolutions,
  setInstallationsCoordinates,
  setPaginatedInstallations,
  setTotalRows,
  setInstallationsLoading,
  setDevicesCountLoading,
  setCoordinatesLoading,
  setClearInstallations,
} = installationsSlice.actions;

export const selectInstallationData = (state: RootState) => state.installations;

export default installationsSlice.reducer;
