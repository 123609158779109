import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  iFrameResizer: {
    width: '1px',
    minWidth: '100%',
    minHeight: '65vh',
    border: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  nonData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    minHeight: '55vh',
    background: 'rgba(0, 128, 185, 0.05)',
  },
  nonDataIcon: {
    color: '#9a9aa1',
    transform: 'scale(2.5)',
    borderLeft: '2px solid #9a9aa1',
    borderBottom: '2px solid #9a9aa1',
  },
  filterContainer: {
    display: 'flex',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '8px',
    marginBottom: '20px',
    background: 'rgba(0, 128, 185, 0.05)',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
