import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../../services/user-settings';
import { useStyles } from './styles';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { selectUser, setModifiedUser, setLanguage } from '../../../redux/user';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import avatar from '../../../assets/avatar.png';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

interface UserData {
  image: any;
  firstname: string;
  lastname: string;
  email: string;
  language: string;
}

const UserForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready, i18n } = useTranslation();
  const { user: userRedux } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line no-useless-escape
  const regex = /^\S+@\S+\.\S+\S$/;
  const nameRegex = /^(?!-)[a-zñáéíóúüA-ZÑÁÉÍÓÚÜ ]*[a-zñáéíóúüA-ZñÑÁÉÍÓÚÜ ]$/;
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    image: '',
    email: userRedux?.email,
    firstname: userRedux?.firstname,
    lastname: userRedux?.lastname,
    language: userRedux?.language || 'es',
  });
  // eslint-disable-next-line no-extra-boolean-cast
  const profileImage = !!userRedux.image
    ? `${userRedux.image}?v=${userRedux.updateSeed}`
    : avatar;

  const handleFileUpload = (event: any) => {
    const imageFormats = ['png', 'jpeg', 'jpg'];
    if (imageFormats.includes(event.target.files[0].name.split('.').pop())) {
      setUserData({ ...userData, image: event.target.files[0] });
    } else {
      enqueueSnackbar(t('user-settings.invalid-image'), {
        variant: 'error',
      });
    }
  };

  const handleDataChange =
    (prop: keyof UserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserData({ ...userData, [prop]: event.target.value });
    };

  const checkSaveData = () => {
    if (
      regex.test(userData.email) &&
      nameRegex.test(userData.firstname) &&
      nameRegex.test(userData.lastname)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleLangChange = (event: any) => {
    const lang = event.target.value;
    setUserData({ ...userData, language: lang });
  };

  const submitData = async () => {
    const formData = new FormData();
    formData.append('file', userData.image);
    formData.append('username', userRedux?.username);
    formData.append('firstname', userData.firstname);
    formData.append('lastname', userData.lastname);
    formData.append('email', userData.email);
    formData.append('language', userData.language);
    try {
      setIsLoading(true);
      const res = await updateUser(formData);
      setIsLoading(false);
      if (res.status === 200) {
        enqueueSnackbar(t('user-settings.data-success'), {
          variant: 'success',
        });
        dispatch(
          setModifiedUser({
            image: res.data.url ? res.data.url : userRedux.image,
            email: userData.email,
            firstname: userData.firstname,
            lastname: userData.lastname,
          })
        );
        dispatch(setLanguage({ language: userData.language }));
        i18n.changeLanguage(userData.language);
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(t('user-settings.save-data-err'), {
        variant: 'error',
      });
      console.error(err);
    }
  };

  return (
    <Grid item xs={4} xl={3} className={classes.settingsContainer}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.boldText}>
          {ready ? t('user-settings.profile') : 'Mi Perfil'}
        </Typography>
      </Box>
      <Box className={classes.imagesContainer}>
        <Box className={classes.blurImgContainer}>
          <img
            data-testid="blur-img"
            src={
              userData.image
                ? URL.createObjectURL(userData.image)
                : profileImage
            }
            alt="Profile image"
            className={classes.blurProfileImg}
          />
        </Box>
        <Box className={classes.profileImgContainer}>
          <img
            data-testid="profile-img"
            src={
              userData.image
                ? URL.createObjectURL(userData.image)
                : profileImage
            }
            alt="Profile image"
            className={classes.profileImg}
          />
        </Box>
        <label htmlFor="contained-button-file">
          <EditOutlinedIcon
            data-testid="edit-img-btn"
            className={classes.editImgBtn}
          />
        </label>
      </Box>
      <Box className={classes.dataContainer}>
        <Box className={classes.roleContainer}>
          <Typography data-testid="role-text" className={classes.roleText}>
            {userRedux?.role}
          </Typography>
        </Box>
        <Input
          type="file"
          onChange={(e) => handleFileUpload(e)}
          id="contained-button-file"
          style={{ display: 'none' }}
          inputProps={{ accept: 'image/*' }}
        />
        <Box className={classes.nameContainer}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="mail-input">
              {ready ? t('user-settings.mail') : 'Correo'}
            </InputLabel>
            <Input
              error={!regex.test(userData.email)}
              data-testid="mail-input"
              inputProps={{
                maxLength: 35,
              }}
              id="mail-input"
              value={userData.email}
              onChange={handleDataChange('email')}
            />
          </FormControl>
        </Box>
        <Box className={classes.nameContainer}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="name-input">
              {ready ? t('user-settings.name') : 'Nombre'}
            </InputLabel>
            <Input
              error={!nameRegex.test(userData.firstname)}
              data-testid="name-input"
              inputProps={{
                maxLength: 20,
              }}
              id="name-input"
              value={userData.firstname}
              onChange={handleDataChange('firstname')}
            />
          </FormControl>
        </Box>
        <Box className={classes.nameContainer}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="lastname-input">
              {ready ? t('user-settings.lastname') : 'Apellido'}
            </InputLabel>
            <Input
              error={!nameRegex.test(userData.lastname)}
              data-testid="lastname-input"
              inputProps={{
                maxLength: 20,
              }}
              id="lastname-input"
              value={userData.lastname}
              onChange={handleDataChange('lastname')}
            />
          </FormControl>
        </Box>
        <Box className={classes.nameContainer}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="languague-label">
              {ready ? t('languages.language') : 'Idioma'}
            </InputLabel>
            <Select
              id="languague-label"
              value={userData.language}
              onChange={handleLangChange}
              label="Idioma"
            >
              <MenuItem value="es">{t('languages.spanish')}</MenuItem>
              <MenuItem value="en">{t('languages.english')}</MenuItem>
              <MenuItem value="fr">{t('languages.french')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.saveDataBtn}>
        <Button
          startIcon={
            isLoading ? <CircularProgress size={20} /> : <SaveRoundedIcon />
          }
          data-testid="save-btn"
          disabled={checkSaveData()}
          onClick={submitData}
        >
          {ready ? t('user-settings.save-data') : 'Guardar Datos'}
        </Button>
      </Box>
    </Grid>
  );
};

export default UserForm;
