import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        marginTop: '35px',
      },
    },
    kpisContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
    },
    cardContainer: {
      width: '100%',
      padding: '5px',
      '@media print': {
        width: '50%',
        [theme.breakpoints.up('sm')]: {
          width: '33% !important',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '33%',
      },
    },
    infoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '20px 0px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 20px 0px',
      },
    },
    chartContainer: {
      width: '40%',
      height: '400px',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    icon: {
      color: '#04225F',
      marginRight: '5px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '10px',
      },
    },
    mapContainer: {
      position: 'relative',
      width: '60%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: '20px',
      },
    },
    progress: {
      position: 'absolute',
      top: '-1px',
      transform: 'scale(1, 1.05)',
      width: '100%',
      zIndex: '1',
    },
    tableContainer: {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 40px)',
      },
    },
  };
});
