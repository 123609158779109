import { Link } from 'react-router-dom';
import { SUPPORT_DETAIL } from '../../constants/routes';
import { HeadCell } from '../../interfaces/table';

export const headCells = (columns: any[]): HeadCell[] => {
  return columns.map((item: any) => {
    return {
      id: item.id,
      numeric: false,
      disablePadding: false,
      label: item.label,
    };
  });
};

interface MakeLinksProps {
  solutions: any[];
}

export const MakeLinks = (props: MakeLinksProps): JSX.Element => {
  const { solutions } = props;
  return (
    <>
      {solutions.map((solution: any, idx: number) => (
        <Link
          key={idx}
          to={`${SUPPORT_DETAIL}/${solution.id}?s=${solution.typeId}`}
          style={{ paddingRight: 10 }}
        >
          {`${solution.name}(${solution.count})`}
        </Link>
      ))}
    </>
  );
};
