import { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import LayoutSwitcher from '../layout/LayoutSwitcher';
import { PrivateRouteProps } from '../interfaces/core';
import { useAuth } from '../auth/useAuth';
import { useAppSelector } from '../redux';
import { selectToken } from '../redux/user';

export const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { isLogged, children, ...rest } = props;
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessByRol } = useAuth();
  const token = useAppSelector(selectToken);
  const path = useLocation().pathname;

  useEffect(() => {
    isLogged && !getAccessByRol(path) && token
      ? enqueueSnackbar(
          ready
            ? t('common.non-permissions')
            : 'Permisos insuficientes o Url inválida',
          {
            variant: 'error',
          }
        )
      : null;
  }, [props]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged && getAccessByRol(path) ? (
          <LayoutSwitcher>{children}</LayoutSwitcher>
        ) : isLogged && !getAccessByRol(path) ? (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};
