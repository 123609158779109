import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      minHeight: '90px',
      background: '#56565A',
      '@media print': {
        display: 'none',
      },
    },
    imgContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      maxHeight: '90px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
        padding: '20px 20px 10px 20px',
      },
    },
    hmyLogo: {
      height: '48px',
      width: 'auto',
    },
    rightSide: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contactContainer: {
      display: 'flex',
      padding: '10px 30px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    phoneContactContainer: {
      display: 'flex',
      padding: '10px 30px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'center',
        padding: '0px 0px 10px 0px',
      },
    },
    contactLogosContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    logoContainer: {
      padding: '10px 0px 0px 10px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    footerText: {
      display: 'flex',
      color: 'white',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    telephonefooterText: {
      display: 'flex',
      color: 'white',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        marginRight: '10px !important',
      },
    },
    phoneIcon: {
      transform: 'scale(0.7)',
    },
    phoneContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    phoneText: {
      color: 'white',
      fontSize: '12px !important',
    },
    versionContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0px',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    versionText: {
      color: 'white',
      fontSize: '15px !important',
    },
    socialMediaLogo: {
      width: '24px',
      height: '24px',
    },
  };
});
