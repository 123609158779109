import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux';
import { useHistory } from 'react-router-dom';
import { remove, last } from 'lodash';
import { statusLabels } from '../../../interfaces/platform';
import { InstallationGral, DevicesTypes } from '../../../interfaces/core';
import { useStyles } from './styles';
import StatusChip from '../../../components/atoms/StatusChip';
import BriefcaseIcon from '@mui/icons-material/CardTravel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import TvIcon from '@mui/icons-material/Tv';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Grid,
} from '@mui/material';

interface DataLeftProps {
  data?: InstallationGral;
}

const DetailDataLeft = ({ data }: DataLeftProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  return (
    <Box sx={{ width: 1 }}>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.address')}</b> : <b>Dirección</b>}:{'    '}
        {data?.address || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.city')}</b> : <b>Ciudad</b>}:{'    '}
        {data?.town || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.cp')}</b> : <b>Código Postal</b>}:{'    '}
        {data?.postalCode || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.phone')}</b> : <b>Teléfono</b>}:{'    '}
        {data?.phone || t('common.sin-datos')}
      </Typography>
    </Box>
  );
};

interface DataRightProps {
  data?: InstallationGral;
}

const DetailDataRight = ({ data }: DataRightProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();

  return (
    <Box sx={{ width: 1 }}>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.contact')}</b> : <b>Contacto</b>}:{'    '}
        {data?.contactPerson?.name || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.cell')}</b> : <b>Teléfono movil</b>}:{'    '}
        {data?.contactPerson?.phone || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.email')}</b> : <b>Email</b>}:{'    '}
        {data?.contactPerson?.email || t('common.sin-datos')}
      </Typography>
      <Typography display="flex" alignItems="center">
        <LabelImportantIcon className={classes.itemIcon} />
        {ready ? <b>{t('support.country')}</b> : <b>País</b>}:{'    '}
        {data?.country || t('common.sin-datos')}
      </Typography>
    </Box>
  );
};

interface DetailProps {
  data?: InstallationGral;
  typeSelected: string;
  handleSolutionChange: (a?: any) => void;
  deviceTypes: DevicesTypes[];
  handleStatusDeviceChange: (a?: any) => void;
  statusDevicesSelected: string[];
}

const Detail = (props: DetailProps): JSX.Element => {
  const { t, ready } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const {
    data,
    typeSelected,
    handleSolutionChange,
    deviceTypes,
    handleStatusDeviceChange,
    statusDevicesSelected,
  } = props;

  const { supportPath } = useAppSelector((state) => state.path);

  const deviceTypesList = [{ id: 0, name: t('support.all-solutions') }].concat(
    deviceTypes
  );

  const deviceStatusList = [
    { id: 'all', name: t('support.statusChip.all') },
  ].concat(
    statusLabels.map((status) => ({
      id: status,
      name: t(`support.statusChip.${status}`),
    }))
  );

  return (
    <>
      <Button
        variant="contained"
        data-testid="go-back"
        disableElevation
        onClick={() =>
          history.push(`/support${supportPath}`, {
            from: history.location.pathname,
          })
        }
        startIcon={<ArrowBackIcon />}
      >
        {ready ? t('support.go-back') : 'Volver'}
      </Button>
      <Box className={classes.root}>
        <Grid
          item
          xs={12}
          md={7}
          xl={6}
          className={classes.customerDataContainer}
        >
          <Box>
            <Box className={classes.textContainer}>
              <BriefcaseIcon className={classes.infoIcon} />
              <Typography className={classes.dataText} data-testid="crm-text">
                {ready ? t('support-detail.crmGroup') : 'Grupo CRM'}:{' '}
                {data?.customer?.crmGroup?.name} (
                {data?.customer?.crmGroup?.key}){' '}
              </Typography>
            </Box>

            <Box className={classes.textContainer}>
              <PersonIcon className={classes.infoIcon} />
              <Typography
                className={classes.dataText}
                data-testid="customer-text"
              >
                {ready ? t('support.client') : 'Cliente'}:{' '}
                {data?.customer?.name} ({data?.customer?.key}){' '}
              </Typography>
              <StatusChip
                style={{ marginLeft: '10px', fontSize: '16px' }}
                status={data?.customer?.status || ''}
              />
            </Box>

            <Box className={classes.textContainer}>
              <TvIcon className={classes.infoIcon} />
              <Typography
                className={classes.dataText}
                data-testid="installation-text"
              >
                {ready ? t('support.instalation') : 'Instalación'}: {data?.name}{' '}
                ({data?.key}){' '}
              </Typography>
              <StatusChip
                style={{ marginLeft: '10px', fontSize: '16px' }}
                status={data?.status || ''}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} xl={6} className={classes.selectContainer}>
          <FormControl className={classes.styledFormControl} fullWidth>
            <InputLabel id="multiple-device-staus-select-id">
              {ready ? t('support.status') : 'Estado'}
            </InputLabel>
            <Select
              labelId="multiple-device-staus-select"
              data-testid="availability-select"
              id="multiple-device-staus-select"
              multiple
              value={statusDevicesSelected}
              label={t('support.status')}
              onChange={(
                event: SelectChangeEvent<typeof statusDevicesSelected>
              ) => {
                const value = event.target.value;
                let toSet =
                  typeof value === 'string' ? value.split(',') : value;
                if (last(toSet) == 'all') {
                  toSet = ['all'];
                } else if (toSet.includes('all')) {
                  remove(toSet, (s: string) => s == 'all');
                }
                handleStatusDeviceChange(toSet);
              }}
              onClose={() => {
                if (!statusDevicesSelected.length) {
                  handleStatusDeviceChange(['all']);
                }
              }}
              renderValue={(selected) =>
                selected
                  .map((s: string) => t(`support.statusChip.${s}`))
                  .join(', ')
              }
            >
              {deviceStatusList.map((dSt) => (
                <MenuItem key={dSt.id} value={dSt.id}>
                  <Checkbox
                    checked={
                      !!statusDevicesSelected.find((s: string) => s === dSt.id)
                    }
                  />
                  <ListItemText primary={dSt.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.styledFormControl} fullWidth>
            <InputLabel id="demo-simple-select-label">
              {ready ? t('support.solution') : 'Solución'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              data-testid="solution-select"
              id="demo-simple-select"
              value={typeSelected}
              label={ready ? t('support.solution') : 'Solución'}
              onChange={(event: SelectChangeEvent) => {
                handleSolutionChange(event.target.value);
              }}
            >
              {deviceTypesList.map((type: any) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <Typography
        variant="h6"
        data-testid="detail-title"
        sx={{ ml: 2, mb: 1, display: 'flex', justifyContent: 'flex-start' }}
      >
        {ready
          ? t('support.details-installation')
          : 'Detalles de la instalación'}
      </Typography>
      <Box className={classes.installationDetailContainer}>
        <DetailDataLeft data={data} />
        <DetailDataRight data={data} />
      </Box>
    </>
  );
};

export default Detail;
