import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100vw - 41px)',
      },
    },
    syncButton: {
      '&:hover': {
        backgroundColor: '#bac6e0',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  };
});
