import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: '-0.8px 0.8px 0 0',
      borderEndEndRadius: '10px',
      borderEndStartRadius: '10px',
    },
    main: {
      display: 'flex',
      flexWrap: 'wrap',
      background: 'rgba(0, 128, 185, 0.05)',
      padding: '16px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      marginBottom: '20px',
    },
    barButton: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    loadingButton: {
      height: '56px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    loadingButton2: {
      height: '56px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      display: 'none',
    },
    googleDump: {
      height: '56px',
      margin: '0px 10px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '10px 0px !important',
      },
    },
    filterButton: {
      height: '56px',
      marginLeft: '20px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '10px 0px !important',
      },
    },
    syncButton: {
      width: '56px',
      height: '56px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    datePickersContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-start',
        marginTop: '16px !important',
      },
    },
    filterText: {
      fontSize: '14px',
      padding: '0px 20px',
    },
    pickerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '10px',
        marginRight: '0px',
      },
    },
    textModalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    itemsModalContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: '2.5rem',
      overflowY: 'scroll',
    },
    itemsModalContainerDump: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

      overflowY: 'scroll',
      maxHeight: '40vh',
      border: 'solid 1px black',
    },
    itemTittleDump: {
      backgroundColor: 'grey',
    },
    itemDump: {
      paddingLeft: '2.5rem',
      width: '100%',
    },
    locationIcon: {
      transform: 'scale(1.3)',
      margin: '0px 10px',
    },
    modalText: {
      fontSize: '1.5rem !important',
      padding: '20px 0px',
    },
    modalItemText: {
      fontSize: '1rem !important',
      padding: '0px',
    },
    modalBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    bodyModal: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
    },
  };
});
