import { SyntheticEvent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import BusinessDashboard from './BusinessDashboard';
import MetricsReport from './MetricsReport';
import { a11yProps } from '../../utils/tabpanel';
import { useTheme } from '@mui/material/styles';
import { TabPanel } from '../../components/molecules/TabPanel';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { isRolAdmin } from '../../utils/adminList';
import { getUserInfo } from '../../services/user';

const Report = () => {
  const theme = useTheme();
  const { t, ready } = useTranslation();
  const [value, setValue] = useState(0);
  const loggedUser = getUserInfo();
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {/* <Tab
            label={ready ? t('reports.reports-tab') : 'Reportes'}
            {...a11yProps(0)}
          /> */}
          {(isRolAdmin(loggedUser.role) ||
            loggedUser.hasBusinessDashboards) && (
            <Tab
              label={
                ready
                  ? t('reports.business-dashboard-tab')
                  : 'Business Dashboard'
              }
              {...a11yProps(1)}
            />
          )}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <MetricsReport />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <BusinessDashboard />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

export default Report;
