import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { sortBy, toString } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Query from 'query-string';

import { getDeviceTypes } from '../services/devices';
import { paginatedSupportData } from '../services/installation';
import { QueryKey } from '../interfaces/platform';
import { useAppDispatch } from '../redux';
import {
  currentPageValue,
  rowsPerPageValue,
  changeCurrentPage,
} from '../redux/table';
import { InstallationDevice, UseSupInstallReturn } from '../interfaces/core';
import { InstallationData } from '../interfaces/installations';

export const useSupportInstallation = ({
  location,
}: {
  location: string;
}): UseSupInstallReturn => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const page = useSelector(currentPageValue);
  const rowsPerPage = useSelector(rowsPerPageValue);
  const [deviceStatusSelected, setDeviceStatusSelected] = useState<string[]>([
    'all',
  ]);
  const [installations, setInstallations] = useState<InstallationData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [optionsCrmGroup, setOptionsCrmGroup] = useState<string[]>([]);
  const [optionsCustomer, setOptionsCustomer] = useState<string[]>([]);
  const [optionsInstall, setOptionsInstall] = useState<string[]>([]);
  const [searchCrmGroup, setSearchCrmGroup] = useState<string>('');
  const [searchCustomer, setSearchCustomer] = useState('');
  const [searchInstall, setSearchInstall] = useState('');
  const [totalRows, setTotalRows] = useState<number>(0);
  const [types, setTypes] = useState<InstallationDevice['deviceType'][]>([]);
  const [typeSelected, setTypeSelected] = useState('0');

  useEffect(() => {
    getData();
  }, [location, rowsPerPage, page]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const query = Query.parse(location);
      const { data } = await paginatedSupportData({
        group: toString(query.g),
        customer: toString(query.c),
        installation: toString(query.i),
        town: toString(query.a),
        solution: toString(query.s || 0),
        availability: toString(query.ds || 'all').split(','),
        rowsPerPage: rowsPerPage,
        pageNumber: page,
      });
      const {
        data: installationData,
        crmGroups,
        customers,
        installations,
        totalRows,
      } = data;
      setInstallations(installationData);
      setOptionsCrmGroup(sortBy(crmGroups));
      setOptionsCustomer(sortBy(customers));
      setOptionsInstall(sortBy(installations));
      setTotalRows(totalRows);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    initLocationStates();
    getDeviceTypes()
      .then((res: AxiosResponse) => res.data)
      .then((data) => setTypes(sortBy(data, 'name')))
      .catch((err) => console.error(err));
  }, []);

  const initLocationStates = () => {
    const query = Query.parse(location);
    setSearchCrmGroup(toString(query.g));
    setSearchCustomer(toString(query.c));
    setSearchInstall(toString(query.i));
    setTypeSelected(toString(query.s || 0));
    setDeviceStatusSelected(toString(query.ds || 'all').split(','));
  };

  const handleClean = () => {
    setSearchCrmGroup('');
    setSearchCustomer('');
    setSearchInstall('');
    setTypeSelected('0');
    setDeviceStatusSelected(['all']);
    dispatch(changeCurrentPage(0));
    setIsLoading(false);
    history.push('/support');
  };

  const makeHandler = (
    key: QueryKey,
    setter: React.Dispatch<React.SetStateAction<any>>
  ) => {
    return function (a?: any) {
      setSearchHistory(key, a);
      setter(a);
    };
  };

  const setSearchHistory = useCallback(
    (key: QueryKey, value: any) => {
      const obj = Query.parse(location);
      obj[key] = value;
      const q = Query.stringify(obj);
      history.push(`?${q}`);
    },
    [location]
  );

  return {
    deviceStatusSelected,
    handleClean,
    installations,
    isLoading,
    optionsCrmGroup,
    optionsCustomer,
    optionsInstall,
    page,
    rowsPerPage,
    searchCrmGroup,
    searchCustomer,
    searchInstall,
    setDeviceStatusSelected: makeHandler('ds', setDeviceStatusSelected),
    setInstallations,
    setSearchCrmGroup: makeHandler('g', setSearchCrmGroup),
    setSearchCustomer: makeHandler('c', setSearchCustomer),
    setSearchInstall: makeHandler('i', setSearchInstall),
    setTotalRows,
    setTypeSelected: makeHandler('s', setTypeSelected),
    totalRows,
    types,
    typeSelected,
  };
};
