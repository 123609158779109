import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import InstallationsTable from './SupportTable';
import MobileFilter from './SupportFilter/MobileFilter';
import DesktopFilter from './SupportFilter/DesktopFilter';
import { useAppDispatch } from '../../redux';
import { setSupportPath } from '../../redux/path';
import { useSupportInstallation } from '../../hooks/useSupportInstallation';
import { useStyles } from './styles';
import { Box } from '@mui/material';
import { changeCurrentPage } from '../../redux/table';
import { useHistory } from 'react-router-dom';

const Installations = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation().search;
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });

  const {
    isLoading,
    searchCustomer,
    searchInstall,
    setSearchCustomer,
    setSearchInstall,
    typeSelected,
    setTypeSelected,
    types,
    handleClean,
    installations,
    optionsCustomer,
    optionsInstall,
    setDeviceStatusSelected,
    deviceStatusSelected,
    optionsCrmGroup,
    searchCrmGroup,
    setSearchCrmGroup,
    totalRows,
    page,
    rowsPerPage,
  } = useSupportInstallation({ location });

  useEffect(() => {
    dispatch(setSupportPath(location));
    if (!history.location.state) {
      dispatch(changeCurrentPage(0));
    }
  }, [location]);

  return (
    <div>
      <Box className={classes.root}>
        {isMobile && (
          <MobileFilter
            deviceStatusSelected={deviceStatusSelected}
            handleClean={handleClean}
            optionsCrmGroup={optionsCrmGroup}
            optionsCustomer={optionsCustomer}
            optionsInstall={optionsInstall}
            searchCrmGroup={searchCrmGroup}
            searchCustomer={searchCustomer}
            searchInstall={searchInstall}
            setDeviceStatusSelected={setDeviceStatusSelected}
            setSearchCrmGroup={setSearchCrmGroup}
            setSearchCustomer={setSearchCustomer}
            setSearchInstall={setSearchInstall}
            setTypeSelected={setTypeSelected}
            types={types}
            typeSelected={typeSelected}
          />
        )}
        {isDesktop && (
          <DesktopFilter
            deviceStatusSelected={deviceStatusSelected}
            handleClean={handleClean}
            optionsCrmGroup={optionsCrmGroup}
            optionsCustomer={optionsCustomer}
            optionsInstall={optionsInstall}
            searchCrmGroup={searchCrmGroup}
            searchCustomer={searchCustomer}
            searchInstall={searchInstall}
            setDeviceStatusSelected={setDeviceStatusSelected}
            setSearchCrmGroup={setSearchCrmGroup}
            setSearchCustomer={setSearchCustomer}
            setSearchInstall={setSearchInstall}
            setTypeSelected={setTypeSelected}
            types={types}
            typeSelected={typeSelected}
          />
        )}
        <Box className={classes.main}>
          <InstallationsTable
            data={installations}
            rowsQuantity={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            loading={isLoading}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Installations;
