import { useTranslation } from 'react-i18next';
import linkedinLogo from '../../assets/svg/linkedin-logo.svg';
import youtubeLogo from '../../assets/svg/youtube-logo.svg';
import twitterLogo from '../../assets/svg/twitter-logo.svg';
import pinterestLogo from '../../assets/svg/pinterest-logo.svg';

const useFooterData = () => {
  const { t, ready } = useTranslation();
  interface SocialMediaProps {
    name: string;
    url: string;
    logo: string;
  }

  const SocialMedia: Array<SocialMediaProps> = [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/company/hmy/',
      logo: linkedinLogo,
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/user/HMYGROUP',
      logo: youtubeLogo,
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/hmy_group',
      logo: twitterLogo,
    },
    {
      name: 'pinterest',
      url: 'https://ar.pinterest.com/hmy/_created/',
      logo: pinterestLogo,
    },
  ];

  interface PhoneNumberProps {
    location: string;
    phone: string;
  }

  const PhoneNumber: Array<PhoneNumberProps> = [
    {
      location: ready ? t('countries.france') : 'Francia',
      phone: '0 379 100 121',
    },
    {
      location: ready ? t('countries.spain') : 'España',
      phone: '917 71 81 40',
    },
  ];

  return { SocialMedia, PhoneNumber };
};

export default useFooterData;
