import { ChangeEvent, ErrorInfo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logout } from '../../redux/user';
import { useAppDispatch } from '../../redux';
import { useStyles } from './styles';
import HmyLogo from '../../assets/png/HMY-logo-white.png';
import typography from '../../theme/typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { sendErrorLog } from '../../services/report';

const ErrorPage = ({
  error,
  errorInfo,
}: {
  error: Error;
  errorInfo: ErrorInfo;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openTextbox, setOpenTextbox] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUserMessage(e.target.value);
  };

  const handleReload = async () => {
    try {
      await sendErrorLog({
        subject: `HMY-PLATFORM ERROR LOG ${new Date().toLocaleString()}`,
        error: error.stack,
        errorInfo: errorInfo,
        userMessage: userMessage,
      });
      dispatch(logout());
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.errorContainer}>
        <Box className={classes.topContainer}>
          <img src={HmyLogo} alt="HMY Logo" className={classes.hmyImage} />
          <ErrorOutlineIcon className={classes.icon} />
        </Box>
        <Box>
          <Typography
            color="white"
            fontFamily={typography}
            fontSize={25}
            fontWeight="bold"
          >
            {t('common.unexpected-error')}
          </Typography>
          <Typography color="white" fontFamily={typography} fontSize={25}>
            {t('common.unexpected-error-message')}
          </Typography>
        </Box>
        <Box className={classes.sendReportText}>
          <Typography color="white" fontFamily={typography}>
            {`${t('common.send-error-log')}\u00A0`}
          </Typography>
          <Typography
            className={classes.hereText}
            color="white"
            fontFamily={typography}
            onClick={() => setOpenTextbox(!openTextbox)}
          >
            {t('common.here')}
          </Typography>
        </Box>
        {openTextbox && (
          <Box className={classes.textBoxContainer}>
            <TextField
              fullWidth
              placeholder="Placeholder"
              variant="standard"
              onChange={handleChange}
              value={userMessage}
              multiline
              rows={4}
              InputProps={{
                inputProps: { style: { color: '#fff' } },
                disableUnderline: true,
              }}
            />
          </Box>
        )}
        <Button
          className={classes.button}
          disableElevation
          fullWidth
          onClick={() => handleReload()}
          variant="contained"
        >
          <Typography fontFamily={typography}>
            {userMessage
              ? t('common.reload-and-send')
              : t('common.reload-site')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
