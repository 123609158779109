import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      height: '100%',
      padding: '10px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    main: {
      height: '100%',
      width: '5px',
      borderRadius: '2px',
      background: 'linear-gradient(0deg, #525252 0%, #0081BC 100%)',
    },
  };
});
