import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSupportDetailTable } from './useSupportDetailTable';
import { InstallationDevice } from '../../../interfaces/core';
import StatusChip from '../../../components/atoms/StatusChip';
import Actions from '../../../components/Table/Actions';
import {
  currentPageSupportDetail,
  rowsPerPageSupportDetail,
} from '../../../redux/table';
import {
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

interface TableProps {
  data: InstallationDevice[];
  actions?: any;
  rowsQuantity: string;
  loading: boolean;
}

interface TableHeads {
  id: string;
  label: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  width: '10%' | '20%' | '30%' | '40%' | '50%' | '60%';
}

const CustomTableHead = () => {
  const { t } = useTranslation();

  const tableHeads: TableHeads[] = [
    {
      id: 'deviceName',
      label: t('table.devices.name'),
      align: 'center',
      width: '30%',
    },
    {
      id: 'deviceType',
      label: t('table.devices.deviceType'),
      align: 'center',
      width: '20%',
    },
    {
      id: 'deviceStatus',
      label: t('table.devices.status'),
      align: 'center',
      width: '10%',
    },
    {
      id: 'supportLink',
      label: t('table.devices.link'),
      align: 'center',
      width: '20%',
    },
    {
      id: 'deviceActions',
      label: t('common.table.actions'),
      align: 'center',
      width: '20%',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {tableHeads.map((tableHead) => (
          <TableCell
            key={tableHead.id}
            align={tableHead.align}
            width={tableHead.width}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {tableHead.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SuportDetailTable = (props: TableProps) => {
  const { data, actions, rowsQuantity, loading } = props;
  const { t } = useTranslation();
  const rowsPerPage = useSelector(rowsPerPageSupportDetail);
  const currentPage = useSelector(currentPageSupportDetail);
  const [dense, setDense] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { rows, handleChangePage, handleChangeRowsPerPage } =
    useSupportDetailTable(data);

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const getIndexTable = () => {
    return rows.map((_row: any, index: number) => {
      return index;
    });
  };

  return (
    <Grid>
      <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={isMobile ? 'small' : dense ? 'small' : 'medium'}
          >
            <CustomTableHead />
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {`${row.name} (${row.device_key})` || t('common.sin-datos')}
                  </TableCell>
                  <TableCell align="center">
                    {row.deviceType || t('common.sin-datos')}
                  </TableCell>
                  <TableCell align="center">
                    <StatusChip status={row.status || ''} />
                  </TableCell>
                  <TableCell align="center">
                    {row.link ? (
                      <a href={row.link} target="_blank" rel="noreferrer">
                        {row.link.length > 45
                          ? row.link.slice(0, 45) + '...'
                          : row.link}
                      </a>
                    ) : (
                      <span>{t('solutions.noLink')}</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Actions
                      actionsProp={actions}
                      data={row}
                      position={index}
                      indexTable={getIndexTable()}
                      remoteOperations={true}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <LinearProgress
            sx={{ position: 'absolute', right: '0px', left: '0px' }}
          />
        )}
        <TablePagination
          labelRowsPerPage={t('common.table.row')}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={Number(rowsQuantity)}
          rowsPerPage={rowsPerPage}
          page={Number(rowsQuantity) <= 0 ? 0 : currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {!isMobile && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label={String(t('common.compact-view'))}
        />
      )}
    </Grid>
  );
};

export default SuportDetailTable;
