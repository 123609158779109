import { DateTime } from 'luxon';
import { LogInterface } from '../interfaces/core';

export function statusFormat(t: (a: string, b?: string) => string) {
  const translate: any = {
    ['Completado']: t('log.log-status.completed'),
    ['Completo con errores']: t('log.log-status.completed-with-error'),
    ['Completado con errores']: t('log.log-status.completed-with-error'),
    ['En progreso']: t('log.log-status.in-progress'),
    ['Error']: t('log.log-status.error'),
  };
  return translate;
}

export function generateInform(
  log: LogInterface | undefined,
  t: (a: string, b?: string) => string
): any {
  return `ID: ${log?.id} 
${t('log.type')}: ${log?.isAutomatic ? t('log.automatic') : t('log.manual')}
${t('log.user')}: ${
    log?.isAutomatic ? t('log.system') : log?.user?.username || t('log.nobody')
  } 
${t('log.status')}: ${statusFormat(t)[log?.status ?? ''] || log?.status}
${t('log.datetime')}: ${
    (log?.started &&
      DateTime.fromJSDate(new Date(log?.started)).toFormat(
        'dd/MM/y HH:mm:ss'
      )) ||
    ''
  }
${t('log.finished')}: ${
    (log?.finished &&
      DateTime.fromJSDate(new Date(log?.finished)).toFormat(
        'dd/MM/y HH:mm:ss'
      )) ||
    ''
  } \n
${JSON.stringify(log?.modifications, null, 2)}`;
}

export const downloadTxtFile = (txt: string, filename: string): any => {
  const element = document.createElement('a');
  const file = new Blob([txt], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.txt`;
  document.body.appendChild(element);
  element.click();
};
