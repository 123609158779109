import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface MapState {
  zoom: number;
}

const initialState: MapState = {
  zoom: 6,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
  },
});

export const { setMapZoom } = mapSlice.actions;
export const selectZoom = (state: RootState) => state.map;
export default mapSlice.reducer;
