import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { getUserInfo } from '../../services/user';
import { InstallationsData } from '../../redux/installations';
import { isRolAdmin } from '../../utils/adminList';
import { useAppSelector } from '../../redux';
import { useDashboardTable } from '../../hooks/useDashboardTable';
import { useStyles } from './styles';
import CollapsibleTable from '../../components/CollapsibleTable';
import CustomSnakbar from '../../components/molecules/CustomSnakbar';
import DashboardCard from './DashboardCard';
import DesktopFilter from './Filter/DesktopFilter';
import GeolocationMap from '../../components/GeolocationMap';
import MobileFilter from './Filter/MobileFilter';
import NivoPieChart from '../../components/NivoPieChart';

import { Grid, LinearProgress, Snackbar } from '@mui/material';
import BriefcaseIcon from '@mui/icons-material/CardTravel';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import GroupIcon from '@mui/icons-material/Group';
import InstallDesktop from '@mui/icons-material/InstallDesktop';
import TvIcon from '@mui/icons-material/Tv';

const Dashboard = (): JSX.Element => {
  const loggedUser = getUserInfo();
  const classes = useStyles();
  const location = useLocation().search;
  const { t, ready } = useTranslation();
  const { zoom } = useAppSelector((state) => state.map);
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [open, setOpen] = useState<boolean>(false);

  const {
    coordinatesLoading,
    crmGroups,
    customers,
    groupedSolutions,
    installations,
    addresses,
    installationsCoordinates,
    installationsLoading,
    kpis,
    paginatedInstallations,
    solutions,
    totalRows,
  } = useAppSelector<InstallationsData>((state) => state.installations);

  const { tableHead, rows, collapsibleTableHead, collapsibleRowTitle } =
    useDashboardTable({
      paginatedInstallations,
    });

  const mapCenter = () => {
    if (installationsCoordinates.length) {
      return {
        lat: parseFloat(installationsCoordinates[0].latitude),
        lng: parseFloat(installationsCoordinates[0].longitude),
      };
    } else {
      return { lat: 43.647032, lng: 0.589137 };
    }
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Empty active validation for no admin users
  useEffect(() => {
    if (
      !loggedUser.hasCustomers &&
      !loggedUser.crmGroup &&
      !isRolAdmin(loggedUser.role)
    ) {
      setOpen(true);
    }
  }, []);

  return (
    <Grid>
      {isMobile && (
        <MobileFilter
          crmGroups={crmGroups}
          customers={customers}
          installations={installations}
          addresses={addresses}
          location={location}
          solutions={solutions}
        />
      )}
      {isDesktop && (
        <DesktopFilter
          crmGroups={crmGroups}
          customers={customers}
          installations={installations}
          addresses={addresses}
          location={location}
          solutions={solutions}
        />
      )}
      <Grid className={classes.main}>
        <Grid className={classes.kpisContainer}>
          <Grid className={classes.cardContainer}>
            <DashboardCard
              icon={<BriefcaseIcon fontSize="large" className={classes.icon} />}
              kpiKey={'crmgroup'}
              location={location}
              progress={installationsLoading}
              title={ready ? t('dashboard.crmGroups') : 'Grupos'}
              value={kpis.crmGroupsQuantity}
              withDownload={!loggedUser?.hasCustomers}
            />
          </Grid>
          <Grid className={classes.cardContainer}>
            <DashboardCard
              icon={<GroupIcon fontSize="large" className={classes.icon} />}
              kpiKey={'customer'}
              location={location}
              progress={installationsLoading}
              title={ready ? t('dashboard.customers') : 'Clientes'}
              value={kpis.customerQuantity}
              withDownload={!loggedUser?.hasCustomers}
            />
          </Grid>
          <Grid className={classes.cardContainer}>
            <DashboardCard
              icon={
                <ConnectedTvIcon fontSize="large" className={classes.icon} />
              }
              kpiKey={'solution'}
              location={location}
              progress={installationsLoading}
              title={ready ? t('dashboard.devices') : 'Dispositivos'}
              value={kpis.devicesQuantity}
              withDownload={!loggedUser?.hasCustomers}
            />
          </Grid>
          <Grid className={classes.cardContainer}>
            <DashboardCard
              icon={
                <InstallDesktop fontSize="large" className={classes.icon} />
              }
              kpiKey={'installation'}
              location={location}
              progress={installationsLoading}
              title={ready ? t('dashboard.installations') : 'Instalaciones'}
              value={kpis.installationsQuantity}
              withDownload={!loggedUser?.hasCustomers}
            />
          </Grid>
          <Grid className={classes.cardContainer}>
            <DashboardCard
              icon={<TvIcon fontSize="large" className={classes.icon} />}
              kpiKey={'solutionType'}
              location={location}
              progress={installationsLoading}
              title={
                ready ? t('dashboard.solutionTypes') : 'Tipo de Soluciones'
              }
              value={kpis.solutionTypeQuantity}
              withDownload={!loggedUser?.hasCustomers}
            />
          </Grid>
          <Grid className={classes.cardContainer}>
            {/* <DashboardCard
              icon={<CellWifiIcon fontSize="large" className={classes.icon} />}
              isPercent
              progress={installationsLoading}
              title={
                ready ? t('dashboard.devices-connected') : 'Con conexión a red'
              }
              value={kpis.devicesConnected}
            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.infoContainer}>
        <Grid className={classes.chartContainer}>
          <NivoPieChart data={groupedSolutions.total} />
        </Grid>
        <Grid className={classes.mapContainer}>
          {coordinatesLoading && (
            <LinearProgress className={classes.progress} />
          )}
          <GeolocationMap dynamicCenter={mapCenter()} zoom={zoom} />
        </Grid>
      </Grid>
      <Grid className={classes.tableContainer}>
        <CollapsibleTable
          tableHead={tableHead}
          rows={rows ?? []}
          totalRows={totalRows}
          loading={installationsLoading}
          collapsibleTableHead={collapsibleTableHead}
          collapsibleRowTitle={collapsibleRowTitle}
        />
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <CustomSnakbar
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {ready
            ? t('dashboard.no-customer-crm')
            : 'Usuario sin cliente / grupo CRM asociado'}
        </CustomSnakbar>
      </Snackbar>
    </Grid>
  );
};

export default Dashboard;
