import { ResponsivePie } from '@nivo/pie';
import { cloneDeep } from 'lodash';
import { useMediaQuery } from 'react-responsive';

interface Props {
  data: any;
  hasLinkLabels?: boolean;
  margin?: any;
  hasLegends?: boolean;
  colors?: string[];
  cornerRadius?: number;
  legendDirection?: 'column' | 'row';
  legendPosition?:
    | 'top'
    | 'top-right'
    | 'right'
    | 'bottom-right'
    | 'bottom'
    | 'bottom-left'
    | 'left'
    | 'top-left'
    | 'center';
  labelPercentage?: boolean;
  isInteractive?: boolean;
  labelColor?: string;
}

const emptyData = [
  {
    id: 'Sin dispositivos',
    label: 'Sin dispositivos',
    value: true,
  },
];

const NivoPieChart = (props: Props) => {
  const {
    data,
    hasLinkLabels = true,
    margin = { top: 40, right: 80, bottom: 80, left: 80 },
    hasLegends = true,
    colors = [
      '#CE530D',
      '#33D4FF',
      '#4F4F4F',
      '#F18327',
      '#D433FF',
      '#33D4FF',
      '#09339D',
    ],
    cornerRadius = 3,
    legendDirection = 'column',
    legendPosition = 'bottom-right',
    labelPercentage = false,
    isInteractive = true,
    labelColor = '#ffffff',
  } = props;
  const smallScreen = useMediaQuery({ query: '(max-width: 1500px)' });

  const theme = {
    fontSize: smallScreen ? 11 : 15,
    legends: {
      text: {
        fontSize: smallScreen ? 11 : 15,
      },
    },
  };

  const setData = () => {
    if (data.length) {
      const dataFormated = cloneDeep(data);
      return dataFormated.map((item: any) => {
        if (item.label === 'Nsign Digital Signage Player') {
          item.id = 'Nsign Digital';
          item.label = 'Nsign Digital';
        }
        return item;
      });
    } else {
      return emptyData;
    }
  };

  return (
    <ResponsivePie
      data={setData()}
      margin={margin}
      innerRadius={0.5}
      padAngle={0.7}
      startAngle={-330}
      cornerRadius={cornerRadius}
      activeOuterRadiusOffset={8}
      colors={colors}
      theme={theme}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1]],
      }}
      enableArcLinkLabels={hasLinkLabels}
      arcLinkLabelsSkipAngle={5}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsDiagonalLength={8}
      arcLinkLabelsStraightLength={8}
      arcLinkLabelsColor={{ from: 'color', modifiers: [['darker', 1]] }}
      enableArcLabels={data.length ? true : false}
      arcLabel={
        labelPercentage
          ? function (e) {
              return e.value + '%';
            }
          : function (e) {
              return e.value.toString();
            }
      }
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={labelColor}
      isInteractive={isInteractive ? (data.length ? true : false) : false}
      legends={
        hasLegends
          ? [
              {
                anchor: legendPosition,
                direction: legendDirection,
                justify: false,
                translateX: 40,
                translateY: 70,
                itemsSpacing: 1,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'square',
              },
            ]
          : []
      }
    />
  );
};

export default NivoPieChart;
