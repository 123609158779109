import { ChangeEvent, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FilteredLogs } from '../../interfaces/core';
import StatusChip from '../atoms/StatusChip';
import { useStyles } from './styles';
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useAppDispatch } from '../../redux';
import { useSelector } from 'react-redux';
import {
  changeRowsPerPage,
  changeCurrentPage,
  rowsPerPageValue,
  currentPageValue,
} from '../../redux/table';
import DumpDetail from '../DumpDetail';
import { DumpResult } from '../../interfaces/dump';

interface Data {
  datetime: string;
  status: string;
  type: string;
  id: number;
}

const createData = (
  datetime: string,
  status: string,
  type: string,
  id: number
): Data => {
  return {
    datetime,
    status,
    type,
    id,
  };
};
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  center?: boolean;
}

const HeadCellsData = () => {
  const { ready, t } = useTranslation();
  const headCells: Array<HeadCell> = [
    {
      id: 'datetime',
      numeric: false,
      disablePadding: false,
      label: ready ? t('table.formDataDump.datetime') : 'Fecha y Hora',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: ready ? t('table.formDataDump.status') : 'Estado',
      center: true,
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: ready ? t('table.formDataDump.type') : 'Tipo',
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: ready ? t('table.formDataDump.details') : 'Detalles',
    },
  ];
  return headCells;
};

function EnhancedTableHead() {
  const headCells = HeadCellsData();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ fontWeight: 'bold' }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
interface ITableDumpHistory {
  data: FilteredLogs[];
}

export const TableDumpHistory = ({ data }: ITableDumpHistory): JSX.Element => {
  const classes = useStyles();
  const [dense, setDense] = useState(false);
  const dispatch = useAppDispatch();
  const rowsPerPage = useSelector(rowsPerPageValue);
  const [rows, setRows] = useState<Data[]>([]);
  const [dumpOpen, setDumpOpen] = useState<boolean>(false);
  const [dumpData, setDumpData] = useState<DumpResult>();
  const history = useHistory();
  const { t, ready } = useTranslation();
  const page = useSelector(currentPageValue);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 600px)' });

  useEffect(() => {
    if (!history.location.state) {
      dispatch(changeCurrentPage(0));
    }
  }, [data]);

  useEffect(() => {
    const devices = data.map((r: any) => {
      return createData(
        `${DateTime.fromJSDate(new Date(r?.started?.toString())).toFormat(
          'dd/MM/y HH:mm'
        )}`,
        `${r?.status}`,
        `${
          r?.isAutomatic ? t('maintenance.automatic') : t('maintenance.manual')
        }`,
        r?.id
      );
    });

    setRows(devices);
  }, [data, t]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(changeCurrentPage(newPage));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(changeRowsPerPage(newRowsPerPage));
    dispatch(changeCurrentPage(0));
  };

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleOpenModal = (rowId: number) => {
    setDumpOpen(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dumpItem = data.find((item: FilteredLogs) => item.id === rowId)!;
    setDumpData({
      id: dumpItem.id,
      info: dumpItem.info,
      finished: dumpItem.finished,
      modifications: dumpItem.modifications,
      status: dumpItem.status,
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      {dumpOpen && (
        <DumpDetail
          dumpData={dumpData}
          dumpOpen={dumpOpen}
          setDumpOpen={setDumpOpen}
        ></DumpDetail>
      )}
      <Box className={classes.root}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={isMobile ? 'small' : dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead />
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`${row.datetime}-${index}`}
                      >
                        <TableCell id={labelId} scope="row" align="center">
                          {row.datetime}
                        </TableCell>
                        <TableCell align="center">
                          <StatusChip status={row?.status?.toString() || ''} />
                        </TableCell>
                        <TableCell align="center">{row.type}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            className={classes.syncButton}
                            onClick={() => handleOpenModal(row.id)}
                          >
                            {t('maintenance.view-details')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={
              ready ? t('common.table.row') : 'Filas por columna'
            }
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {isDesktop && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label={String(t('common.compact-view'))}
          />
        )}
      </Box>
    </>
  );
};
