import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';

axiosRetry(axiosPrivate, { retries: 3 });

//famoc - separar del core-api

export const rebootDeviceFamocRemote = (
  name: string,
  organizationId: string
): Promise<any> => {
  return axiosPrivate.post(
    '/famoc-reboot-device',
    {},
    { params: { name, organizationId } }
  );
};

export const getDeviceStatusFamocRemote = (name: string): Promise<any> => {
  return axiosPrivate.get('/famoc-device-status', {
    params: { name },
  });
};
