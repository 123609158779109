import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  devicecontainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#525252',
    transform: 'scale(0.4)',
  },
});
