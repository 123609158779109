import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { LinkItemProps } from '../../interfaces/core';
import { logout, selectUser } from '../../redux/user';
import { setClearInstallations } from '../../redux/installations';
import { useAppDispatch, useAppSelector } from '../../redux';
import { useMenuOptions } from '../../hooks/useMenuOptions';
import { useStyles } from './styles';
import Footer from '../Footer';
import avatar from '../../assets/avatar.png';
import { Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';

type Anchor = 'top';

const MobileLayout = ({ children }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState({ top: false });
  const { LinkItems } = useMenuOptions();
  const { pathname } = useLocation();
  const { t, ready } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const openProfile = Boolean(anchorEl);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(setClearInstallations());
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setMenuOpen({ ...menuOpen, [anchor]: open });
    };

  const handleAviableListItems = () => {
    return LinkItems.filter(
      (linkItem: any) => linkItem.role && linkItem.role.includes(user?.role)
    );
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.styledList}>
        {handleAviableListItems().map(
          ({ name, uri, icon, selectedIcon }: LinkItemProps) => (
            <ListItem
              key={name}
              component={Link}
              to={uri}
              selected={uri === pathname}
              className={classes.itemList}
              classes={{
                root: classes.menuitem,
                selected: classes.selected,
              }}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                {uri === pathname ? selectedIcon : icon}
              </ListItemIcon>
              {name}
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar elevation={0}>
        <Toolbar className={classes.styledToolbar}>
          <Box
            onClick={handleProfileClick}
            className={classes.profileImgContainer}
            data-testid="account-button"
          >
            <img
              src={user.image ? `${user.image}?v=${user.updateSeed}` : avatar}
              alt="profile image"
              className={classes.profileImg}
            />
          </Box>
          <Grid className={classes.titleContainer}>
            <Typography variant="h6" noWrap component="div">
              HMY - Platform
            </Typography>
          </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer('top', !menuOpen.top)}
            data-testid="menu-button"
            edge="start"
            sx={{
              marginRight: 2,
              transform: 'scale(1.3)',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openProfile}
            data-testid="logout-menu"
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                width: '200px',
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 21,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {ready ? t('navbar.logout') : 'Cerrar Sesión'}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'top'}
        open={menuOpen['top']}
        onClose={toggleDrawer('top', false)}
      >
        {list('top')}
      </Drawer>
      <Box component="main" className={classes.root}>
        <Toolbar />
        <Grid className={classes.mainPage}>{children}</Grid>
        <Footer />
      </Box>
    </Box>
  );
};

export default MobileLayout;
