import { useRef, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { toString } from 'lodash';
import { useTranslation } from 'react-i18next';
import Query from 'query-string';
import { getKpiReport } from '../../../services/report';
import { useStyles } from './styles';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';

interface Props {
  actualState?: boolean;
  actualStateCsvData?: any;
  data?: any;
  icon: JSX.Element;
  isPercent?: boolean;
  location?: string;
  progress?: boolean;
  solutionType?: boolean;
  solutionTypeCsvData?: any;
  title: string;
  value: number;
  withDownload?: boolean;
  kpiKey?:
    | 'crmgroup'
    | 'customer'
    | 'installation'
    | 'solution'
    | 'solutionType';
}

export default function DashboardCard(props: Props): JSX.Element {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [csvData, setCsvData]: any[] = useState([]);
  const csvInstance = useRef<any | null>(null);
  const {
    icon,
    isPercent = false,
    kpiKey = '',
    location = '',
    progress = false,
    title,
    value,
    withDownload = false,
  } = props;

  const headers = [
    {
      label: String('ID'),
      key: 'id',
    },
    {
      label: String(t('dashboard.csv.name')),
      key: 'name',
    },
    {
      label: String(t('dashboard.csv.created')),
      key: 'create',
    },
    {
      label: String(t('dashboard.csv.updated')),
      key: 'update',
    },
  ];

  const deviceHeaders = [
    {
      label: String(t('dashboard.csv.name')),
      key: 'name',
    },
    {
      label: String(t('dashboard.csv.status')),
      key: 'status',
    },
    {
      label: String(t('dashboard.csv.created')),
      key: 'created',
    },
    {
      label: String(t('dashboard.csv.updated')),
      key: 'updated',
    },
  ];

  const solutionTypeHeaders = [
    {
      label: String(t('dashboard.csv.name')),
      key: 'name',
    },
    {
      label: String(t('dashboard.csv.quantity')),
      key: 'total',
    },
  ];

  const switchHeaders = () => {
    switch (kpiKey) {
      case 'crmgroup':
        return headers;
        break;
      case 'customer':
        return headers;
        break;
      case 'installation':
        return headers;
        break;
      case 'solution':
        return deviceHeaders;
        break;
      case 'solutionType':
        return solutionTypeHeaders;
        break;

      default:
        return Object.keys(csvData[0]);
        break;
    }
  };

  const generateReport = async () => {
    try {
      const query = Query.parse(location);
      const { data } = await getKpiReport({
        kpiName: kpiKey,
        crmGroup: toString(query.g),
        searchCustomer: toString(query.c),
        searchInstall: toString(query.i),
        typeSelected: parseInt(toString(query.s) || '0'),
      });
      setCsvData(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <Grid className={classes.root}>
      <CardContent className={classes.cardStyle}>
        <Grid className={classes.main}>
          <Grid className={classes.titleContainer}>
            <Box data-testid="icon-box">{icon}</Box>
            <Typography className={classes.titleText} data-testid="card-title">
              {title}
            </Typography>
          </Grid>
          <Grid className={classes.contentContainer}>
            <Typography
              className={classes.contentText}
              data-testid="card-content"
            >
              {value || 0}
              {isPercent ? '%' : ''}
            </Typography>
          </Grid>
          <Grid className={classes.downlaodBtnContainer}>
            {withDownload && (
              <>
                <Tooltip
                  title={String(
                    ready ? t('dashboard.download') : 'Descargar informe'
                  )}
                  sx={{
                    ml: -2.5,
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    className={classes.iconButton}
                    onClick={generateReport}
                  >
                    <DownloadIcon color="primary" className={classes.icon} />
                  </IconButton>
                </Tooltip>
                {csvData.length > 0 ? (
                  <CSVLink
                    id={title}
                    ref={csvInstance}
                    data={csvData}
                    headers={switchHeaders()}
                    filename={`${title}.csv`}
                  />
                ) : undefined}
              </>
            )}
          </Grid>
        </Grid>
        <Grid className={classes.progressContainer}>
          {progress && <LinearProgress />}
        </Grid>
      </CardContent>
      <Divider className={classes.divider} />
    </Grid>
  );
}
