import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '15px',
  },
  idText: {
    display: 'flex',
    marginRight: '40px !important',
    fontSize: '22px !important',
  },
  standardText: {
    display: 'flex',
    paddingRight: '40px',
    fontSize: '22px !important',
  },
  statusText: {
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  styledButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginRight: '20px !important',
  },
  logDetail: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '20px 0 10px 10px !important',
  },
  test: {
    position: 'relative',
    height: '80vh',
  },
  detailContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    overflow: 'scroll',
    background: 'rgba(0, 128, 185, 0.05)',
    borderRadius: '8px',
    padding: '24px',
  },
  rowContainer: {
    display: 'flex',
    alignContent: 'center',
    paddingBottom: '20px',
  },
});
