import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: '-0.8px 0.8px 0 0',
    },
    main: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '35px',
      },
    },
  };
});
