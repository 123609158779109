import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkItemProps } from '../../interfaces/core';
import { logout, selectUser } from '../../redux/user';
import { setClearInstallations } from '../../redux/installations';
import { useAppDispatch, useAppSelector } from '../../redux';
import { useMenuOptions } from '../../hooks/useMenuOptions';
import { USER_SETTINGS } from '../../constants/routes';
import { useStyles } from './styles';
import avatar from '../../assets/avatar.png';
import Footer from '../Footer';
import HmyIsotypeWhite from '../../assets/png/HMY-isotype-white.png';

import { BiEdit, BiExit } from 'react-icons/bi';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {
  Grid,
  Box,
  Menu,
  MenuItem,
  CssBaseline,
  Typography,
  IconButton,
  ListItem,
  Toolbar,
  List,
  ListItemIcon,
} from '@mui/material';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  /* stylelint-disable-next-line property-no-unknown */
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  /* stylelint-disable-next-line function-no-unknown */
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  /* stylelint-disable-next-line property-no-unknown */
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DesktopLayout = ({ children }: any) => {
  const classes = useStyles();
  const { LinkItems } = useMenuOptions();
  const { t, ready } = useTranslation();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorEl);
  const { pathname } = useLocation();
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(setClearInstallations());
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAviableListItems = () => {
    return LinkItems.filter(
      (linkItem: any) => linkItem.role && linkItem.role.includes(user?.role)
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className={classes.styledToolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            data-testid="menu-button"
            edge="start"
            sx={{
              marginRight: 2,
              transform: 'scale(1.2)',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid className={classes.titleContainer}>
            <img
              src={HmyIsotypeWhite}
              alt="hmy logo"
              className={classes.logo}
            />
            <Typography variant="h6" noWrap component="div">
              HMY - Platform
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }} />
          <Grid className={classes.accountMenu}>
            <Typography component="div">
              <Grid
                sx={{ fontWeight: 'bold', m: 1, lineHeight: 1 }}
                data-testid="username-text"
              >
                {user?.firstname + ' ' + user?.lastname}
              </Grid>
              <Grid
                sx={{ fontWeight: 'light', m: 1, lineHeight: 0.5 }}
                data-testid="userrole-text"
              >
                {user?.role}
              </Grid>
            </Typography>
            <Box
              onClick={handleProfileClick}
              className={classes.profileImgContainer}
              data-testid="account-button"
            >
              <img
                src={user.image ? `${user.image}?v=${user.updateSeed}` : avatar}
                alt="profile image"
                className={classes.profileImg}
              />
            </Box>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={openProfile}
            data-testid="logout-menu"
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 'auto',
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem component={Link} to={USER_SETTINGS}>
              <ListItemIcon>
                <BiEdit size={20} />
              </ListItemIcon>
              {ready ? t('navbar.account-settings') : 'Editar Perfil'}
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <BiExit size={20} />
              </ListItemIcon>
              {ready ? t('navbar.logout') : 'Cerrar Sesión'}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className={classes.drawer}>
        <Grid className={classes.drawerContainer} data-testid="data-drawer">
          <DrawerHeader />
          <List className={classes.styledList}>
            {handleAviableListItems().map(
              ({ name, uri, icon, selectedIcon }: LinkItemProps) => (
                <ListItem
                  key={name}
                  component={Link}
                  to={uri}
                  selected={uri === pathname}
                  className={classes.itemList}
                  classes={{
                    root: classes.menuitem,
                    selected: classes.selected,
                  }}
                >
                  <ListItemIcon className={classes.menuItemIcon}>
                    {uri === pathname ? selectedIcon : icon}
                  </ListItemIcon>
                  {name}
                </ListItem>
              )
            )}
          </List>
        </Grid>
        {open ? (
          <Grid className={classes.versionContainer}>
            <Typography className={classes.versionText}>
              {`ver ${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Grid>
        ) : null}
      </Drawer>
      <Box component="main" className={classes.root}>
        {/* <DrawerHeader /> */}
        <Toolbar />
        <Grid className={classes.mainPage}>{children}</Grid>
        <Footer />
      </Box>
    </Box>
  );
};

export default DesktopLayout;
