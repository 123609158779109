import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    background: 'white',
    position: 'absolute',
    top: '56px',
    left: '-20px',
    right: '0px',
    padding: '0px 20px',
  },
  title: {
    padding: '0px 15px',
  },
  itemContainer: {
    margin: '10px 0px',
    padding: '0px 10px',
  },
});
