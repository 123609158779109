import { useStyles } from './styles';
import UserForm from './UserForm';
import PasswordForm from './PasswordForm';
import { Grid } from '@mui/material';

const UserSettings = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <UserForm />
      <PasswordForm />
    </Grid>
  );
};

export default UserSettings;
