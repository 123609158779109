import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    margin: '0',
    padding: '0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  tabPanel: {
    margin: '0 !important',
    padding: '0 !important',
  },
});
