import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Buttons } from '../../../../interfaces/report';
import { useDatePickerButtons } from '../../../../hooks/useDatePickerButtons';
import { useStyles } from './styles';
import { Box, Button, Menu, Grid, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getFirstDayOfLastYearGlobal } from '../../../../utils/helpers';

interface PropsInterface {
  dateFrom: (date: string) => void;
  dateTo: (date: string) => void;
}

const DatePickerMenu = (props: PropsInterface) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { buttons } = useDatePickerButtons();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dateNow = DateTime.now().toJSDate();
  const [pickerDateFrom, setPickerDateFrom] = useState<Date>(dateNow);
  const [pickerDateTo, setPickerDateTo] = useState<Date>(dateNow);
  const untilDate = dateNow.toISOString().split('T')[0];
  const initialDate = getFirstDayOfLastYearGlobal().toISOString().split('T')[0];
  const [dateFrom, setDateFrom] = useState(initialDate);
  const [dateTo, setDateTo] = useState(untilDate);
  const [isDisabled, setIsDisabled] = useState(true);
  const openPicker = Boolean(anchorEl);

  const currentDayNumber = new Date().getDate();

  const currentMonthNumber = new Date().getMonth() + 1;

  const firstDayOfMonth = DateTime.now().plus({
    days: -currentDayNumber + 1,
  });

  const getFirstDayOfLastMonth = () => {
    const lastMonthDate = DateTime.now().plus({ months: -1 });
    return lastMonthDate.plus({ days: -currentDayNumber + 1 }).toJSDate();
  };

  const getFirstDayOfCurrentYear = () => {
    return firstDayOfMonth.plus({ months: -currentMonthNumber + 1 }).toJSDate();
  };

  const getfirstDayOfLastYear = () => {
    return firstDayOfMonth
      .plus({ months: -currentMonthNumber + 1 })
      .plus({ years: -1 })
      .toJSDate();
  };

  const getLastDayOfLastYear = () => {
    const lastYearDecemberFirst = firstDayOfMonth.plus({
      months: -currentMonthNumber,
    });
    return lastYearDecemberFirst.plus({ days: 30 }).toJSDate();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    props.dateFrom(dateFrom);
    props.dateTo(dateTo);
  }, [dateFrom, dateTo]);

  const handleDateRange = (id: number) => {
    if (id === 10) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    switch (id) {
      //Hoy
      case 1:
        setPickerDateFrom(DateTime.now().toJSDate());
        setPickerDateTo(DateTime.now().toJSDate());
        break;
      //Ayer
      case 2:
        setPickerDateFrom(DateTime.now().plus({ days: -1 }).toJSDate());
        setPickerDateTo(DateTime.now().plus({ days: -1 }).toJSDate());
        break;
      //Últimos 7 días
      case 3:
        setPickerDateFrom(DateTime.now().plus({ weeks: -1 }).toJSDate());
        setPickerDateTo(DateTime.now().toJSDate());
        break;
      //Últimos 30 días
      case 4:
        setPickerDateFrom(DateTime.now().plus({ months: -1 }).toJSDate());
        setPickerDateTo(DateTime.now().toJSDate());
        break;
      //Este mes
      case 5:
        setPickerDateFrom(
          DateTime.now()
            .plus({ days: -currentDayNumber + 1 })
            .toJSDate()
        );
        setPickerDateTo(DateTime.now().toJSDate());
        break;
      //Último mes
      case 6:
        setPickerDateFrom(getFirstDayOfLastMonth);
        setPickerDateTo(
          DateTime.now().plus({ days: -currentDayNumber }).toJSDate()
        );
        break;
      //Este año
      case 7:
        setPickerDateFrom(getFirstDayOfCurrentYear);
        setPickerDateTo(DateTime.now().toJSDate());
        break;
      //Último año
      case 8:
        setPickerDateFrom(getfirstDayOfLastYear);
        setPickerDateTo(getLastDayOfLastYear);
        break;

      default:
        break;
    }
  };

  const handleFilter = () => {
    setDateFrom(pickerDateFrom.toISOString().split('T')[0]);
    setDateTo(pickerDateTo.toISOString().split('T')[0]);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        disableTouchRipple
        endIcon={<ArrowDropDownIcon />}
        onClick={handleMenuClick}
      >
        {ready ? t('reports.data-range-select') : 'Seleccionar rango de fechas'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openPicker}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 260,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <Grid className={classes.menuContainer}>
          <Grid className={classes.buttonsContainer}>
            {buttons.map((btn: Buttons) => (
              <Button
                key={btn.id}
                variant="contained"
                className={classes.styledBtn}
                onClick={() => handleDateRange(btn.id)}
              >
                {btn.title}
              </Button>
            ))}
          </Grid>
          <Grid className={classes.datePickersContainer}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box className={classes.pickerContainer}>
                  <DatePicker
                    label={ready ? t('maintenance.date-from') : 'Fecha desde'}
                    value={pickerDateFrom}
                    onChange={(newValue) => {
                      setPickerDateFrom(new Date(String(newValue)));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd/MM/yyyy"
                    disabled={isDisabled}
                  />
                </Box>
                <Box className={classes.pickerContainer}>
                  <DatePicker
                    label={ready ? t('maintenance.date-to') : 'Fecha hasta'}
                    value={pickerDateTo}
                    onChange={(newValue) => {
                      setPickerDateTo(new Date(String(newValue)));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="dd/MM/yyyy"
                    disabled={isDisabled}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Box className={classes.btnContainer}>
              <Button size="small" variant="contained" onClick={handleFilter}>
                {t('reports.date-filter.apply')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default DatePickerMenu;
