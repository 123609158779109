import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background: 'rgba(0, 128, 185, 0.05)',
      padding: '15px 0px',
      borderEndEndRadius: '10px',
      borderEndStartRadius: '10px',
    },
    main: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    boxSection: {
      display: 'grid',
      alignContent: 'space-between',
      [theme.breakpoints.down('lg')]: {
        padding: '20px 0px',
      },
    },
    startTimePicker: {
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    styledClock: {
      width: '120px',
      marginTop: '25px',
      [theme.breakpoints.down('lg')]: {
        margin: '10px 10px 0px 10px',
      },
    },
    optionButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        padding: '30px 0px 10px 0px',
      },
    },
    button: {
      margin: '0px 10px !important',
    },
    checkIcon: {
      transform: 'scale(1.2)',
    },
    selectDayTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '0px 45px',
      marginBottom: '15px',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '56px',
        padding: '0px',
      },
    },
    daysContainer: {
      alignContent: 'start',
      padding: '0px 40px',
      [theme.breakpoints.down('lg')]: {
        alignContent: 'center',
        padding: '0px',
      },
    },
    infoTextContainer: {
      display: 'grid',
      marginTop: '32px',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    dayText: {
      fontSize: '18px !important',
    },
    text: {
      color: '#56565A',
    },
    mailText: {
      color: '#0080B9',
    },
  };
});
