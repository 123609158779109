import { useState } from 'react';
import { remove, last } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { statusLabels } from '../../../../interfaces/platform';
import { DeviceType } from '../../../../interfaces/core';
import { getUserInfo } from '../../../../services/user';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

interface InstFilterProps {
  searchCustomer: string;
  searchInstall: string;
  setSearchCustomer: (a?: any) => void;
  setSearchInstall: (a?: any) => void;
  typeSelected: string;
  setTypeSelected: (a?: any) => void;
  types: DeviceType[];
  handleClean: () => void;
  optionsCustomer: string[];
  optionsInstall: string[];
  setDeviceStatusSelected: (a?: any) => void;
  deviceStatusSelected: string[];
  optionsCrmGroup: string[];
  searchCrmGroup: string;
  setSearchCrmGroup: (a?: any) => void;
}

const MobileFilter = (props: InstFilterProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const loggedUser = getUserInfo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    searchCustomer,
    searchInstall,
    setSearchCustomer,
    setSearchInstall,
    typeSelected,
    setTypeSelected,
    types,
    handleClean,
    optionsCustomer,
    optionsInstall,
    setDeviceStatusSelected,
    deviceStatusSelected,
    optionsCrmGroup,
    searchCrmGroup,
    setSearchCrmGroup,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deviceStatusList = ['all'].concat(statusLabels).map((status) => ({
    id: status,
    name: t(`support.statusChip.${status}`),
  }));

  return (
    <Grid className={classes.root}>
      <Button
        variant="text"
        size="large"
        disableRipple
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClick}
      >
        {ready ? t('support.filter') : 'Filtrar'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 24,
          sx: {
            width: '100%',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 35,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" className={classes.title}>
          {t('support.filter-by')}
        </Typography>
        <Divider />
        <Box className={classes.itemContainer}>
          <Autocomplete
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disabled={loggedUser?.hasCustomers}
            disablePortal
            id="combo-box-crm-group"
            options={optionsCrmGroup}
            value={searchCrmGroup}
            data-testid="crm-groups"
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event: any, newValue: string | null) => {
              setSearchCrmGroup(newValue ? `${newValue}` : '');
            }}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('support.crmGroups') : 'Grupos'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <Autocomplete
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-demo"
            options={optionsCustomer}
            value={searchCustomer}
            data-testid="customer"
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event, newValue: string | null) => {
              setSearchCustomer(newValue ? `${newValue}` : '');
            }}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('support.client') : 'Cliente'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <Autocomplete
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-demo-1"
            options={optionsInstall}
            value={searchInstall}
            data-testid="installation"
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event, newValue: string | null) => {
              setSearchInstall(newValue ? `${newValue}` : '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('support.instalation') : 'Instalación'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {ready ? t('support.solution') : 'Solución'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="Alcampo Kiosk"
              value={typeSelected}
              data-testid="solution"
              label={ready ? t('support.solution') : 'Solución'}
              fullWidth
              onChange={(event: SelectChangeEvent) => {
                setTypeSelected(event.target.value);
              }}
            >
              {[
                {
                  id: 0,
                  name: ready
                    ? t('support.all-solutions')
                    : 'Todas las Soluciones',
                },
              ]
                .concat(types)
                .map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.itemContainer}>
          <FormControl fullWidth>
            <InputLabel id="multiple-staus-device-select">
              {ready ? t('support.availability') : 'Disponibilidad'}
            </InputLabel>
            <Select
              labelId="multiple-staus-device-select"
              id="multiple-staus-device-select"
              multiple
              value={deviceStatusSelected}
              data-testid="availability"
              label={ready ? t('support.availability') : 'Disponibilidad'}
              fullWidth
              onChange={(
                event: SelectChangeEvent<typeof deviceStatusSelected>
              ) => {
                const value = event.target.value;
                let toSet =
                  typeof value === 'string' ? value.split(',') : value;
                if (last(toSet) == 'all') {
                  toSet = ['all'];
                } else if (toSet.includes('all')) {
                  remove(toSet, (s: string) => s == 'all');
                }
                setDeviceStatusSelected(toSet);
              }}
              onClose={() => {
                if (!deviceStatusSelected.length) {
                  setDeviceStatusSelected(['all']);
                }
              }}
              renderValue={(selected) =>
                selected
                  .map((s: any) =>
                    ready ? t(`support.statusChip.${s}`) : 'Todos los estados'
                  )
                  .join(', ')
              }
            >
              {deviceStatusList.map((dSt) => (
                <MenuItem key={dSt.id} value={dSt.id}>
                  <Checkbox
                    checked={
                      !!deviceStatusSelected.find((s: any) => s === dSt.id)
                    }
                  />
                  <ListItemText primary={dSt.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.itemContainer}>
          <Button
            variant="text"
            size="large"
            disableRipple
            startIcon={<FilterAltOffIcon />}
            onClick={handleClean}
          >
            {ready ? t('common.cleanFilters') : 'Limpiar filtros'}
          </Button>
        </Box>
      </Menu>
    </Grid>
  );
};

export default MobileFilter;
