/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface TableConfig {
  parentTableConfig: PaginationData;
  childrenTableConfig: PaginationData;
  order: string;
  orderBy: string;
}

interface PaginationData {
  rowsPerPage: number;
  currentPage: number;
}

const initialState: TableConfig = {
  parentTableConfig: {
    rowsPerPage: 10,
    currentPage: 0,
  },
  childrenTableConfig: {
    rowsPerPage: 10,
    currentPage: 0,
  },
  order: 'ASC',
  orderBy: 'customer',
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    changeRowsPerPage: (state, action: PayloadAction<number>) => {
      state.parentTableConfig.rowsPerPage = action.payload;
    },
    changeCurrentPage: (state, action: PayloadAction<number>) => {
      state.parentTableConfig.currentPage = action.payload;
    },
    changeChildrenRowsPerPage: (state, action: PayloadAction<number>) => {
      state.childrenTableConfig.rowsPerPage = action.payload;
    },
    changeChildrenCurrentPage: (state, action: PayloadAction<number>) => {
      state.childrenTableConfig.currentPage = action.payload;
    },
    changeOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload;
    },
    changeOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    initTable: () => initialState,
  },
});

export const {
  changeRowsPerPage,
  changeCurrentPage,
  changeChildrenRowsPerPage,
  changeChildrenCurrentPage,
  changeOrder,
  changeOrderBy,
  initTable,
} = tableSlice.actions;
export const rowsPerPageValue = (state: RootState) =>
  state.table.parentTableConfig.rowsPerPage;
export const currentPageValue = (state: RootState) =>
  state.table.parentTableConfig.currentPage;
export const rowsPerPageSupportDetail = (state: RootState) =>
  state.table.childrenTableConfig.rowsPerPage;
export const currentPageSupportDetail = (state: RootState) =>
  state.table.childrenTableConfig.currentPage;
export const orderDashboardTable = (state: RootState) => state.table.order;
export const orderByDashboardTable = (state: RootState) => state.table.orderBy;

export default tableSlice.reducer;
