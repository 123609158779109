import axiosRetry from 'axios-retry';
import axiosPrivate from '../api/axios';
import {
  CoordinatesResponse,
  PaginatedInstallationsResponse,
} from '../interfaces/installations';
import {
  DashboardFilter,
  SupportFilter,
  InstallationMarker,
} from '../interfaces/installations';

axiosRetry(axiosPrivate, { retries: 3 });

export const getActiveInstallationsByUsername = (): Promise<any> => {
  return axiosPrivate.get('/installations/active');
};

export const getInstallation = (
  id: number,
  pageNumber: number,
  rowsPerPage: number,
  statusList: string[],
  devicetypeId: number
): Promise<any> => {
  return axiosPrivate.post(`/installation/${id}`, {
    pageNumber,
    rowsPerPage,
    statusList,
    devicetypeId,
  });
};

export const paginatedSupportData = (body: SupportFilter) => {
  return axiosPrivate.post<PaginatedInstallationsResponse>(
    '/installations/active',
    body
  );
};

export const paginatedDashboardData = (body: Partial<SupportFilter>) => {
  return axiosPrivate.post<PaginatedInstallationsResponse>(
    '/installations/dashboard',
    body
  );
};

export const installationsCoordinates = (body: DashboardFilter) => {
  return axiosPrivate.post<CoordinatesResponse>(
    '/installations/coordinates',
    body
  );
};

export const getInstallationMarker = (id: number) => {
  return axiosPrivate.get<InstallationMarker>(`/installation/mapmarker/${id}`);
};
