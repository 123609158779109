import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { login } from '../../services/auth';
import { login as loginAction } from '../../redux/user';
import { useAppDispatch } from '../../redux';
import { useStyles } from './styles';
import HmyLogoNavy from '../../assets/png/HMY-logo-navy.png';
import PaswordRecovery from './PasswordRecovery';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  CssBaseline,
  TextField,
} from '@mui/material';

interface LoginValues {
  username: string;
  password: string;
}

const Login = (): JSX.Element => {
  const classes = useStyles();
  const { t, ready, i18n } = useTranslation();
  const [credentialsError, setCredentialsError] = useState<boolean>(false);
  const [serverPermissionsError, setServerPermissionsError] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.removeItem('i18nextLng');
    i18n.changeLanguage();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (values: LoginValues) => {
    setCredentialsError(false);
    setServerPermissionsError(false);
    await login(values.username, values.password)
      .then((res) => {
        if (res.status === 200) {
          if (Array.isArray(res.data.permissions)) {
            dispatch(loginAction(res.data));
            i18n.changeLanguage(res.data.language);
          } else {
            setServerPermissionsError(true);
          }
        } else {
          throw new Error('Error');
        }
      })
      .catch((e) => {
        setCredentialsError(e.response.data.error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <img
          src={HmyLogoNavy}
          alt="HMY Logo"
          data-testid="image"
          className={classes.logo}
        />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            data-testid="data-username"
            label={ready ? t('login.username') : 'Usuario'}
            autoComplete="username"
            autoFocus
            {...register('username', { required: 'Ingrese su usuario' })}
            error={errors.username ? true : false}
            name="username"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={ready ? t('login.password') : 'Contraseña'}
            type="password"
            id="password"
            data-testid="data-password"
            autoComplete="current-password"
            {...register('password', { required: 'Ingrese su contraseña' })}
            error={errors.password ? true : false}
            name="password"
          />
          <LoadingButton
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3, mb: 2 }}
          >
            {ready ? t('login.signin') : 'Acceder'}
          </LoadingButton>
        </Box>
        <Box sx={{ width: '100%' }}>
          <PaswordRecovery />
          {credentialsError && (
            <Alert severity="error">
              <AlertTitle>
                {ready ? t('login.alert') : 'No se pudo iniciar sesión'}
              </AlertTitle>
              <strong>
                {ready ? t('login.alert-bold-message') : 'Compruebe los datos'}
              </strong>{' '}
              {ready
                ? t('login.alert-thin-message')
                : 'o comuniquesé con el administrador.'}
            </Alert>
          )}
          {serverPermissionsError && (
            <Alert severity="error">
              <AlertTitle>
                {ready
                  ? t('login.server-error')
                  : 'Error de comunicación con el servidor'}
              </AlertTitle>
              <strong>
                {ready
                  ? t('login.try-again')
                  : 'Intente iniciar sesion nuevamente'}
              </strong>{' '}
              {ready
                ? t('login.alert-contact')
                : 'si el error persiste, comuníquese con el administrador.'}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};
export default Login;
