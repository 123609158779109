import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { DateTime } from 'luxon';
import { UserState } from '../../../../interfaces/user';
import { useAppSelector } from '../../../../redux';
import { useNsignMetricsReport } from '../../../../hooks/useNsignMetricsReport';
import { useStyles } from './styles';
import DatePickerMenu from '../../BasicReport/DatePickerMenu';
import NivoPieChart from '../../../../components/NivoPieChart';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DownloadIcon from '@mui/icons-material/Download';
import CircleIcon from '@mui/icons-material/Circle';
import { visuallyHidden } from '@mui/utils';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isRolAdmin } from '../../../../utils/adminList';

const CustomTableCell = ({ children }: { children: string }) => {
  return (
    <TableCell sx={{ backgroundColor: '#E5E5E5', width: '10%' }}>
      {children}
    </TableCell>
  );
};

function crearFechaConFormato(fechaString: string): Date {
  // Dividir el string en partes separadas para el día, mes, año y hora
  const partes = fechaString.split(/[-: ]/);

  return new Date(
    parseInt(partes[2]), // Año
    parseInt(partes[1]) - 1, // Mes
    parseInt(partes[0]), // Día
    parseInt(partes[3]), // Hora
    parseInt(partes[4]) // Minutos
  );
}

export const hoursUpToday = (d1: string) => {
  const fecha = crearFechaConFormato(d1);
  const diferencia = Date.now() - fecha.getTime();
  const horas = Math.round(diferencia / (1000 * 60 * 60));
  return horas;
};

const LastConnectionCell = ({ children }: { children: string }) => {
  const hours = hoursUpToday(children);

  return (
    <TableCell>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '165px',
        }}
      >
        {children}
        {hours < 24 ? (
          <CircleIcon sx={{ color: '#71C325' }} />
        ) : hours > 72 ? (
          <CircleIcon sx={{ color: '#DA0000' }} />
        ) : (
          <CircleIcon sx={{ color: '#FFEC00' }} />
        )}
      </Box>
    </TableCell>
  );
};

const FreeSpaceCell = ({ children }: { children: string }) => {
  const space = parseFloat(children);
  return (
    <TableCell>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '80px',
        }}
      >
        {children}
        {space > 20 ? (
          <CircleIcon sx={{ color: '#71C325' }} />
        ) : space < 10 ? (
          <CircleIcon sx={{ color: '#DA0000' }} />
        ) : (
          <CircleIcon sx={{ color: '#FFEC00' }} />
        )}
      </Box>
    </TableCell>
  );
};

const NsignMetrics = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const { user } = useAppSelector<UserState>((state) => state.user);
  const hasManyCustomers =
    user.userToCustomer?.length === 1 && !isRolAdmin(user.role) ? false : true;
  const {
    chrtLastConnection,
    chrtSpace,
    clear,
    createSortHandler,
    csvData,
    customerList,
    deviceList,
    handleAutocomplete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClean,
    headCells,
    isLoading,
    metricData,
    metricsReportHeads,
    metricTitle,
    order,
    orderBy,
    page,
    rowsPerPage,
    setDateFrom,
    setDateTo,
    totalRows,
  } = useNsignMetricsReport();

  return (
    <Grid key={clear}>
      <Typography variant="h4" className={classes.title}>
        {metricTitle}
      </Typography>
      <Box className={classes.chartSection}>
        <Box className={classes.chartContainer}>
          <Typography variant="h6" className={classes.chartTitle}>
            {ready
              ? t('reports.metrics-report.avg-non-conection')
              : 'Tiempo medio sin conexión'}
          </Typography>
          <NivoPieChart
            data={chrtLastConnection}
            hasLinkLabels={false}
            margin={{ top: 40, right: 10, bottom: 10, left: 10 }}
            colors={['#A3E368', '#FCF380', '#FB7474']}
            hasLegends={false}
            cornerRadius={0}
            legendDirection="row"
            legendPosition="bottom"
            labelPercentage={true}
            isInteractive={false}
            labelColor="#000000"
          />
        </Box>
        <Box className={classes.chartContainer}>
          <Typography variant="h6" className={classes.chartTitle}>
            {ready
              ? t('reports.metrics-report.disk-space-status')
              : 'Estado espacio disco duro'}
          </Typography>
          <NivoPieChart
            data={chrtSpace}
            hasLinkLabels={false}
            margin={{ top: 40, right: 10, bottom: 10, left: 10 }}
            colors={['#A3E368', '#FCF380', '#FB7474', '#D6D6D6']}
            hasLegends={false}
            cornerRadius={0}
            legendDirection="row"
            legendPosition="bottom"
            labelPercentage={true}
            isInteractive={false}
            labelColor="#000000"
          />
        </Box>
      </Box>
      <Box className={classes.filterContainer}>
        <DatePickerMenu
          dateFrom={(date: string) => setDateFrom(date)}
          dateTo={(date: string) => setDateTo(date)}
        />
        {hasManyCustomers && (
          <Autocomplete
            sx={{ width: 200 }}
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            options={customerList}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(_event: any, newValue: any) =>
              handleAutocomplete('customer', newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('reports.customer') : 'Cliente'}
              />
            )}
          />
        )}
        <Autocomplete
          sx={{ width: 200 }}
          clearText={t('common.clear')}
          openText={t('common.open')}
          closeText={t('common.close')}
          options={deviceList}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onChange={(_event: any, newValue: any) =>
            handleAutocomplete('deviceName', newValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={ready ? t('reports.device') : 'Dispositivo'}
            />
          )}
        />
        <Tooltip
          title={String(ready ? t('common.cleanFilters') : 'Limpiar filtros')}
        >
          <Button variant="outlined" disableElevation onClick={handleClean}>
            <FilterAltOffIcon fontSize="large" />
          </Button>
        </Tooltip>
        <CSVLink
          ref={null}
          data={csvData}
          headers={metricsReportHeads}
          filename="report.csv"
        >
          <Tooltip
            title={String(ready ? t('reports.download-csv') : 'Descargar CSV')}
          >
            <Button variant="outlined" className={classes.downloadBtn}>
              <DownloadIcon color="primary" />
            </Button>
          </Tooltip>
        </CSVLink>
      </Box>
      <Paper elevation={0} className={classes.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => {
                  return (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={
                        index < 3
                          ? { backgroundColor: '#E5E5E5' }
                          : { backgroundColor: '#FFFFFF' }
                      }
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {metricData.map((metric) => (
                <TableRow key={metric.id}>
                  <CustomTableCell>{metric.customer.name}</CustomTableCell>
                  <CustomTableCell>{metric.name}</CustomTableCell>
                  <CustomTableCell>{metric.customer.status}</CustomTableCell>
                  <LastConnectionCell>
                    {metric.lastConnection}
                  </LastConnectionCell>
                  <TableCell>{t('common.sin-datos')}</TableCell>
                  <TableCell>{t('common.sin-datos')}</TableCell>
                  <FreeSpaceCell>{metric.space}</FreeSpaceCell>
                  <TableCell>{metric.resolution}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', right: '0px', left: '0px' }}
          />
        )}
        <TablePagination
          labelRowsPerPage={ready ? t('common.table.row') : 'Filas por columna'}
          rowsPerPageOptions={[5]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={totalRows <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </Paper>
    </Grid>
  );
};

export default NsignMetrics;
