import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeCurrentPage } from '../../../../redux/table';
import { useAppDispatch } from '../../../../redux';
import { setMapZoom } from '../../../../redux/map';
import { FilterProps } from '../../../../interfaces/filter';
import { useDashboard } from '../../../../hooks/useDashboard';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useStyles } from './styles';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

const MobileFilter = ({
  crmGroups,
  customers,
  installations,
  addresses,
  location,
  solutions,
}: FilterProps): JSX.Element => {
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const {
    handleClean,
    searchCrmGroup,
    searchCustomer,
    searchInstall,
    setSearchCrmGroup,
    setSearchCustomer,
    setSearchInstall,
    searchAddress,
    setSearchAddress,
    setTypeSelected,
    typeSelected,
  } = useDashboard({ location });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilters = () => {
    handleClean();
    dispatch(setMapZoom(6));
    dispatch(changeCurrentPage(0));
  };

  return (
    <Grid className={classes.root}>
      <Button
        variant="text"
        size="large"
        disableRipple
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClick}
      >
        {ready ? t('dashboard.filter') : 'Filtrar'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 24,
          sx: {
            width: '100%',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 35,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" className={classes.title}>
          {t('dashboard.filter-by')}
        </Typography>
        <Divider />
        <Box className={classes.itemContainer}>
          <Autocomplete
            fullWidth
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-crm-group"
            options={crmGroups}
            value={searchCrmGroup}
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event: any, newValue: string | null) =>
              setSearchCrmGroup(newValue ? `${newValue}` : '')
            }
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('dashboard.crmGroups') : 'Grupos'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <Autocomplete
            fullWidth
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-demo"
            options={customers}
            value={searchCustomer}
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event, newValue: string | null) => {
              setSearchCustomer(newValue ? `${newValue}` : '');
            }}
            getOptionLabel={(option) => option || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('dashboard.customer') : 'Cliente'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <Autocomplete
            fullWidth
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-demo-1"
            data-testid="installation-autocomplete"
            options={installations}
            value={searchInstall}
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event, newValue: string | null) => {
              setSearchInstall(newValue ? `${newValue}` : '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('dashboard.installation') : 'Instalación'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {ready ? t('dashboard.solution') : 'Solución'}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              data-testid="solution-select"
              placeholder="Alcampo Kiosk"
              value={typeSelected}
              label={ready ? t('dashboard.solution') : 'Solución'}
              onChange={(event: SelectChangeEvent) => {
                setTypeSelected(event.target.value);
              }}
            >
              {[
                {
                  id: 0,
                  name: ready
                    ? t('support.all-solutions')
                    : 'Todas las Soluciones',
                },
              ]
                .concat(solutions)
                .map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.itemContainer}>
          <Autocomplete
            fullWidth
            clearText={t('common.clear')}
            openText={t('common.open')}
            closeText={t('common.close')}
            disablePortal
            id="combo-box-demo-1"
            data-testid="address-autocomplete"
            options={addresses}
            value={searchAddress}
            isOptionEqualToValue={(option: any, value: any) =>
              option.value === value.value
            }
            onChange={(_event, newValue: string | null) => {
              setSearchAddress(newValue ? `${newValue}` : '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={ready ? t('dashboard.town') : 'Ciudad'}
              />
            )}
          />
        </Box>
        <Box className={classes.itemContainer}>
          <Button
            variant="text"
            size="large"
            disableRipple
            startIcon={<FilterAltOffIcon />}
            onClick={clearFilters}
          >
            {ready ? t('common.cleanFilters') : 'Limpiar filtros'}
          </Button>
        </Box>
      </Menu>
    </Grid>
  );
};

export default MobileFilter;
