import { split } from 'lodash';
import { useAppSelector } from '../redux';
import { Permission, UserState } from '../interfaces/user';

export const useAuth = () => {
  const { user: userRedux } = useAppSelector<UserState>((state) => state.user);

  const getAccessByPath = (path: string) => {
    return userRedux.permissions
      ? userRedux.permissions.find((route: Permission) => route.path == path)
          ?.authList
      : [];
  };

  const getAccessByRol = (pathname: string) => {
    return userRedux.permissions
      ? userRedux.permissions.find(
          (route: Permission) =>
            split(pathname, '/')[1] === route.path.substring(1) &&
            route.authList.some((authRol: string) => authRol === userRedux.role)
        )
      : [];
  };

  return { getAccessByPath, getAccessByRol };
};
