import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  title: {
    margin: '10px 0px 25px 5px',
  },
  filterContainer: {
    display: 'flex',
    gap: '0.5rem',
    margin: '1rem 0rem',
  },
  downloadBtn: {
    height: '100%',
  },
  chartSection: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#E5E5E5',
  },
  chartContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '40%',
  },
  chartTitle: {
    top: '5px',
    position: 'absolute',
  },
  tableContainer: {
    position: 'relative',
    minHeight: '400px',
    paddingBottom: '52px',
  },
  tableScroll: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    minHeight: '340px',
  },
  pagination: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
});
